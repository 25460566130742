import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

export default function PageNotFound() {
  return (
    <Wrapper>
      <h1>页面不存在</h1>
      <img
        className="m-5"
        src="https://media2.giphy.com/media/hS42TuYYnANLFR9IRQ/200w.webp?cid=ecf05e47szeyo6yks08gjremy2o6khaq3wdukqe3lcmtsljm&rid=200w.webp&ct=ts"
        alt=""
      />
      <Link to="/">
        <button className="mybtn my-4">返回主页</button>
      </Link>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: black;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    color: #fff;
  }
`;
