import Layout from "../../components/Layout";
import styled from "styled-components";

export default function Rules() {
  return (
    <Layout>
      <Wrapper>
        <div className="container">
          <h1>[ Terms of Service ]</h1>
          <i>Last updated: June 2022</i>
          <p>General Terms – All Users</p>
          <h2>1. Your Relationship With Us</h2>
          <p>
            Welcome to VHot (the “Platform”), which is provided by VHot Pte.
            Ltd. or one of its affiliates (“VHot”, “we” or “us”).
          </p>
          <p>
            You are reading the terms of service (the “Terms”), which govern the
            relationship and serve as an agreement between you and us and set
            forth the terms and conditions by which you may access and use the
            Platform and our related websites, services, applications, products
            and content (collectively, the “Services”). Our Services are
            provided for private, non-commercial use. For purposes of these
            Terms, “you” and “your” means you as the user of the Services.
          </p>
          <p>
            The Terms form a legally binding agreement between you and us.
            Please take the time to read them carefully.
          </p>
          <h2>2. Accepting the Terms</h2>
          <p>
            By accessing or using our Services, you confirm that you can form a
            binding contract with VHot, that you accept these Terms and that you
            agree to comply with them. Your access to and use of our Services is
            also subject to our Privacy Policy and Community Guidelines, the
            terms of which can be found directly on the Platform, or where the
            Platform is made available for download, on your mobile device’s
            applicable app store, and are incorporated herein by reference. By
            using the Services, you consent to the terms of the Privacy Policy.
          </p>
          <p>
            If you access or use the Services from within a jurisdiction for
            which there are separate supplemental terms, you also hereby agree
            to the supplemental terms applicable to users in each jurisdiction
            as outlined below, and in the event of a conflict between the
            provisions of the Supplemental Terms – Jurisdiction-Specific that
            are relevant to your jurisdiction from which you access or use the
            Services, and the rest of these Terms, the relevant jurisdictions’
            Supplemental Terms – Jurisdiction-Specific will supersede and
            control. If you do not agree to these Terms, you must not access or
            use our Services.
          </p>
          <p>
            If you are accessing or using the Services on behalf of a business
            or entity, then (a) “you” and “your” includes you and that business
            or entity, (b) you represent and warrant that you are an authorized
            representative of the business or entity with the authority to bind
            the entity to these Terms, and that you agree to these Terms on the
            entity’s behalf, and (c) your business or entity is legally and
            financially responsible for your access or use of the Services as
            well as for the access or use of your account by others affiliated
            with your entity, including any employees, agents or contractors.
          </p>
          <p>
            You can accept the Terms by accessing or using our Services. You
            understand and agree that we will treat your access or use of the
            Services as acceptance of the Terms from that point onwards.
          </p>
          <p>
            You should print off or save a local copy of the Terms for your
            records.
          </p>
          <h2>3. Changes to the Terms</h2>
          <p>
            We amend these Terms from time to time, for instance when we update
            the functionality of our Services, when we combine multiple apps or
            services operated by us or our affiliates into a single combined
            service or app, or when there are regulatory changes. We will use
            commercially reasonable efforts to generally notify all users of any
            material changes to these Terms, such as through a notice on our
            Platform, however, you should look at the Terms regularly to check
            for such changes. We will also update the “Last Updated” date at the
            top of these Terms, which reflect the effective date of such Terms.
            Your continued access or use of the Services after the date of the
            new Terms constitutes your acceptance of the new Terms. If you do
            not agree to the new Terms, you must stop accessing or using the
            Services.
          </p>
          <h2>4. Your Account with Us</h2>
          <p>
            To access or use some of our Services, you must create an account
            with us. When you create this account, you must provide accurate and
            up-to-date information. It is important that you maintain and
            promptly update your details and any other information you provide
            to us, to keep such information current and complete.
          </p>
          <p>
            It is important that you keep your account password confidential and
            that you do not disclose it to any third party. If you know or
            suspect that any third party knows your password or has accessed
            your account, you must promptly notify us by info@vhot.live.
          </p>
          <p>
            You agree that you are solely responsible (to us and to others) for
            the activity that occurs under your account.
          </p>
          <p>
            We reserve the right to disable your user account at any time,
            including if you have failed to comply with any of the provisions of
            these Terms, or if activities occur on your account which, in our
            sole discretion, would or might cause damage to or impair the
            Services or infringe or violate any third party rights, or violate
            any applicable laws or regulations.
          </p>
          <p>
            If you no longer want to use our Services again, and would like your
            account deleted, we can take care of this for you. Please contact us
            at: https://www.VHot.com/legal/report/feedback, and we will provide
            you with further assistance and guide you through the process. Once
            you choose to delete your account, you will not be able to
            reactivate your account or retrieve any of the content or
            information you have added.
          </p>
          <h2>5. Your Access to and Use of Our Services</h2>
          <p>
            Your access to and use of the Services is subject to these Terms and
            all applicable laws and regulations. You may not:
          </p>
          <ul>
            <li>
              access or use the Services if you are not fully able and legally
              competent to agree to these Terms;
            </li>
            <li>
              make unauthorised copies, modify, adapt, translate, reverse
              engineer, disassemble, decompile or create any derivative works of
              the Services or any content included therein, including any files,
              tables or documentation (or any portion thereof) or determine or
              attempt to determine any source code, algorithms, methods or
              techniques embodied by the Services or any derivative works
              thereof;
            </li>
            <li>
              distribute, license, transfer, or sell, in whole or in part, any
              of the Services or any derivative works thereof;
            </li>
            <li>
              market, rent or lease the Services for a fee or charge, or use the
              Services to advertise or perform any commercial solicitation;
            </li>
            <li>
              use the Services, without our express written consent, for any
              commercial or unauthorized purpose, including communicating or
              facilitating any commercial advertisement or solicitation or
              spamming;
            </li>
            <li>
              interfere with or attempt to interfere with the proper working of
              the Services, disrupt our website or any networks connected to the
              Services, or bypass any measures we may use to prevent or restrict
              access to the Services;
            </li>
            <li>
              incorporate the Services or any portion thereof into any other
              program or product. In such case, we reserve the right to refuse
              service, terminate accounts or limit access to the Services in our
              sole discretion;
            </li>
            <li>
              use automated scripts to collect information from or otherwise
              interact with the Services;
            </li>
            <li>
              impersonate any person or entity, or falsely state or otherwise
              misrepresent you or your affiliation with any person or entity,
              including giving the impression that any content you upload, post,
              transmit, distribute or otherwise make available emanates from the
              Services;
            </li>
            <li>
              distribute or otherwise make available emanates from the Services;
              intimidate or harass another, or promote sexually explicit
              material, violence or discrimination based on race, sex, religion,
              nationality, disability, sexual orientation or age;
            </li>
            <li>
              use or attempt to use another’s account, service or system without
              authorisation from VHot, or create a false identity on the
              Services;
            </li>
            <li>
              use the Services in a manner that may create a conflict of
              interest or undermine the purposes of the Services, such as
              trading reviews with other users or writing or soliciting fake
              reviews;
            </li>
            <li>
              use the Services to upload, transmit, distribute, store or
              otherwise make available in any way: files that contain viruses,
              trojans, worms, logic bombs or other material that is malicious or
              technologically harmful; any unsolicited or unauthorised
              advertising, solicitations, promotional materials, “junk mail,”
              “spam,” “chain letters,” “pyramid schemes,” or any other
              prohibited form of solicitation; any private information of any
              third party, including addresses, phone numbers, email addresses,
              number and feature in the personal identity document (e.g.,
              National Insurance numbers, passport numbers) or credit card
              numbers; any material which does or may infringe any copyright,
              trade mark or other intellectual property or privacy rights of any
              other person; any material which is defamatory of any person,
              obscene, offensive, pornographic, hateful or inflammatory; any
              material that would constitute, encourage or provide instructions
              for a criminal offence, dangerous activities or self-harm; any
              material that is deliberately designed to provoke or antagonise
              people, especially trolling and bullying, or is intended to
              harass, harm, hurt, scare, distress, embarrass or upset people;
              any material that contains a threat of any kind, including threats
              of physical violence; any material that is racist or
              discriminatory, including discrimination on the basis of someone’s
              race, religion, age, gender, disability or sexuality;
            </li>
            <li>
              any answers, responses, comments, opinions, analysis or
              recommendations that you are not properly licensed or otherwise
              qualified to provide; or
            </li>
            <li>
              material that, in the sole judgment of VHot, is objectionable or
              which restricts or inhibits any other person from using the
              Services, or which may expose VHot, the Services or its users to
              any harm or liability of any type.
            </li>
          </ul>
          <p>
            In addition to the above, your access to and use of the Services
            must, at all times, be compliant with our Community Guidelines.
          </p>
          <p>
            We reserve the right, at any time and without prior notice, to
            remove or disable access to content at our discretion for any reason
            or no reason. Some of the reasons we may remove or disable access to
            content may include finding the content objectionable, in violation
            of these Terms or our Community Guidelines, or otherwise harmful to
            the Services or our users. Our automated systems analyze your
            content to provide you personally relevant product features, such as
            customized search results, tailored advertising, and spam and
            malware detection. This analysis occurs as the content is sent,
            received, and when it is stored.
          </p>
          <h2>6. Intellectual Property Rights</h2>
          <p>
            We respect intellectual property rights and ask you to do the same.
            As a condition of your access to and use of the Services, you agree
            not to use the Services to infringe on any intellectual property
            rights. We reserve the right, with or without notice, at any time
            and in our sole discretion to block access to and/or terminate the
            accounts of any user who infringes or is alleged to infringe any
            copyrights or other intellectual property rights.
          </p>
          <h2>7. Content</h2>
          <p>A. VHot Content</p>
          <p>
            As between you and VHot, all content, software, images, text,
            graphics, illustrations, logos, patents, trademarks, service marks,
            copyrights, photographs, audio, videos, music on and “look and feel”
            of the Services, and all intellectual property rights related
            thereto (the “VHot Content”), are either owned or licensed by VHot,
            it being understood that you or your licensors will own any User
            Content (as defined below) you upload or transmit through the
            Services. Use of the VHot Content or materials on the Services for
            any purpose not expressly permitted by these Terms is strictly
            prohibited. Such content may not be downloaded, copied, reproduced,
            distributed, transmitted, broadcast, displayed, sold, licensed or
            otherwise exploited for any purpose whatsoever without our or, where
            applicable, our licensors’ prior written consent. We and our
            licensors reserve all rights not expressly granted in and to their
            content.
          </p>
          <p>
            You acknowledge and agree that we may generate revenues, increase
            goodwill or otherwise increase our value from your use of the
            Services, including, by way of example and not limitation, through
            the sale of advertising, sponsorships, promotions, usage data and
            Gifts (as defined and further explained in the “Virtual Items
            Policy”), and except as specifically permitted by us in these Terms
            or in another agreement you enter into with us, you will have no
            right to share in any such revenue, goodwill or value whatsoever.
            You further acknowledge that, except as specifically permitted by us
            in these Terms or in another agreement you enter into with us, you
            (i) have no right to receive any income or other consideration from
            any User Content (defined below) or your use of any musical works,
            sound recordings or audiovisual clips made available to you on or
            through the Services, including in any User Content created by you,
            and (ii) are prohibited from exercising any rights to monetize or
            obtain consideration from any User Content within the Services or on
            any third party service (e.g., you cannot claim User Content that
            has been uploaded to a social media platform such as YouTube for
            monetization).
          </p>
          <p>
            Subject to the terms and conditions of the Terms, you are hereby
            granted a non-exclusive, limited, non-transferable,
            non-sublicensable, revocable, worldwide license to access and use
            the Services, including to download the Platform on a permitted
            device, and to access the VHot Content solely for your personal,
            non-commercial use through your use of the Services and solely in
            compliance with these Terms. VHot reserves all rights not expressly
            granted herein in the Services and the VHot Content. You acknowledge
            and agree that VHot may terminate this license at any time for any
            reason or no reason.
          </p>
          <p>
            NO RIGHTS ARE LICENSED WITH RESPECT TO SOUND RECORDINGS AND THE
            MUSICAL WORKS EMBODIED THEREIN THAT ARE MADE AVAILABLE FROM OR
            THROUGH THE SERVICE.
          </p>
          <p>
            You acknowledge and agree that when you view content provided by
            others on the Services, you are doing so at your own risk. The
            content on our Services is provided for general information only. It
            is not intended to amount to advice on which you should rely. You
            must obtain professional or specialist advice before taking, or
            refraining from, any action on the basis of the content on our
            Services.
          </p>
          <p>
            We make no representations, warranties or guarantees, whether
            express or implied, that any VHot Content (including User Content)
            is accurate, complete or up to date. Where our Services contain
            links to other sites and resources provided by third parties, these
            links are provided for your information only. We have no control
            over the contents of those sites or resources. Such links should not
            be interpreted as approval by us of those linked websites or
            information you may obtain from them. You acknowledge that we have
            no obligation to pre-screen, monitor, review, or edit any content
            posted by you and other users on the Services (including User
            Content).
          </p>

          <p>B. User-Generated Content</p>
          <p>
            Users of the Services may be permitted to upload, post or transmit
            (such as via a stream) or otherwise make available content through
            the Services including, without limitation, any text, photographs,
            user videos, sound recordings and the musical works embodied
            therein, including videos that incorporate locally stored sound
            recordings from your personal music library and ambient noise (“User
            Content”). Users of the Services may also extract all or any portion
            of User Content created by another user to produce additional User
            Content, including collaborative User Content with other users, that
            combine and intersperse User Content generated by more than one
            user. Users of the Services may also overlay music, graphics,
            stickers, Virtual Items (as defined and further explained in the
            “Virtual Items Policy“) and other elements provided by VHot (“VHot
            Elements”) onto this User Content and transmit this User Content
            through the Services. The information and materials in the User
            Content, including User Content that includes VHot Elements, have
            not been verified or approved by us. The views expressed by other
            users on the Services (including through use of the virtual gifts)
            do not represent our views or values.
          </p>
          <p>
            Whenever you access or use a feature that allows you to upload or
            transmit User Content through the Services (including via certain
            third party social media platforms such as Instagram, Facebook,
            YouTube, Twitter), or to make contact with other users of the
            Services, you must comply with the standards set out at “Your Access
            to and Use of Our Services” above. You may also choose to upload or
            transmit your User Content, including User Content that includes
            VHot Elements, on sites or platforms hosted by third parties. If you
            decide to do this, you must comply with their content guidelines as
            well as with the standards set out at “Your Access to and Use of Our
            Services” above.
          </p>
          <p>
            You warrant that any such contribution does comply with those
            standards, and you will be liable to us and indemnify us for any
            breach of that warranty. This means you will be responsible for any
            loss or damage we suffer as a result of your breach of warranty.
          </p>
          <p>
            Any User Content will be considered non-confidential and
            non-proprietary. You must not post any User Content on or through
            the Services or transmit to us any User Content that you consider to
            be confidential or proprietary. When you submit User Content through
            the Services, you agree and represent that you own that User
            Content, or you have received all necessary permissions, clearances
            from, or are authorised by, the owner of any part of the content to
            submit it to the Services, to transmit it from the Services to other
            third party platforms, and/or adopt any third party content.
          </p>

          <p>
            If you only own the rights in and to a sound recording, but not to
            the underlying musical works embodied in such sound recordings, then
            you must not post such sound recordings to the Services unless you
            have all permissions, clearances from, or are authorised by, the
            owner of any part of the content to submit it to the Services
          </p>
          <p>
            You or the owner of your User Content still own the copyright in
            User Content sent to us, but by submitting User Content via the
            Services, you hereby grant us an unconditional irrevocable,
            non-exclusive, royalty-free, fully transferable, perpetual worldwide
            licence to use, modify, adapt, reproduce, make derivative works of,
            publish and/or transmit, and/or distribute and to authorise other
            users of the Services and other third-parties to view, access, use,
            download, modify, adapt, reproduce, make derivative works of,
            publish and/or transmit your User Content in any format and on any
            platform, either now known or hereinafter invented.
          </p>
          <p>
            You further grant us a royalty-free license to use your user name,
            image, voice, and likeness to identify you as the source of any of
            your User Content.
          </p>
          <p>
            User Content. For the avoidance of doubt, the rights granted in the
            preceding paragraphs of this Section include, but are not limited
            to, the right to reproduce sound recordings (and make mechanical
            reproductions of the musical works embodied in such sound
            recordings), and publicly perform and communicate to the public
            sound recordings (and the musical works embodied therein), all on a
            royalty-free basis. This means that you are granting us the right to
            use your User Content without the obligation to pay royalties to any
            third party, including, but not limited to, a sound recording
            copyright owner (e.g., a record label), a musical work copyright
            owner (e.g., a music publisher), a performing rights organization
            (e.g., ASCAP, BMI, SESAC, etc.) (a “PRO”), a sound recording PRO
            (e.g., SoundExchange), any unions or guilds, and engineers,
            producers or other royalty participants involved in the creation of
            User Content.
          </p>
          <p>
            Specific Rules for Musical Works and for Recording Artists. If you
            are a composer or author of a musical work and are affiliated with a
            PRO, then you must notify your PRO of the royalty-free license you
            grant through these Terms in your User Content to us. You are solely
            responsible for ensuring your compliance with the relevant PRO’s
            reporting obligations. If you have assigned your rights to a music
            publisher, then you must obtain the consent of such music publisher
            to grant the royalty-free license(s) set forth in these Terms in
            your User Content or have such music publisher enter into these
            Terms with us. Just because you authored a musical work (e.g., wrote
            a song) does not mean you have the right to grant us the licenses in
            these Terms. If you are a recording artist under contract with a
            record label, then you are solely responsible for ensuring that your
            use of the Services is in compliance with any contractual
            obligations you may have to your record label, including if you
            create any new recordings through the Services that may be claimed
            by your label.
          </p>
          <p>
            Through-To-The-Audience Rights. All of the rights you grant in your
            User Content in these Terms are provided on a
            through-to-the-audience basis, meaning the owners or operators of
            third party services will not have any separate liability to you or
            any other third party for User Content posted or used on such third
            party service via the Services.
          </p>
          <p>
            Waiver of Rights to User Content. By posting User Content to or
            through the Services, you waive any rights to prior inspection or
            approval of any marketing or promotional materials related to such
            User Content. You also waive any and all rights of privacy,
            publicity, or any other rights of a similar nature in connection
            with your User Content, or any portion thereof. To the extent any
            moral rights are not transferable or assignable, you hereby waive
            and agree never to assert any and all moral rights, or to support,
            maintain or permit any action based on any moral rights that you may
            have in or with respect to any User Content you Post to or through
            the Services.
          </p>
          <p>
            We also have the right to disclose your identity to any third party
            who is claiming that any User Content posted or uploaded by you to
            our Services constitutes a violation of their intellectual property
            rights, or of their right to privacy.
          </p>

          <p>
            We, or authorised third parties, reserve the right to cut, crop,
            edit or refuse to publish, your content at our or their sole
            discretion. We have the right to remove, disallow, block or delete
            any posting you make on our Services if, in our opinion, your post
            does not comply with the content standards set out at “Your Access
            to and Use of Our Services” above. In addition, we have the right –
            but not the obligation – in our sole discretion to remove, disallow,
            block or delete any User Content (i) that we consider to violate
            these Terms, or (ii) in response to complaints from other users or
            third parties, with or without notice and without any liability to
            you. As a result, we recommend that you save copies of any User
            Content that you post to the Services on your personal device(s) in
            the event that you want to ensure that you have permanent access to
            copies of such User Content. We do not guarantee the accuracy,
            integrity, appropriateness or quality of any User Content, and under
            no circumstances will we be liable in any way for any User Content.
          </p>

          <p>
            You control whether your User Content is made publicly available on
            the Services to all other users of the Services or only available to
            people you approve. To restrict access to your User Content, you
            should select the privacy setting available within the Platform.
          </p>

          <p>
            We accept no liability in respect of any content submitted by users
            and published by us or by authorised third parties.
          </p>

          <p>
            If you wish to complain about information and materials uploaded by
            other users please contact us
            at: https://www.VHot.com/legal/report/reports.
          </p>
          <p>
            VHot takes reasonable measures to expeditiously remove from our
            Services any infringing material that we become aware of. It is
            VHot’s policy, in appropriate circumstances and at its discretion,
            to disable or terminate the accounts of users of the Services who
            repeatedly infringe copyrights or intellectual property rights of
            others.
          </p>
          <p>
            While our own staff is continually working to develop and evaluate
            our own product ideas and features, we pride ourselves on paying
            close attention to the interests, feedback, comments, and
            suggestions we receive from the user community. If you choose to
            contribute by sending us or our employees any ideas for products,
            services, features, modifications, enhancements, content,
            refinements, technologies, content offerings (such as audio, visual,
            games, or other types of content), promotions, strategies, or
            product/feature names, or any related documentation, artwork,
            computer code, diagrams, or other materials (collectively
            “Feedback”), then regardless of what your accompanying communication
            may say, the following terms will apply, so that future
            misunderstandings can be avoided. Accordingly, by sending Feedback
            to us, you agree that:
          </p>
          <ul>
            <li>
              i. VHot has no obligation to review, consider, or implement your
              Feedback, or to return to you all or part of any Feedback for any
              reason;
            </li>
            <li>
              ii. Feedback is provided on a non-confidential basis, and we are
              not under any obligation to keep any Feedback you send
              confidential or to refrain from using or disclosing it in any way;
              and
            </li>
            <li>
              iii. You irrevocably grant us perpetual and unlimited permission
              to reproduce, distribute, create derivative works of, modify,
              publicly perform (including on a through-to-the-audience basis),
              communicate to the public, make available, publicly display, and
              otherwise use and exploit the Feedback and derivatives thereof for
              any purpose and without restriction, free of charge and without
              attribution of any kind, including by making, using, selling,
              offering for sale, importing, and promoting commercial products
              and services that incorporate or embody Feedback, whether in whole
              or in part, and whether as provided or as modified.
            </li>
          </ul>
          <h2>8. Indemnity</h2>
          <p>
            You agree to defend, indemnify, and hold harmless VHot, its parents,
            subsidiaries, and affiliates, and each of their respective officers,
            directors, employees, agents and advisors from any and all claims,
            liabilities, costs, and expenses, including, but not limited to,
            attorneys’ fees and expenses, arising out of a breach by you or any
            user of your account of these Terms or arising out of a breach of
            your obligations, representation and warranties under these Terms.
          </p>
          <h2>9. EXCLUSION OF WARRANTIES</h2>
          <p>
            NOTHING IN THESE TERMS SHALL AFFECT ANY STATUTORY RIGHTS THAT YOU
            CANNOT CONTRACTUALLY AGREE TO ALTER OR WAIVE AND ARE LEGALLY ALWAYS
            ENTITLED TO AS A CONSUMER.
          </p>
          <p>
            THE SERVICES ARE PROVIDED “AS IS” AND WE MAKE NO WARRANTY OR
            REPRESENTATION TO YOU WITH RESPECT TO THEM. IN PARTICULAR WE DO NOT
            REPRESENT OR WARRANT TO YOU THAT:
          </p>
          <ul>
            <li>YOUR USE OF THE SERVICES WILL MEET YOUR REQUIREMENTS;</li>
            <li>
              YOUR USE OF THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR
              FREE FROM ERROR; 
            </li>
            <li>
              ANY INFORMATION OBTAINED BY YOU AS A RESULT OF YOUR USE OF THE
              SERVICES WILL BE ACCURATE OR RELIABLE; AND
            </li>
            <li>
              DEFECTS IN THE OPERATION OR FUNCTIONALITY OF ANY SOFTWARE PROVIDED
              TO YOU AS PART OF THE SERVICES WILL BE CORRECTED.
            </li>
          </ul>
          <p>
            NO CONDITIONS, WARRANTIES OR OTHER TERMS (INCLUDING ANY IMPLIED
            TERMS AS TO SATISFACTORY QUALITY, FITNESS FOR PURPOSE OR CONFORMANCE
            WITH DESCRIPTION) APPLY TO THE SERVICES EXCEPT TO THE EXTENT THAT
            THEY ARE EXPRESSLY SET OUT IN THE TERMS. WE MAY CHANGE, SUSPEND,
            WITHDRAW OR RESTRICT THE AVAILABILITY OF ALL OR ANY PART OF OUR
            PLATFORM FOR BUSINESS AND OPERATIONAL REASONS AT ANY TIME WITHOUT
            NOTICE
          </p>
          <h2>10. LIMITATION OF LIABILITY</h2>
          <p>
            NOTHING IN THESE TERMS SHALL EXCLUDE OR LIMIT OUR LIABILITY FOR
            LOSSES WHICH MAY NOT BE LAWFULLY EXCLUDED OR LIMITED BY APPLICABLE
            LAW. THIS INCLUDES LIABILITY FOR DEATH OR PERSONAL INJURY CAUSED BY
            OUR NEGLIGENCE OR THE NEGLIGENCE OF OUR EMPLOYEES, AGENTS OR
            SUBCONTRACTORS AND FOR FRAUD OR FRAUDULENT MISREPRESENTATION.
          </p>
          <p>
            FRAUDULENT MISREPRESENTATION. SUBJECT TO THE PARAGRAPH ABOVE, WE
            SHALL NOT BE LIABLE TO YOU FOR:
          </p>
          <p>
            (I) ANY LOSS OF PROFIT (WHETHER INCURRED DIRECTLY OR INDIRECTLY);
            (II) ANY LOSS OF GOODWILL; (III) ANY LOSS OF OPPORTUNITY; (IV) ANY
            LOSS OF DATA SUFFERED BY YOU; OR (V) ANY INDIRECT OR CONSEQUENTIAL
            LOSSES WHICH MAY BE INCURRED BY YOU. ANY OTHER LOSS WILL BE LIMITED
            TO THE AMOUNT PAID BY YOU TO VHOT WITHIN THE LAST 12 MONTHS.
          </p>
          <p>ANY LOSS OR DAMAGE WHICH MAY BE INCURRED BY YOU AS A RESULT OF:</p>
          <ul>
            <li>
              ANY RELIANCE PLACED BY YOU ON THE COMPLETENESS, ACCURACY OR
              EXISTENCE OF ANY ADVERTISING, OR AS A RESULT OF ANY RELATIONSHIP
              OR TRANSACTION BETWEEN YOU AND ANY ADVERTISER OR SPONSOR WHOSE
              ADVERTISING APPEARS ON THE SERVICE;
            </li>
            <li>
              ANY CHANGES WHICH WE MAY MAKE TO THE SERVICES, OR FOR ANY
              PERMANENT OR TEMPORARY CESSATION IN THE PROVISION OF THE SERVICES
              (OR ANY FEATURES WITHIN THE SERVICES);
            </li>
            <li>
              THE DELETION OF, CORRUPTION OF, OR FAILURE TO STORE, ANY CONTENT
              AND OTHER COMMUNICATIONS DATA MAINTAINED OR TRANSMITTED BY OR
              THROUGH YOUR USE OF THE SERVICES;
            </li>
            <li>
              YOUR FAILURE TO PROVIDE US WITH ACCURATE ACCOUNT INFORMATION;
            </li>
            <li>
              OR YOUR FAILURE TO KEEP YOUR PASSWORD OR ACCOUNT DETAILS SECURE
              AND CONFIDENTIAL.
            </li>
          </ul>
          <p>
            PLEASE NOTE THAT WE ONLY PROVIDE OUR PLATFORM FOR DOMESTIC AND
            PRIVATE USE. YOU AGREE NOT TO USE OUR PLATFORM FOR ANY COMMERCIAL OR
            BUSINESS PURPOSES, AND WE HAVE NO LIABILITY TO YOU FOR ANY LOSS OF
            PROFIT, LOSS OF BUSINESS, LOSS OF GOODWILL OR BUSINESS REPUTATION,
            BUSINESS INTERRUPTION, OR LOSS OF BUSINESS OPPORTUNITY.
          </p>
          <p>
            IF DEFECTIVE DIGITAL CONTENT THAT WE HAVE SUPPLIED DAMAGES A DEVICE
            OR DIGITAL CONTENT BELONGING TO YOU AND THIS IS CAUSED BY OUR
            FAILURE TO USE REASONABLE CARE AND SKILL, WE WILL EITHER REPAIR THE
            DAMAGE OR PAY YOU COMPENSATION. HOWEVER, WE WILL NOT BE LIABLE FOR
            DAMAGE THAT YOU COULD HAVE AVOIDED BY FOLLOWING OUR ADVICE TO APPLY
            AN UPDATE OFFERED TO YOU FREE OF CHARGE OR FOR DAMAGE THAT WAS
            CAUSED BY YOU FAILING TO CORRECTLY FOLLOW INSTALLATION INSTRUCTIONS
            OR TO HAVE IN PLACE THE MINIMUM SYSTEM REQUIREMENTS ADVISED BY US.
          </p>
          <p>
            THESE LIMITATIONS ON OUR LIABILITY TO YOU SHALL APPLY WHETHER OR NOT
            WE HAVE BEEN ADVISED OF OR SHOULD HAVE BEEN AWARE OF THE POSSIBILITY
            OF ANY SUCH LOSSES ARISING.
          </p>
          <p>
            YOU ARE RESPONSIBLE FOR ANY MOBILE CHARGES THAT MAY APPLY TO YOUR
            USE OF OUR SERVICE, INCLUDING TEXT-MESSAGING AND DATA CHARGES. IF
            YOU’RE UNSURE WHAT THOSE CHARGES MAY BE, YOU SHOULD ASK YOUR SERVICE
            PROVIDER BEFORE USING THE SERVICE.
          </p>
          <p>
            TO THE FULLEST EXTENT PERMITTED BY LAW, ANY DISPUTE YOU HAVE WITH
            ANY THIRD PARTY ARISING OUT OF YOUR USE OF THE SERVICES, INCLUDING,
            BY WAY OF EXAMPLE AND NOT LIMITATION, ANY CARRIER, COPYRIGHT OWNER
            OR OTHER USER, IS DIRECTLY BETWEEN YOU AND SUCH THIRD PARTY, AND YOU
            IRREVOCABLY RELEASE US AND OUR AFFILIATES FROM ANY AND ALL CLAIMS,
            DEMANDS AND DAMAGES (ACTUAL AND CONSEQUENTIAL) OF EVERY KIND AND
            NATURE, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED
            WITH SUCH DISPUTES.
          </p>
          <h2>11. Other Terms</h2>
          <p>
            a. Applicable Law and Jurisdiction. Subject to the Supplemental
            Terms – Jurisdiction Specific, these Terms, their subject matter and
            their formation, are governed by the laws of Singapore. Any dispute
            arising out of or in connection with these Terms, including any
            question regarding existence, validity or termination of these
            Terms, shall be referred to and finally resolved by arbitration
            administered by the Singapore International Arbitration Centre
            (“SIAC”) in accordance with the Arbitration Rules of the Singapore
            International Arbitration Centre ("SIAC Rules") for the time being
            in force, which rules are deemed to be incorporated by reference in
            this clause. The seat of the arbitration shall be Singapore. The
            Tribunal shall consist of three (3) arbitrators. The language of the
            arbitration shall be English.
          </p>
          <p>
            b. Open Source. The Platform contains certain open source software.
            Each item of open source software is subject to its own applicable
            license terms, which can be found at Open Source Policy.
          </p>
          <p>
            c. Entire Agreement. These Terms (including the Supplemental Terms
            below) constitute the whole legal agreement between you and VHot and
            govern your use of the Services and completely replace any prior
            agreements between you and VHot in relation to the Services.
          </p>
          <p>
            d. Links. You may link to our home page, provided you do so in a way
            that is fair and legal and does not damage our reputation or take
            advantage of it. You must not establish a link in such a way as to
            suggest any form of association, approval or endorsement on our part
            where none exists. You must not establish a link to our Services in
            any website that is not owned by you. The website in which you are
            linking must comply in all respects with the content standards set
            out at “Your Access to and Use of Our Services” above. We reserve
            the right to withdraw linking permission without notice.
          </p>
          <p>
            e. Age Limit. The Services are only for people 13 years old and over
            (with additional limits that may be set forth in the Supplemental
            Terms – Jurisdiction-Specific). By using the Services, you confirm
            that you are over the relevant age specified herein. If we learn
            that someone under the relevant age specified above is using the
            Services, we will terminate that user’s account.
          </p>
          <p>
            f. No Waiver. Our failure to insist upon or enforce any provision of
            these Terms shall not be construed as a waiver of any provision or
            right.
          </p>
          <p>
            g. Security. We do not guarantee that our Services will be secure or
            free from bugs or viruses. You are responsible for configuring your
            information technology, computer programmes and platform to access
            our Services. You should use your own virus protection software.
          </p>
          <p>
            h. Severability. If any court of law, having jurisdiction to decide
            on this matter, rules that any provision of these Terms is invalid,
            then that provision will be removed from the Terms without affecting
            the rest of the Terms, and the remaining provisions of the Terms
            will continue to be valid and enforceable.
          </p>
          <p>
            i. Any Questions? Get in touch
            at: https://www.VHot.com/legal/report/feedback.
          </p>
          <h2>Supplemental Terms – App Stores</h2>
          <p>
            To the extent permitted by applicable law, the following
            supplemental terms shall apply when accessing the Platform through
            specific devices:
          </p>
          <i>Notice regarding Apple.</i>
          <ul>
            <li>
              These Terms between VHot and you; Apple is not a party to these
              Terms.
            </li>
            <li>
              The license granted to you hereunder is limited to a personal,
              limited, non-exclusive, non-transferable right to install the
              Platform on the Apple device(s) authorised by Apple that you own
              or control for personal, non-commercial use, subject to the Usage
              Rules set forth in Apple’s App Store Terms of Services.
            </li>
            <li>
              Apple is not responsible for the Platform or the content thereof
              and has no obligation whatsoever to furnish any maintenance or
              support services with respect to the Platform.
            </li>
            <li>
              In the event of any failure of the Platform to conform to any
              applicable warranty, you may notify Apple, and Apple will refund
              the purchase price for the Platform, if any, to you. To the
              maximum extent permitted by applicable law, Apple will have no
              other warranty obligation whatsoever with respect to the Platform.
            </li>
            <li>
              Apple is not responsible for addressing any claims by you or a
              third party relating to the Platform or your possession or use of
              the Platform, including without limitation (a) product liability
              claims; (b) any claim that the Platform fails to conform to any
              applicable legal or regulatory requirement; and (c) claims arising
              under consumer protection or similar legislation.
            </li>
            <li>
              In the event of any third party claim that the Platform or your
              possession and use of the Platform infringes such third party’s
              intellectual property rights, Apple is not responsible for the
              investigation, defence, settlement or discharge of such
              intellectual property infringement claim.
            </li>
            <li>
              You represent and warrant that (a) you are not located in a
              country that is subject to a U.S. Government embargo, or that has
              been designated by the U.S. Government as a “terrorist supporting”
              country; and (b) you are not listed on any U.S. Government list of
              prohibited or restricted parties.
            </li>
            <li>
              Apple and its subsidiaries are third party beneficiaries of these
              Terms and upon your acceptance of the terms and conditions of
              these Terms, Apple will have the right (and will be deemed to have
              accepted the right) to enforce these Terms against you as a third
              party beneficiary hereof.
            </li>
            <li>
              VHot expressly authorises use of the Platform by multiple users
              through the Family Sharing or any similar functionality provided
              by Apple.
            </li>
          </ul>

          <p>
            Windows Phone Store. By downloading the Platform from the Windows
            Phone Store (or its successors) operated by Microsoft, Inc. or its
            affiliates, you specifically acknowledge and agree that:
          </p>
          <ul>
            <li>
              You may install and use one copy of the Platform on up to five (5)
              Windows Phone enabled devices that are affiliated with the
              Microsoft account you use to access the Windows Phone Store.
              Beyond that, we reserve the right to apply additional conditions
              or charge additional fees
            </li>
            <li>
              You acknowledge that Microsoft Corporation, your phone
              manufacturer and network operator have no obligation whatsoever to
              furnish any maintenance and support services with respect to the
              Platform.
            </li>
          </ul>
          <p>
            Amazon Appstore. By downloading the Platform from the Amazon
            Appstore (or its successors) operated by Amazon Digital Services,
            Inc. or affiliates (“Amazon”), you specifically acknowledge and
            agree that:
          </p>
          <ul>
            <li>
              to the extent of any conflict between (a) the Amazon Appstore
              Terms of Use or such other terms which Amazon designates as
              default end user license terms for the Amazon Appstore (“Amazon
              Appstore EULA Terms”), and (b) the other terms and conditions in
              these Terms, the Amazon Appstore EULA Terms shall apply with
              respect to your use of the Platform that you download from the
              Amazon Appstore, and
            </li>
            <li>
              Amazon does not have any responsibility or liability related to
              compliance or non-compliance by VHot or you (or any other user)
              under these Terms or the Amazon Appstore EULA Terms.
            </li>
          </ul>
          <p>
            Google Play. By downloading the Platform from Google Play (or its
            successors) operated by Google, Inc. or one of its affiliates
            (“Google”), you specifically acknowledge and agree that:
          </p>
          <ul>
            <li>
              to the extent of any conflict between (a) the Google Play Terms of
              Services and the Google Play Business and Program Policies or such
              other terms which Google designates as default end user license
              terms for Google Play (all of which together are referred to as
              the “Google Play Terms”), and (b) the other terms and conditions
              in these Terms, the Google Play Terms shall apply with respect to
              your use of the Platform that you download from Google Play, and
            </li>
            <li>
              you hereby acknowledge that Google does not have any
              responsibility or liability related to compliance or
              non-compliance by VHot or you (or any other user) under these
              Terms or the Google Play Terms.
            </li>
          </ul>
          <h2>Supplemental Terms – Jurisdiction-Specific</h2>
          <p>
            Brazil. If you are using our Services in Brazil, the following
            additional terms apply. In the event of any conflict between the
            following additional terms and the provisions of the main body of
            these Terms, the following terms shall prevail.
          </p>
          <ul>
            <li>
              Applicable Law and Jurisdiction. These Terms, their subject matter
              and their formation, are governed by Brazilian law. You and we
              both agree that the courts of Brazil will have exclusive
              jurisdiction.
            </li>
            <li>
              Parental and Guardian Consent. If you are over the age of 16 but
              under the age of 18, you declare that you had the consent of your
              parent or legal guardian to use the Services or to register an
              account on the Services.
            </li>
          </ul>
          <p>
            India. If you are using our Services in India, the following
            additional terms apply. In the event of any conflict between the
            following additional terms and the provisions of the main body of
            these Terms, the following terms shall prevail.
          </p>
          <ul>
            <li>
              Accepting the Terms. By agreeing to these Terms and by accessing
              or using our Services, you acknowledge that you have read and
              understood these Terms and provide your consent to be bound by
              these Terms and our Privacy Policy and Community Guidelines.
            </li>
            <li>
              Your access to and use of our Services. You may not use the
              Services to upload, transmit, distribute, store or otherwise make
              available in any way (including for the purposes of creating
              and/or streaming content) any User Content that:
            </li>
            <ul>
              <li>is obscene, pornographic, paedophilic;</li>
              <li>
                is relating to or encouraging money laundering or gambling, or
                otherwise any activity that is unlawful in any manner
                whatsoever;
              </li>
              <li>harms minors in any way;</li>
              <li>
                deceives or misleads the addressee about the origin of such
                messages or communicates any information which is grossly
                offensive or menacing in nature; or
              </li>
              <li>
                threatens the unity, integrity, defence, security or sovereignty
                of India, friendly relations with foreign states, or public
                order or causes incitement to the commission of any cognisable
                offence or prevents investigation of any offence or is insulting
                any other nation.
              </li>
            </ul>
            <li>
              User–Generated Content. You hereby irrevocably waive any right to
              raise any objection or other claim before any authority including
              any copyright board in relation to the rights granted and licensed
              to us under these Terms, including any right under the provisions
              of section 30A of the (Indian) Copyright Act, 1957 or other
              applicable law. The above waiver is granted by you in favour of
              VHot and all of its group companies, affiliates and successors in
              title and interest, whether existing or in future.
            </li>
            <li>
              Indemnity. In the event you are required to indemnify us pursuant
              to these Terms or any order or ruling of a court of law, you will
              obtain all necessary approvals and consents from regulatory
              authorities for the remittance of such amount to us.
            </li>
          </ul>
          <p>
            Indonesia. If you are using our Services in Indonesia, the following
            additional terms apply. In the event of any conflict between the
            following additional terms and the provisions of the main body of
            these Terms, the following terms shall prevail.
          </p>
          <ul>
            <li>
              Limitation of Liabilities. No limitation of liabilities set out in
              Section 10 (Limitation of Liability) above shall not be applicable
              to the extent any loss or damage is incurred by you as a result of
              our willful misconduct or negligence.
            </li>
            <li>
              Age Limit. The Services are only for people 14 years old and over
              in Indonesia.
            </li>
            <li>
              Language. These Terms are signed in the English language and
              Indonesian language. In the event of any inconsistency or
              different interpretation between the English text and Indonesian
              text, the relevant Indonesian text shall be deemed to be
              automatically amended to conform with and to make the relevant
              Indonesian text consistent with the relevant English text. Each
              party acknowledges that it has read these Terms and understands
              its content and that these Terms have been entered into freely and
              without duress. The official text of these Terms shall be in
              English. The Parties agree that to comply with Law No. 24 of 2009
              of the Republic of Indonesia (“Law No.24/2009”) on Flag, Language,
              State Emblem and National Anthem applies to these Terms (as an
              agreement to which an Indonesian entity is a party), these Terms
              are signed in both English and Indonesian language. The parties
              agree that in the event of any dispute concerning the construction
              or interpretation of these Terms, reference shall be made only to
              the version as written in English and not to the Indonesian
              language version or any translation into any other language. You
              acknowledge that you fully understand the language and the content
              of these Terms, and you agree that you will not use the provisions
              under Law No.24/2009 to invalidate these Terms.
            </li>
            <li>
              Change to the terms. The following terms shall apply with priority
              over Section 3 (Changes to the Terms) above.
            </li>
            <ul>
              <li>
                We amend these Terms from time to time to the extent that is
                permitted by the applicable laws. In the event we amend these
                Terms, we will notify you of the effective date of the changes
                through (i) a notice to be posted on the landing page of our
                website or the splash screen of our mobile app or (ii) the
                contact information you provided to us. If you fail to
                explicitly express your objection to the amended Terms even
                though we notified you that your failure to do so within the
                above advance notification period will be considered as an
                acceptance of the changes, you will be considered to have agreed
                to the new Terms.
              </li>
            </ul>
          </ul>
          <p>
            United Arab Emirates. If you are using our Services in the United
            Arab Emirates (“UAE”), the following additional terms shall apply.
            In the event of any conflict between the following additional terms
            and the provisions of the main body of these Terms, the following
            terms shall prevail.
          </p>
          <ul>
            <li>
              Accepting the Terms: By agreeing to these Terms and accessing or
              using our Services, you acknowledge that you have read and
              understood these Terms, the additional terms herein and provide
              your consent to be bound by these Terms, the Privacy Policy, the
              Community Guidelines and all other policies or agreements referred
              to herein.
            </li>
            <li>
              Your access to and use of our Services: You may not use our
              Service to upload, transmit, distribute, store or otherwise make
              available in any way (including for the purpose of creating and/or
              streaming content) User Content that is contrary to any UAE
              applicable laws. In particular (without limitation), when
              generating User Content, you shall at all times (i) refrain from
              offending God and Islamic beliefs and show respect for other
              heavenly religions; (ii) show respect for the UAE government and
              its emblems and political institutions and the national identity
              of the UAE; and (iii) refrain from promoting alcoholic beverages,
              narcotics, tobacco and smoking of all kinds.
            </li>
            <li>
              Governing Law and Dispute Resolution: These Terms will be governed
              by and interpreted in accordance with the laws of the United Arab
              Emirates. In the event of a dispute arising between the parties in
              connection with these Terms:
            </li>
            <ul>
              <li>
                where the dispute relates to a claim for a sum within the limits
                specified by the DIFC Small Claims Tribunal from time to time,
                then the dispute shall be referred by either party to the said
                Tribunal; and
              </li>
              <li>
                for all other disputes, the parties shall seek settlements of
                that dispute by mediation in accordance with the Mediation Rules
                of the DIFC-LCIA Arbitration Centre, which Rules are deemed to
                be incorporated by reference to this clause. If the dispute is
                not settled by mediation within 30 days of the commencement of
                the mediation, or such further period as the parties shall agree
                in writing, the dispute shall be referred to and finally
                resolved by arbitration under the Arbitration Rules of the
                DIFC-LCIA Arbitration Centre, which Rules are deemed to be
                incorporated by reference to this clause. The language to be
                used in the mediation and in the arbitration, shall be English.
                In any arbitration commenced pursuant to this clause the number
                of arbitrators shall be one and the arbitration shall be
                conducted in Dubai.
              </li>
            </ul>
          </ul>
        </div>
      </Wrapper>
    </Layout>
  );
}

const Wrapper = styled.div`
  min-height: 100vh;
  h1,
  h2,
  p,
  i,
  li {
    color: #fff;
  }
  h1 {
    padding: 22px 0px;
  }
  h2 {
    padding: 15px 0px;
  }
  p {
    padding: 10px 0px;
  }
`;
