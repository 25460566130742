import React, { useState } from "react";
import Layout from "../../components/Layout";
// import request from "../utils/request";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import axios from "axios";

export default function Chat() {
  const hosts = useSelector((state: any) => state.user.hosts);
  const [roomNum, setRoomNum] = useState<number | undefined>();
  // const [myProfileData, setMyProfileData] = useState<any>({});
  const [inputText, setInputText] = useState<string>("");
  const [chatHistory, setChatHistory] = useState<Array<string>>([]);
  // const [username, setUsername] = useState<string>("");
  // const creatingGPRCaccountURL =
  //   "http://3.144.241.138:7350/v2/account/authenticate/device?create=true&username=username29";

  // const getUserData = async () => {
  //   let res: any = await request.post("user");
  //   setMyProfileData(res.data.data);
  //   handleCreatingGPRCaccount("username" + res.data.data.id);
  // };

  // const handleCreatingGPRCaccount = (username: string) => {
  //   let res = axios.post(creatingGPRCaccountURL, {
  //     auth: {
  //       username: "defaultkey",
  //       password: "",
  //     },
  //   });
  //   console.log(res);
  // };

  const getChatHistoryFromLocalStorage = (Rnum: string) => {
    const chatHistoryString: string | null | undefined = localStorage.getItem(
      "CHAT_HISTORY" + Rnum
    );
    if (!chatHistoryString) {
      setChatHistory([]);
    } else {
      setChatHistory(JSON.parse(chatHistoryString));
    }
  };

  const handleSentMessage = () => {
    if (inputText === "") {
      alert("发送的消息不能为空");
      return;
    }
    setChatHistory([...chatHistory, inputText]);
    localStorage.setItem("CHAT_HISTORY" + roomNum, JSON.stringify(chatHistory));
    setInputText("");
    window.scrollTo(-1, -1);
  };

  const clearAllChatHistory = () => {
    localStorage.removeItem("CHAT_HISTORY");
    setChatHistory([]);
  };

  const createGroupChat = () => {
    const num = Number(prompt("请创建房间号"));
    if (isNaN(num)) {
      alert("无效的房间号");
    } else {
      setRoomNum(num);
      getChatHistoryFromLocalStorage(num + "");
    }
  };

  const joinGroupChat = () => {
    const num = Number(prompt("请输入房间号"));
    if (isNaN(num)) {
      alert("无效的房间号");
    } else {
      alert("此房间号不存在");
    }
  };

  return (
    <>
      <Layout>
        {!roomNum ? (
          <PreCheckSection>
            <p className="m-4">测试 - WebSocket 群聊功能</p>
            <button onClick={() => createGroupChat()} className="mybtn m-3">
              创建群聊
            </button>
            <button onClick={() => joinGroupChat()} className="mybtn m-3">
              加入群聊
            </button>
          </PreCheckSection>
        ) : (
          <Wrapper>
            <div className="d-flex flex-row">
              <InfoWrapper>
                <TitleWrapper className="container d-flex flex-column justify-content-center align-items-start">
                  <p className="m-3">
                    房间号：<span className="text text-main">{roomNum}</span>
                  </p>
                  <div className="m-3">
                    <p>已加入用户：</p>
                    <div>
                      {hosts?.map((user: any) => (
                        <Link to={`/uploader/${user.id}`} key={user.id}>
                          <Avatar
                            className="cursor-pointer m-2"
                            src={user.avatar}
                          />
                        </Link>
                      ))}
                    </div>
                  </div>
                  <button
                    onClick={() => clearAllChatHistory()}
                    className="mybtn m-3"
                  >
                    清除聊天记录
                  </button>
                  <button
                    onClick={() => setRoomNum(undefined)}
                    className="mybtn m-3"
                  >
                    退出Vhot群聊
                  </button>
                </TitleWrapper>
              </InfoWrapper>
              <ChatWrapper>
                <ChatArea>
                  {chatHistory?.map((chatMessage, index) => (
                    <div
                      className="d-flex flex-row justify-content-end align-items-center my-4"
                      key={index}
                    >
                      <ChatString className="mx-2">{chatMessage}</ChatString>
                      {/* <Avatar src={myProfileData.avatar} /> */}
                    </div>
                  ))}
                </ChatArea>
                <TypingArea>
                  <div className="m-auto">
                    <InputBar
                      value={inputText}
                      onChange={(e) => {
                        setInputText(e.target.value);
                      }}
                      onKeyPress={(e: any) => {
                        if (e.key === "Enter") {
                          handleSentMessage();
                        }
                      }}
                      className="shadow-lg"
                    />
                    <button onClick={handleSentMessage} className="mybtn mx-3">
                      发送
                    </button>
                  </div>
                </TypingArea>
              </ChatWrapper>
            </div>
          </Wrapper>
        )}
      </Layout>
    </>
  );
}

const PreCheckSection = styled.div`
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    color: #fff;
    font-size: 22px;
  }
`;

const Wrapper = styled.div`
  padding: 20px 50px;
`;

const ChatString = styled.p`
  background-color: #fff;
  border-radius: 10px;
  color: black;
  padding: 10px 15px;
`;

const TitleWrapper = styled.div`
  padding-bottom: 20px;
  border: 1px solid grey;
  border-radius: 5px;
  p {
    font-size: 22px;
    color: #fff;
  }
`;

const Avatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0px 10px;
`;

const InfoWrapper = styled.div`
  width: 40%;
  margin: 10px;
  padding: 10px;
`;

const ChatWrapper = styled.div`
  width: 60%;
  margin: 10px;
  background-color: black;
`;

const ChatArea = styled.div`
  border-radius: 5px;
  border: 1px solid grey;
  height: 500px;
  padding: 40px;
  margin: 10px;
  overflow-y: scroll;
  overflow: -moz-scrollbars-vertical;
`;

const TypingArea = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
`;

const InputBar = styled.input`
  border-width: 0px;
  width: 400px;
  padding: 10px;
  outline: 0px;
  border-radius: 5px;
  font-size: 16px;
`;
