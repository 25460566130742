/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";

import VideosTemplate from "../../components/VideosTemplate";
import Layout from "../../components/Layout";
import request from "../../utils/request";

export default function Uploader() {
  const prams: any = useParams();
  const id: number = parseInt(prams.id);
  const [info, setInfo] = useState<any>({});

  const getUserInfo = async () => {
    const res: any = await request.post("user/get", {
      user_id: id,
    });
    setInfo(res.data.data);
  };

  const handleFollow = async () => {
    const res = await request.post("follow/on", {
      follow_id: info.id,
    });
    if (res.data.code === 200) {
      getUserInfo();
    } else {
      toast.error(res.data.msg);
      getUserInfo();
    }
  };

  const handleUnFollow = async () => {
    await request.post("follow/off", {
      follow_id: info.id,
    });
    getUserInfo();
  };

  useEffect(() => {
    getUserInfo();
  }, [id]);

  return (
    <Layout>
      <div className="d-flex flex-row justify-content-center align-items-center">
        <img
          style={{ borderRadius: "50%" }}
          className="m-4"
          src={info.avatar}
          width={80}
          height={80}
          alt="avatar"
        />
        <div className="text-light m-4">
          <h3 className="text-center text-light m-3">{info.nickname}</h3>
          <div className="d-flex flex-row">
            <p className="mx-3">关注: {info.follow_num}</p>
            <p className="mx-3">粉丝: {info.my_follow_num}</p>
            <p className="mx-3">喜欢: {info.like_num}</p>
          </div>
        </div>
        {info.is_follow === 0 ? (
          <button className="mybtn m-4" onClick={handleFollow}>
            关注
          </button>
        ) : (
          <button className="mybtn-grey m-4" onClick={handleUnFollow}>
            取消关注
          </button>
        )}
        {/* <Link to={`/p2pchat/${info.id}`}>
          <img
            className="mx-4 cursor-pointer"
            width={50}
            height={50}
            src={`/images/chat-icon.png`}
            alt="chat icon"
          />
        </Link> */}
      </div>
      <VideosTemplate payload={{ user_id: id }} />
    </Layout>
  );
}
