import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Layout from "../../components/Layout";
import request from "../../utils/request";
import { toast } from "react-toastify";
import axios from "axios";
import PuffLoader from "react-spinners/PuffLoader";
import html2canvas from "html2canvas";
import VideoSnapshot from "video-snapshot";
import UserInfoBar from "../../components/UserInfoBar";

export default function Signup() {
  const exportRef = useRef<any>("");
  const [categoryData, setCategoryData] = useState<Array<Object>>([]);
  const [tempVideoURL, setTempVideoURL] = useState<string>("");
  const [videoScreenShot, setVideoScreenShot] = useState<string>("");
  const [binaryFile, setBinaryFile] = useState<any>(null);
  const [title, setTitle] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [tag, setTag] = useState<string>("");
  const [tags, setTags] = useState<Array<string>>([]);
  const [loadingPercentage, setLoadingPercentage] = useState<number>(0);
  const [isVideoUploading, setIsVideoUploading] = useState<boolean>(false);

  const getBinaryDataFromFile = async (e: any) => {
    let file = e.target.files[0];
    if (file.type !== "video/mp4") {
      toast.error("Video must be .mp4 type.");
    } else {
      const videoURL: any = URL.createObjectURL(file);
      setTempVideoURL(videoURL);
      const buffer = await file.arrayBuffer();
      setBinaryFile(buffer);
    }
  };

  const getScreenShotFromVideo = async (e: any) => {
    let file = e.target.files[0];
    const snapshoter = new VideoSnapshot(file);
    const previewSrc = await snapshoter.takeSnapshot();
    setVideoScreenShot(previewSrc);
  };

  const getCategoryIDbyName = () => {
    let obj: any = categoryData.filter((el: any) => el.name === category);
    return obj[0].id;
  };

  const handleTagAdd = () => {
    if (tags.includes(tag)) {
      toast.warn("You have already added this tag.");
    } else {
      let newtags = tags;
      newtags.push(tag);
      setTags(newtags);
      setTag("");
    }
  };

  const handleTagRemove = (str: string) => {
    let newtags = tags;
    newtags = newtags.filter((el: string) => el !== str);
    setTags(newtags);
  };

  const handleClearData = () => {
    setCategoryData([]);
    setTempVideoURL("");
    setVideoScreenShot("");
    setBinaryFile(null);
    setTitle("");
    setCategory("");
    getVideoCategory();
    setTag("");
    setTags([]);
    setLoadingPercentage(0);
    setIsVideoUploading(false);
  };

  const getVideoCategory = () => {
    request
      .post("category", {
        pid: 2,
      })
      .then((res) => {
        setCategoryData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const preCheck = () => {
    if (binaryFile === null) {
      toast.warn("请选择视频");
      return false;
    } else if (title === "") {
      toast.warn("请输入标题");
      return false;
    } else if (category === "") {
      toast.warn("请选择分类");
      return false;
    } else {
      return true;
    }
  };

  const handleVideoPost = async () => {
    // STEP 1: put cover image into s3
    const canvas = await html2canvas(exportRef.current);
    const src = canvas.toDataURL("image/png", 1.0);
    const fetchRes = await fetch(src);
    const blob = await fetchRes.blob();
    const time = new Date().getTime();
    const file = new File([blob], `coverimage${time}.png`, blob);
    const FormData = require("form-data");
    const formData = new FormData();
    formData.append("file", file);
    let res = await request.post("video/uploadCover", formData);
    const coverImgURL = res.data.code === 200 ? res.data.data.url : "";
    // STEP 2: put video into S3
    res = await request.post("video/getUploadUrl");
    const videoURL = res.data.data.url;
    await axios.put(videoURL, binaryFile, {
      headers: {
        "Content-Type": "video/mp4",
      },
      onUploadProgress: (progressEvent) => {
        let current: number = progressEvent.loaded;
        let total: number = progressEvent.total;
        let percent: number = Math.floor((current / total) * 100);
        setLoadingPercentage(percent);
      },
    });
    let uploadURL = videoURL.split("?")[0];
    // STEP 3: commit uploading video
    const payload = {
      category_id: getCategoryIDbyName(),
      title: title,
      tags: tags.length !== 0 ? "" : tags.join(","),
      video_url: uploadURL,
      thumb: coverImgURL,
    };
    handleClearData();
    request
      .post("video/uploadCommit", payload)
      .then((res) => {
        if (res.data.code === 200) {
          toast.success("上传成功！");
        } else {
          console.log(res);
          toast.error("上传失败！");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getVideoCategory();
  }, []);

  return (
    <Layout>
      <div style={{ marginTop: "-50px" }}>
        <UserInfoBar />
      </div>
      <Wrapper>
        <Content>
          <LeftWrapper>
            {tempVideoURL === "" ? (
              <div className="d-flex justify-content-center align-items-center">
                <label
                  htmlFor="file-upload"
                  className="custom-file-upload"
                  style={{ textAlign: "center", padding: "70% 20%" }}
                >
                  点击上传 <br /> .mp4 视频文件
                  <br /> <br />
                  您上传的视频将显示在此处
                </label>
                <input
                  id="file-upload"
                  accept="video/mp4"
                  type="file"
                  onChange={(e) => {
                    getBinaryDataFromFile(e);
                    getScreenShotFromVideo(e);
                  }}
                />
              </div>
            ) : isVideoUploading ? (
              <CoverImageWrapper ref={exportRef}>
                <img src={videoScreenShot} width="100%" alt="coverimage" />
              </CoverImageWrapper>
            ) : (
              <TempVideoWrapper>
                <video
                  autoPlay
                  src={tempVideoURL}
                  width="100%"
                  height="100%"
                ></video>
              </TempVideoWrapper>
            )}
          </LeftWrapper>
          <RightWrapper>
            <Text>标题</Text>
            <Input
              value={title}
              style={{ width: "400px" }}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
            <Text>分类</Text>
            <Select
              value={category}
              onChange={(e) => {
                setCategory(e.target.value);
              }}
            >
              <Option></Option>
              {categoryData.map((el: any) => (
                <Option key={el.id}>{el.name}</Option>
              ))}
            </Select>
            <Text>标签</Text>
            <div className="d-flex flex-wrap flex-row justify-content-start align-items-center">
              {tags.map((el) => (
                <TagShow className="m-2" key={el}>
                  {"# " + el}
                  <TagRemoveIcon onClick={() => handleTagRemove(el)}>
                    x
                  </TagRemoveIcon>
                </TagShow>
              ))}
            </div>
            <div className="my-2">
              <Input
                value={tag}
                onChange={(e) => {
                  setTag(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleTagAdd();
                  }
                }}
                style={{ width: "240px" }}
              />
              <button className="mx-3 mybtn-grey" onClick={handleTagAdd}>
                添加
              </button>
            </div>
            <div className="my-5 d-flex flex-row">
              {isVideoUploading ? (
                <div className="d-flex flex-row justify-content-center align-items-center">
                  <PuffLoader
                    color="#fff"
                    loading={isVideoUploading}
                    size={40}
                  />
                  <p className="mx-3">{loadingPercentage + " %"}</p>
                </div>
              ) : (
                <button
                  className="mybtn"
                  type="submit"
                  onClick={() => {
                    if (preCheck()) {
                      setIsVideoUploading(true);
                      setTimeout(() => {
                        handleVideoPost();
                      }, 1000);
                    }
                  }}
                >
                  发布视频
                </button>
              )}

              <button
                className="mybtn-grey"
                style={{ marginLeft: "50px" }}
                onClick={handleClearData}
              >
                取消发布
              </button>
            </div>
          </RightWrapper>
        </Content>
      </Wrapper>
    </Layout>
  );
}

const Wrapper = styled.div`
  min-height: 100vh;
  color: #fff;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 60px;
`;

const LeftWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TempVideoWrapper = styled.div`
  background-color: black;
  width: 260px;
  height: 442px;
  img {
    z-index: 333;
  }
`;

const CoverImageWrapper = styled.div`
  background-color: black;
  width: 260px;
  height: 442px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RightWrapper = styled.div`
  width: 50%;
  padding: 0px 5%;
`;

const Text = styled.p`
  font-size: 18px;
  padding-bottom: 10px;
`;

const Input = styled.input`
  outline: 0px;
  padding: 8px;
  border-radius: 5px;
  border: 0px;
  margin-bottom: 20px;
  color: #fff;
  background-color: #bfb9b933;
`;

const Select = styled.select`
  color: #fff;
  background-color: #bfb9b933;
  padding: 10px;
  outline: 0px;
  width: 200px;
  border-radius: 5px;
  border: 0px;
  margin-bottom: 20px;
  :hover {
    cursor: pointer;
  }
`;

const TagShow = styled.p`
  font-size: 14px;
  border-radius: 10px;
  margin: 10px;
  padding: 10px 20px;
  border: 1px solid #fff;
  transition: 1s;
`;

const TagRemoveIcon = styled.span`
  font-size: 12px;
  margin-left: 10px;
  color: black;
  background-color: #fff;
  padding: 2px 8px;
  border-radius: 5px;
  transition: 0.4s;
  :hover {
    cursor: pointer;
    background-color: grey;
    color: #fff;
  }
`;

const Option = styled.option``;
