import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import axios from "axios";
import styled from "styled-components";
import { toast } from "react-toastify";

import Layout from "../../../components/Layout";
import request from "../../../utils/request";
import { handleLogin } from "../actions/index";

export default function Signup() {
  const navigate = useNavigate();
  const [registrationType, setRegistrationType] = useState<string>("email");
  const [email, setEmail] = useState<string>("");
  const [phoneNum, setPhoneNum] = useState<string>("");
  const [verificationCode, setVerificatonCode] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isRead, setIsRead] = useState<boolean>(false);

  const switchRegistrationTabPhone = () => {
    setRegistrationType("phone");
    setEmail("");
    setPassword("");
    setIsRead(false);
  };

  const switchRegistrationTabEmail = () => {
    setRegistrationType("email");
    setPhoneNum("");
    setVerificatonCode("");
    setPassword("");
    setIsRead(false);
  };

  const validateEmptyStringEmail = () => {
    if (email === "" || password === "") {
      toast.error("必填输入项不能为空");
      return false;
    } else {
      return true;
    }
  };

  const validateEmptyStringPhone = () => {
    if (phoneNum === "" || password === "") {
      toast.error("必填输入项不能为空");
      return false;
    } else {
      return true;
    }
  };

  const validateEmail = () => {
    if (
      String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      return true;
    } else {
      toast.error("邮箱的格式不正确");
      return false;
    }
  };

  const validatePassword = () => {
    if (password.length < 6) {
      toast.error("密码至少6位");
      return false;
    }
    if (password.search(/[a-z]/i) < 0) {
      toast.error("密码至少包含一个英文字母");
      return false;
    }
    if (password.search(/[0-9]/) < 0) {
      toast.error("密码至少包含一个数字");
      return false;
    }
    return true;
  };

  const validateDocRead = () => {
    if (isRead) {
      return true;
    } else {
      toast.error("请阅读并同意 Vhot 使用条款");
      return false;
    }
  };

  const getVerificationCode = async () => {
    let res = await axios.get("http://3.144.241.138:8088/getCode/" + phoneNum);
    if (res.data.status === "ok") {
      toast.success("短信验证码已发送，请查看手机！");
    } else {
      toast.error("短信验证码发送失败，请确认全球有效的手机号码！");
    }
  };

  const validateVerificationCode = async () => {
    if (verificationCode === "") {
      toast.error("验证码不能为空");
      return false;
    }
    let res = await axios.get("http://3.144.241.138:8088/checkCode", {
      params: {
        phone: phoneNum,
        code: verificationCode,
      },
    });
    console.log(res);
    if (res.data.status === "ok") {
      return true;
    } else {
      toast.error("验证码错误");
      return false;
    }
  };

  const handleEmailSignup = () => {
    if (
      validateEmptyStringEmail() &&
      validateEmail() &&
      validatePassword() &&
      validateDocRead()
    ) {
      request
        .post("register", {
          email: email,
          password: password,
        })
        .then((res) => {
          if (res.data.code === 400) {
            toast.error(res.data.msg);
          }
          if (res.data.code === 200) {
            handleLogin(email, password);
            navigate("/");
          }
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  const handlePhoneSignUp = async () => {
    if (
      validateEmptyStringPhone() &&
      validatePassword() &&
      validateDocRead() &&
      (await validateVerificationCode())
    ) {
      toast.success("手机号码验证成功！手机号验证码正确！");
    }
  };

  const handleSignup = () => {
    if (registrationType === "email") {
      handleEmailSignup();
    } else if (registrationType === "phone") {
      handlePhoneSignUp();
    } else {
      toast.error("Sign Up Error!");
    }
  };

  return (
    <Layout>
      <Wrapper>
        <ContentWrapper>
          <Title>创建Vhot账号</Title>
          <div>
            <button
              style={{
                opacity: registrationType === "email" ? 1 : 0.4,
              }}
              className="mybtn m-4"
              onClick={switchRegistrationTabEmail}
            >
              邮箱注册
            </button>
            <button
              style={{
                opacity: registrationType === "phone" ? 1 : 0.4,
              }}
              className="mybtn m-4"
              onClick={switchRegistrationTabPhone}
            >
              手机注册
            </button>
          </div>
          {registrationType === "email" ? (
            <div>
              <Bar>
                <Text>邮箱:</Text>
                <Input
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Bar>
              <Bar>
                <Text>密码:</Text>
                <Input
                  type="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </Bar>
            </div>
          ) : (
            <div>
              <Bar>
                <Text>手机号码:</Text>
                <Input
                  value={phoneNum}
                  onChange={(e) => {
                    setPhoneNum(e.target.value);
                  }}
                />
                <button
                  className="mybtn-grey my-3"
                  onClick={getVerificationCode}
                >
                  获取短信验证码
                </button>
              </Bar>
              <Bar>
                <Text>短信验证码:</Text>
                <Input
                  type="text"
                  value={verificationCode}
                  onChange={(e) => {
                    setVerificatonCode(e.target.value);
                  }}
                />
              </Bar>
              <Bar>
                <Text>密码:</Text>
                <Input
                  type="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </Bar>
            </div>
          )}

          <div className="d-flex flex-row justify-content-center align-items-center my-2">
            <CheckBox
              type="checkbox"
              checked={isRead}
              onChange={() => {
                setIsRead(!isRead);
              }}
            ></CheckBox>
            <p className="text-light m-2">
              我已阅读并同意
              <Link to="/rules">
                <span className="text-main"> Vhot使用条款</span>
              </Link>
            </p>
          </div>

          <p className="text-light my-3 text-center">
            已经拥有 Vhot 账号了? 在这里
            <a href="/signin">
              <span className="text-main"> 登录</span>
            </a>
          </p>
          <div className="text-center" onClick={handleSignup}>
            <button className="mybtn">注册</button>
          </div>
        </ContentWrapper>
      </Wrapper>
    </Layout>
  );
}

const Wrapper = styled.div`
  display: flex;
  padding-top: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  background-color: #bfb9b971;
  padding: 60px;
  margin-bottom: 80px;
  border-radius: 5px;
`;

const Title = styled.h2`
  color: #fff;
  text-align: center;
`;

const Bar = styled.div`
  margin: 15px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const Text = styled.p`
  color: #fff;
`;

const Input = styled.input`
  width: 300px;
  margin-top: 10px;
  border-radius: 10px;
  padding: 5px 10px;
  outline: 0px;
  border-width: 0px;
`;

const CheckBox = styled.input`
  width: 16px;
  height: 16px;
  transition: 0.4s;
  cursor: pointer;
`;
