/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import request from "../utils/request";
import VideoCard from "./VideoCard";

export default function VideosBar(props: any) {
  const [videos, setVideos] = useState<Array<any>>([]);

  const getVideos = async (num: number) => {
    let requestURL =
      props.payload.url === undefined ? "video/list" : props.payload.url;
    const res = await request.post(requestURL, {
      ...props.payload,
      type: 1,
      page: num,
    });
    setVideos(res.data.data.list);
  };

  useEffect(() => {
    getVideos(1);
  }, []);

  return (
    <div className="d-flex flex-row justify-content-center align-items-start flex-wrap">
      {videos.map((el: any) => (
        <VideoCard
          key={el.id}
          id={el.id}
          thumb={el.thumb}
          viewNum={el.view_num}
          likeNum={el.like_num}
          title={el.title}
          avatar={el.avatar}
          nickname={el.nickname}
          mtime={el.mtime}
          userId={el.user_id}
        />
      ))}
    </div>
  );
}
