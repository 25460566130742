/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import request from "../utils/request";
import VideoCard from "./VideoCard";

// this is the general component built for video rendering with top page navigation bar and page videos

export default function VideosTemplate(props: any) {
  const { payload } = props;
  const navigate = useNavigate();
  const [videos, setVideos] = useState<Array<any>>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);

  const getVideos = async (num: number) => {
    let requestURL =
      props.payload.url === undefined ? "video/list" : props.payload.url;
    const res = await request.post(requestURL, {
      ...props.payload,
      type: 1,
      page: num,
    });
    setVideos(res.data.data.list);
    setTotalPage(res.data.data.total_page);
  };

  const isActive = (active: boolean) => {
    return active
      ? {
          backgroundColor: "#df4156",
        }
      : {
          backgroundColor: "#bfb9b971",
        };
  };

  const getPageArray = () => {
    const arr = [];
    for (let i = 1; i <= totalPage; i++) {
      arr.push(i);
    }
    return arr;
  };

  useEffect(() => {
    getVideos(page);
  }, [payload]);

  return (
    <>
      {videos.length === 0 ? (
        <div className="text-center">
          <p className="text-light">抱歉，未找到此类的视频。</p>
        </div>
      ) : (
        <div>
          <div className="my-3 d-flex flex-row justify-content-center align-items-center flex-wrap">
            {getPageArray().map((el: number) => (
              <div className="p-3" key={el}>
                <PageNavItem
                  key={el}
                  style={isActive(el === page)}
                  onClick={() => {
                    setPage(el);
                    getVideos(el);
                  }}
                >
                  {el}
                </PageNavItem>
              </div>
            ))}
          </div>
          <div className="d-flex flex-row justify-content-center align-items-center flex-wrap">
            {videos.map((el: any) => (
              <VideoCard
                key={el.id}
                id={el.id}
                thumb={el.thumb}
                viewNum={el.view_num}
                likeNum={el.like_num}
                title={el.title}
                avatar={el.avatar}
                nickname={el.nickname}
                mtime={el.mtime}
                userId={el.user_id}
              />
            ))}
          </div>
          <div className="my-5 text-center" onClick={() => navigate(-1)}>
            <button className="mybtn-grey">返回</button>
          </div>
        </div>
      )}
    </>
  );
}

const PageNavItem = styled.p`
  color: #fff;
  border-radius: 50%;
  padding: 2px 8px;
  transition: 0.4s;
  :hover {
    cursor: pointer;
  }
`;
