import React from "react";
import { useEffect, useState } from "react";

import ReactLoading from "react-loading";
import InfiniteScroll from "react-infinite-scroll-component";

import Layout from "../../components/Layout";
import request from "../../utils/request";
import VideoCard from "../../components/VideoCard";

export default function Recomandation() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<Array<any>>([]);

  const getShortVideoData = () => {
    request
      .post("video/listRandom", {
        limit: 20,
      })
      .then((res) => {
        setData(res.data.data.list);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    setIsLoading(false);
  };

  const fetchMoreData = async () => {
    let res: any = await request.post("video/listRandom");
    if (res.data.code === 200) {
      setData([...data, ...res.data.data.list]);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getShortVideoData();
  }, []);

  return (
    <Layout>
      {isLoading ? (
        <div className="text-center d-flex flex-row justify-content-center">
          <ReactLoading
            type="bubbles"
            color="#e6005a"
            height={"100%"}
            width={160}
          />
        </div>
      ) : (
        <div>
          <InfiniteScroll
            className="d-flex flex-row flex-wrap justify-content-center align-items-center"
            dataLength={data.length}
            next={fetchMoreData}
            hasMore={true}
            loader={
              <div className="text-center d-flex justify-content-center align-items-center">
                <ReactLoading
                  type="bubbles"
                  color="#e6005a"
                  height={"100%"}
                  width={160}
                />
              </div>
            }
          >
            {data.length !== 0 &&
              data.map((el) => (
                <VideoCard
                  key={el.id}
                  id={el.id}
                  thumb={el.thumb}
                  viewNum={el.view_num}
                  likeNum={el.like_num}
                  title={el.title}
                  avatar={el.avatar}
                  nickname={el.nickname}
                  mtime={el.mtime}
                  userId={el.user_id}
                />
              ))}
          </InfiniteScroll>
        </div>
      )}
    </Layout>
  );
}
