import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import styled from "styled-components";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import VideoCallIcon from "@mui/icons-material/VideoCall";

import Avatar from "./Avatar";
import MobileNav from "./MobileNav";

export default function Header() {
  const [text, setText] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  function handleSearch() {
    text === ""
      ? toast.error("Your input can not be empty.")
      : navigate(`/search/${text}`);
  }

  return (
    <>
      <NavBar>
        <Link to="/">
          <img width="160px" src={`/images/vhot-t-logo.png`} alt="vhot logo" />
        </Link>
        <div style={{ position: "relative" }}>
          <SearchInput
            value={text}
            placeholder="搜索视频标题"
            onChange={(e: any) => setText(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
            type="text"
          />
          <SearchWrapper onClick={() => handleSearch()}>
            <SearchIcon style={{ color: "#fff", fontSize: "40px" }} />
            <label>搜索</label>
          </SearchWrapper>
        </div>
        <p className="text-light mybtn" onClick={() => setIsOpen(true)}>
          扫描下载 Vhot App 安卓版
        </p>
        <Dialog className="p-5" open={isOpen} onClose={() => setIsOpen(false)}>
          <div className="d-flex flex-column justify-content-center align-items-center p-3">
            <p className="text-center p-4">
              下载 <span style={{ color: "#E6005A" }}>Vhot</span>安卓APP
              <br />
              版本号: v0.0.5 <br />
              最近更新：2022-05-02
            </p>
            <img src="/images/frame.png" width={200} alt="qr-code" />
          </div>
        </Dialog>
        <div className="d-flex flex-row justify-content-center align-items-center">
          <div>
            <Link to="/upload" className="mx-2">
              <VideoCallIcon style={{ color: "#fff", fontSize: "30px" }} />
            </Link>
            <Link to="/message" className="mx-2">
              <NotificationsIcon style={{ color: "#fff", fontSize: "30px" }} />
            </Link>
            <FileDownloadIcon
              onClick={() => setIsOpen(true)}
              className="mx-2"
              style={{ color: "#fff", fontSize: "30px" }}
            />
          </div>
          <div className="d-flex flex-row justify-content-center align-items-center">
            {localStorage.getItem("token") === null ? (
              <Link to="/signin">
                <button className="mybtn mx-2">登录</button>
              </Link>
            ) : (
              <Avatar />
            )}
          </div>
        </div>
      </NavBar>
      <MobileNav />
    </>
  );
}

const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const SearchInput = styled.input`
  width: 360px;
  padding: 10px;
  border-radius: 5px;
  border: 0px;
  background-color: #bfb9b933;
  color: #fff;
  outline: 0px;
  ::placeholder {
    color: #fff;
  }
`;

const SearchWrapper = styled.button`
  outline: 0px;
  background: transparent;
  border: 0px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0px;
  top: -2px;
  z-index: 999;
  label {
    color: #fff;
    margin-right: 10px;
    :hoevr {
      cursor: pointer;
    }
  }
`;
