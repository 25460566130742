import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import Recomandation from "../pages/home/Recomandation";
import Main from "../pages/home/Main";
import Signup from "../pages/login/signup/Signup";
import Signin from "../pages/login/signin/Signin";
import Upload from "../pages/upload/Upload";
import Profile from "../pages/profile/Profile";
import Message from "../pages/message/Message";
import MyVideos from "../pages/myvideos/MyVideos";
import Like from "../pages/like/Like";
import Discover from "../pages/discover/Discover";
import Category from "../pages/category/Category";
import Uploader from "../pages/uploader/Uploader";
import Tags from "../pages/tags/Tags";
import Search from "../pages/search/Search";
import Following from "../pages/following/Following";
import VideoPlayer from "../pages/delete/VideoPlayer";
import PageNotFound from "../pages/404/PageNotFound";
import Rules from "../pages/general/Rules";
import Read from "../pages/general/Read";
import ReadEn from "../pages/general/ReadEn";
import Contact from "../pages/contact";
import TiktokHome from "../pages/home/TiktokHome";
import Chat from "../pages/chat/Chat";
import GroupChat from "../pages/chat/GroupChat";
import P2PChat from "../pages/chat/P2PChat";
import VhotMessenger from "../pages/vhotmessenger/VhotMessenger";
import Home from "../pages/home/Home";
import Settings from "../pages/settings/Settings";
import AboutApp from "../pages/AboutApp";

import PrivacyPolicy from "../pages/privacy-policy";
import TermsOfUseEn from "../pages/terms-of-use/en";
import TermsOfUseCn from "../pages/terms-of-use/cn";

const Routes = () => {
  const token = localStorage.getItem("token");
  const isLoggedIn = token !== null && token !== undefined;
  let routes = useRoutes([
    {
      path: "*",
      element: <PageNotFound />,
    },
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/recomandation",
      element: <Recomandation />,
    },
    {
      path: "/videos",
      element: <TiktokHome />,
    },
    {
      path: "/main",
      element: <Main />,
    },
    {
      path: "/tiktokhome",
      element: <TiktokHome />,
    },
    {
      path: "/discover",
      element: <Discover />,
    },
    {
      path: "/category/",
      element: <Category />,
    },
    {
      path: "/category/:data",
      element: <Category />,
    },
    {
      path: "/uploader/",
      element: <Uploader />,
    },
    {
      path: "/uploader/:id",
      element: <Uploader />,
    },
    {
      path: "/tags",
      element: <Tags />,
    },
    {
      path: "/tags/:tag",
      element: <Tags />,
    },
    {
      path: "/videoplayer",
      element: <VideoPlayer />,
    },
    {
      path: "/videoplayer/:id",
      element: <VideoPlayer />,
    },
    {
      path: "/search",
      element: <Search />,
    },
    {
      path: "/search/:keyword",
      element: <Search />,
    },
    {
      path: "/rules",
      element: <Rules />,
    },
    {
      path: "/read",
      element: <Read />,
    },
    {
      path: "/read-en",
      element: <ReadEn />,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
    {
      path: "/signup",
      element: <Signup />,
    },
    {
      path: "/about-app",
      element: <AboutApp />,
    },

    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/terms-of-service",
      element: <TermsOfUseEn />,
    },
    {
      path: "/terms-of-service-cn",
      element: <TermsOfUseCn />,
    },

    {
      path: "/vhotmessenger",
      element: isLoggedIn ? <VhotMessenger /> : <Signin />,
    },
    {
      path: "/groupchat",
      element: isLoggedIn ? <GroupChat /> : <Signin />,
    },
    {
      path: "/p2pchat",
      element: isLoggedIn ? <P2PChat /> : <Signin />,
    },
    {
      path: "/p2pchat/:uid",
      element: isLoggedIn ? <P2PChat /> : <Signin />,
    },
    {
      path: "/chat",
      element: isLoggedIn ? <Chat /> : <Signin />,
    },
    {
      path: "/signin",
      element: isLoggedIn ? <Home /> : <Signin />,
    },
    {
      path: "/followingvideos",
      element: isLoggedIn ? <Following /> : <Signin />,
    },
    {
      path: "/upload",
      element: isLoggedIn ? <Upload /> : <Signin />,
    },
    {
      path: "/profile",
      element: isLoggedIn ? <Profile /> : <Signin />,
    },
    {
      path: "/myvideos",
      element: isLoggedIn ? <MyVideos /> : <Signin />,
    },
    {
      path: "/like",
      element: isLoggedIn ? <Like /> : <Signin />,
    },
    {
      path: "/message",
      element: isLoggedIn ? <Message /> : <Signin />,
    },
    {
      path: "/settings",
      element: isLoggedIn ? <Settings /> : <Signin />,
    },
  ]);
  return routes;
};

export default function Routing() {
  return (
    <Router>
      <Routes />
    </Router>
  );
}
