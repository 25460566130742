import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function Rules() {
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const validateEmail = (email: string) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const handleSubmit = async () => {
    if (name === "" || email === "" || message === "") {
      alert(
        "Please fill out all information including name, email and message."
      );
    } else if (validateEmail(email)) {
      alert("Please provide a correct email address!");
    } else {
      alert("Thank you for your feedback!");
      window.location.reload();
    }
  };
  return (
    <Wrapper>
      <div className="container d-flex flex-column justify-content-center align-items-center">
        <Link to="/">
          <img
            className="my-3"
            width="160px"
            src={`/images/vhot-t-logo.png`}
            alt="vhot logo"
          />
        </Link>
        <h2 className="my-4">Contact Us</h2>
        <div>
          <div className="bar">
            <p>* Name:</p>
            <input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              type="text"
            />
          </div>

          <div className="bar">
            <p>* Email:</p>
            <input
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              type="email"
            />
          </div>

          <div className="bar">
            <p>Phone Number:</p>
            <input
              value={number}
              onChange={(e) => {
                setNumber(e.target.value);
              }}
              type="text"
            />
          </div>

          <div className="bar">
            <p>* Message:</p>
            <textarea
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
          </div>

          <div className="text-center my-5">
            <button onClick={() => handleSubmit()} className="mybtn">
              Submit
            </button>
          </div>

          <p>© 2022 Yexc Technologies Inc. All Rights Reserved.</p>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-width: 100vw;
  min-height: 100vh;

  background-image: linear-gradient(
    to bottom,
    #af7384,
    #a0727f,
    #9e7081,
    #926593,
    #7b5997,
    #6753a0,
    #070606
  );
  padding: 20px 0px;
  h3,
  h2,
  h5,
  p,
  i,
  li {
    color: #fff;
  }
  h3 {
    padding: 20px 0px;
  }
  h5 {
    padding: 15px 0px;
  }
  p {
    padding: 10px 0px;
  }

  > div {
    input {
      width: 100%;
      padding: 10px;
      border: 0px;
      border-radius: 4px;
    }
    textarea {
      border: 0px;
      border-radius: 4px;
      width: 100%;
      height: 120px;
      padding: 10px;
    }
    .bar {
      margin-bottom: 14px;
    }
  }
`;
