import styled from "styled-components";
import Header from "./Header";
import SideBar from "./SideBar";

export default function Layout(props: any) {
  return (
    <>
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>
      <Wrapper>
        <LeftWrapper className="side-scrollbar">
          <SideBar />
        </LeftWrapper>
        <RightWrapper>
          <div>{props.children}</div>
        </RightWrapper>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  background-image: linear-gradient(
    to bottom,
    #af7384,
    #a0727f,
    #9e7081,
    #926593,
    #7b5997,
    #6753a0,
    #070606
  );
  background-attachment: fixed;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
`;

const LeftWrapper = styled.div`
  width: 15%;
  position: fixed;
  /* background-color: black; */
  color: #fff;
  padding: 10px;
  height: 100vh;
  overflow-y: scroll;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const RightWrapper = styled.div`
  width: 85%;
  min-height: 100vh;
  margin-left: 15%;
  padding-top: 120px;
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: 0px;
  }
`;

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0px;
  width: 100%;
  padding: 10px;
  background-color: #8e6f78;
  z-index: 3;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;
