import React from "react";
import Layout from "../../components/Layout";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import styled from "styled-components";
import request from "../../utils/request";
import ReactPlayer from "react-player";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import VisibilitySensor from "react-visibility-sensor";
import InfiniteScroll from "react-infinite-scroll-component";

export default function Home() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>([]);
  const [currentPlayingVideoIndex, setCurrentPlayingVideoIndex] =
    useState<number>(0);

  const checkIsLogin = () => {
    if (localStorage.getItem("token") === null) {
      toast("请先登录账号");
      navigate("/signin");
      return false;
    } else {
      return true;
    }
  };

  const getShortVideoData = () => {
    request
      .post("video/listRandom")
      .then((res) => {
        setData(res.data.data.list);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleView = (index: number) => {
    let newData = data.slice();
    newData[index].view_num++;
    setData(newData);
    request.post("video/view", {
      vid: newData[index].id,
    });
  };

  const handleFollow = (userID: number) => {
    request
      .post("follow/on", {
        follow_id: userID,
      })
      .then((res) => {
        if (res.data.code === 200) {
          let newData = data.slice();
          newData.forEach((el: any) => {
            if (el.user_id === userID) {
              el.is_follow = 1;
            }
          });
          setData(newData);
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleUnFollow = (userID: number) => {
    request
      .post("follow/off", {
        follow_id: userID,
      })
      .then((res) => {
        if (res.data.code === 200) {
          let newData = data.slice();
          newData.forEach((el: any) => {
            if (el.user_id === userID) {
              el.is_follow = 0;
            }
          });
          setData(newData);
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleLike = (index: number) => {
    let newData = data.slice();
    if (newData[index].is_like === 0) {
      newData[index].is_like = 1;
      newData[index].like_num++;
      setData(newData);
      request.post("like/on", {
        vid: newData[index].id,
      });
    } else {
      newData[index].is_like = 0;
      newData[index].like_num--;
      setData(newData);
      request.post("like/off", {
        vid: newData[index].id,
      });
    }
  };

  const handleShare = () => {
    alert("share!");
  };

  const fetchMoreData = async () => {
    let res: any = await request.post("video/listRandom");
    if (res.data.code === 200) {
      setCurrentPlayingVideoIndex(-1);
      setData([...data, ...res.data.data.list]);
    }
  };

  useEffect(() => {
    getShortVideoData();
  }, []);

  return (
    <Layout>
      {isLoading ? (
        <div className="text-center d-flex flex-row justify-content-center">
          <ReactLoading
            type="bubbles"
            color="#e6005a"
            height={"100%"}
            width={160}
          />
        </div>
      ) : (
        <div
          onKeyDown={(e: any) => {
            if (e.key === "ArrowUp") {
              window.scrollBy(0, -800);
              // let idx = currentPlayingVideoIndex - 1;
              // let res: any = document.getElementById(idx + "");
              // res.scrollIntoView();
            }
            if (e.key === "ArrowDown") {
              window.scrollBy(0, 800);
            }
          }}
        >
          <div className="d-flex flex-column flex-wrap mx-md-5">
            <InfiniteScroll
              dataLength={data.length}
              next={fetchMoreData}
              hasMore={true}
              loader={
                <div className="text-center d-flex flex-row justify-content-center">
                  <ReactLoading
                    type="bubbles"
                    color="#e6005a"
                    height={"100%"}
                    width={160}
                  />
                </div>
              }
            >
              {data.length !== 0 &&
                data.map((el: any, index: number) => (
                  <VisibilitySensor
                    key={index}
                    onChange={(status: boolean) => {
                      status && setCurrentPlayingVideoIndex(index);
                    }}
                  >
                    <div key={index} id={`${index}`}>
                      <VideoWrapper>
                        {/* <p className="text-light">{index}</p> */}
                        <ReactPlayer
                          loop={true}
                          controls={true}
                          playing={index === currentPlayingVideoIndex}
                          url={el.video_url}
                          width="100%"
                          height="100%"
                          onPlay={() => {
                            handleView(index);
                          }}
                        />
                        <RightWrapper className="d-none">
                          <RightTopBar>
                            <Link to={`/uploader/${el.user_id}`}>
                              <UserWrapper>
                                <UserAvatar src={el.avatar} alt="avatar" />
                                <Text className="mx-4">{el.nickname}</Text>
                              </UserWrapper>
                            </Link>
                            {el.is_follow === 0 ? (
                              <button
                                className="mybtn"
                                onClick={() =>
                                  checkIsLogin() && handleFollow(el.user_id)
                                }
                              >
                                关注
                              </button>
                            ) : (
                              <button
                                className="mybtn-grey"
                                onClick={() =>
                                  checkIsLogin() && handleUnFollow(el.user_id)
                                }
                              >
                                取消关注
                              </button>
                            )}
                          </RightTopBar>
                          <Text>
                            <Icon>💻</Icon> 标题: {el.title}
                          </Text>
                          <Link
                            to={`/category/${el.category_id}@${el.category_name}`}
                          >
                            <Text>
                              <Icon>📦</Icon> 分类: {el.category_name}
                            </Text>
                          </Link>
                          <Text>
                            <Icon>⏰</Icon> 上传时间:{" "}
                            {el.created_at.split(" ")[0]}
                          </Text>
                          <div className="d-flex flex-sm-column flex-row flex-wrap">
                            <Function>
                              <p>观看</p>
                              <img
                                src={`./images/video-functions/view.png`}
                                alt="png"
                              />
                              <p>{el.view_num}</p>
                            </Function>
                            <Function>
                              <p>点赞</p>
                              <img
                                className={
                                  el.is_like === 1
                                    ? "animate__animated animate__bounceIn"
                                    : ""
                                }
                                src={
                                  el.is_like === 1
                                    ? "./images/video-functions/like1.png"
                                    : "./images/video-functions/like.png"
                                }
                                alt="png"
                                onClick={() =>
                                  checkIsLogin() && handleLike(index)
                                }
                              />
                              <p>{el.like_num}</p>
                            </Function>
                            <Function>
                              <p>评论</p>
                              <img
                                onClick={() => {
                                  checkIsLogin() &&
                                    navigate(`videoplayer/${el.id}`);
                                }}
                                src="./images/video-functions/comment.png"
                                alt="png"
                              />
                              <p>{el.comment_num}</p>
                            </Function>
                            <Function>
                              <p>分享</p>
                              <img
                                onClick={() => checkIsLogin() && handleShare()}
                                src="./images/video-functions/sharing.png"
                                alt="png"
                              />
                              <p>{el.share_num}</p>
                            </Function>
                          </div>
                        </RightWrapper>
                      </VideoWrapper>
                    </div>
                  </VisibilitySensor>
                ))}
            </InfiniteScroll>
          </div>
        </div>
      )}
    </Layout>
  );
}

const VideoWrapper = styled.div`
  background-color: black;
  /* border: 1px solid red; */
  height: 85vh;
  padding: 5vh;
  width: 100%;
`;

const RightWrapper = styled.div`
  width: 50%;
  padding: 0px 40px;
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 10px;
  }
`;

const RightTopBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
`;

const UserWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0px;
  transition: 0.4s;
  :hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;

const UserAvatar = styled.img`
  border-radius: 50%;
  width: 50px;
  height: 50px;
`;

const Text = styled.p`
  color: white;
  padding: 10px 0px;
`;

const Icon = styled.span`
  margin-right: 5px;
  font-size: 120%;
`;

const Function = styled.div`
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  img {
    width: 30px;
    height: 30px;
    margin: 0px 30px;
    :hover {
      cursor: pointer;
    }
  }
  p {
    color: white;
    margin: 0px !important;
  }
  @media only screen and (max-width: 768px) {
    margin: 20px;
  }
`;
