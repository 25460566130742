/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import styled from "styled-components";

import FavoriteIcon from "@mui/icons-material/Favorite";
import HomeIcon from "@mui/icons-material/Home";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import PublishIcon from "@mui/icons-material/Publish";

import request from "../utils/request";
import { SET_HOSTS, SET_TAGS, SET_CATEGORIES } from "../store/userSlice";

export default function SideBar() {
  const dispatch = useDispatch();
  const hosts = useSelector((state: any) => state.user.hosts);
  const tags = useSelector((state: any) => state.user.tags);
  const categories = useSelector((state: any) => state.user.categories);

  const getCategories = () => {
    request
      .post("category", {
        pid: 2,
      })
      .then((res) => {
        dispatch(SET_CATEGORIES(res.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getRecommendedHosts = () => {
    request
      .post("video/recommendUploader")
      .then((res) => {
        dispatch(SET_HOSTS(res.data.data.list));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getRecommendedTags = () => {
    request
      .post("video/recommendTag")
      .then((res) => {
        dispatch(SET_TAGS(res.data.data.list));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (hosts.length === 0) {
      getRecommendedHosts();
    }
    if (tags.length === 0) {
      getRecommendedTags();
    }
    if (categories.length === 0) {
      getCategories();
    }
  }, []);

  return (
    <Wrapper>
      <div
        style={{ backgroundColor: "#bfb9b933", borderRadius: "5px" }}
        className="d-flex flex-column justify-content-center align-items-center mb-5"
      >
        <Link to="/">
          <NavItem>
            <HomeIcon style={{ color: "#fff", fontSize: "30px" }} />
            <p>首页</p>
          </NavItem>
        </Link>
        <Link to="/recomandation">
          <NavItem>
            <LocalFireDepartmentIcon
              style={{ color: "#fff", fontSize: "30px" }}
            />
            <p>热门</p>
          </NavItem>
        </Link>
        <Link to="/videos">
          <NavItem>
            <ThumbUpIcon style={{ color: "#fff", fontSize: "30px" }} />
            <p>推荐</p>
          </NavItem>
        </Link>
        <Link to="/followingvideos">
          <NavItem>
            <PersonAddIcon style={{ color: "#fff", fontSize: "30px" }} />
            <p>关注</p>
          </NavItem>
        </Link>
        <Link to="/like">
          <NavItem>
            <FavoriteIcon style={{ color: "#fff", fontSize: "30px" }} />
            <p>喜欢</p>
          </NavItem>
        </Link>
        <Link to="/upload">
          <NavItem>
            <PublishIcon style={{ color: "#fff", fontSize: "30px" }} />
            <p>发布</p>
          </NavItem>
        </Link>
      </div>

      <div>
        <div>
          <div className="d-flex flex-row justify-content-between align-items-center">
            <p className="m-2">推荐用户</p>
            <img
              width={15}
              height={15}
              className="cursor-pointer mx-3"
              src="/images/refresh.png"
              alt="refresh"
              onClick={getRecommendedHosts}
            />
          </div>
          <div className="d-flex flex-row flex-wrap justify-content-center align-items-center">
            {hosts.map((el: any) => (
              <Link to={`/uploader/${el.id}`} key={el.id}>
                <img
                  className="m-2"
                  style={{ borderRadius: "50%" }}
                  width={40}
                  height={40}
                  src={el.avatar}
                  alt="avatar"
                />
              </Link>
            ))}
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <p className="m-2">发现标签</p>
          <img
            width={15}
            className="cursor-pointer mx-3"
            src="/images/refresh.png"
            alt="refresh"
            onClick={getRecommendedTags}
          />
        </div>
        <div className="d-flex flex-row flex-wrap my-3">
          {tags.length !== 0 &&
            tags.map((el: any) => (
              <Link to={`/tags/${el.name}`} key={el.id}>
                <DiscoverItem>{el.name}</DiscoverItem>
              </Link>
            ))}
        </div>
      </div>
      <hr />
      <p>版权所有: Richlink Technologies Inc.</p>
      <Link to="/read">
        <p className="my-3 text-light">社群自律公约</p>
      </Link>
      <p>
        建议与问题欢迎联系 email:{" "}
        <a style={{ color: "#DF4156" }} href="mailto:info@richlink.ca">
          info@richlink.ca
        </a>
      </p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  color: #eeeeee;
  padding: 20px;
  padding-top: 100px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const NavItem = styled.p`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  p {
    color: #fff;
    font-size: 17px;
    padding: 0px 10px;
    margin: 0px;
  }
  :hover {
    cursor: pointer;
    color: #d34079;
  }
`;

const DiscoverItem = styled.div`
  color: #fff;
  padding: 2px 10px;
  font-size: 13px;
  border: 1px solid grey;
  border-radius: 20px;
  margin: 5px;
  :hover {
    transition: 0.3s;
    cursor: pointer;
    background-color: #e6005a;
    border-color: #e6005a;
  }
`;
