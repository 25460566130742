import styled from "styled-components";

export default function Rules() {
  return (
    <Wrapper>
      <div className="container p-5">
        <h2>Community Self-discipline Convention</h2>
        <h3>Foreword</h3>
        <i>Last revision date: February 2022</i>
        <p>
          Vhot's mission is to inspire creativity and bring joy. We're building
          a global community where users can create, share, explore the world
          around them, and connect with people from around the world. We are
          committed to maintaining a supportive environment for growing
          communities. Our community handbook sets out a code and common code of
          conduct for Vhot to create a safe and friendly space for all users.
        </p>
        <p>
          At Vhot, security, diversity, inclusion and truth are our priorities.
          We encourage our creators to celebrate the things that make them
          special and our audiences to participate in the things that inspire
          them; We believe that a safe environment allows people to express
          themselves generously. We take the global nature of our community very
          seriously and are trying to think about the breadth of cultural norms
          from the perspective of the region in which we operate. We also
          actively encourage the publication of original content, hoping to make
          Vhot a truly interactive environment.
        </p>

        <p>
          Our community self-discipline agreement applies to all users and
          content on Vhot. We will use technology and manual review mechanisms
          to actively implement the terms of the community agreement before
          potentially violating content is reported. In addition, we also
          encourage community members to use our tools on Vhot to report content
          or accounts that they believe violate the community self-discipline
          convention.
        </p>

        <p>
          We will remove all content, including videos, audio files, live
          broadcasts, images, comments, hyperlinks and other text, that violates
          our community self-discipline. We will inform the user of our removal
          decision, and if the user believes that there is no violation, the
          user can appeal. We will suspend or permanently suspend accounts
          and/or users involved in serious or repeated violations; we may also
          refer to information obtained from other platforms and the Internet as
          a reference for decisions.
        </p>

        <p>
          We will report to law enforcement authorities where there is a
          real-world threat of specific, definite and immediate physical harm.
          The full-featured version of Vhot is only available for users aged 13
          and above, if we suspect that the user of the account does not meet
          this age, we will disable these accounts. (In the U.S., we have Vhot
          for Younger Users software for users under 13, which is a limited
          application software for younger users with additional child
          protection and privacy protection mechanisms.)
        </p>

        <p>
          Trust and security are at the core of our algorithm design. We may
          reduce the visibility of certain content by altering our search
          results, or by limiting the distribution of "For You" pages. You can
          find more information under Restricting Eligibility for Referral Page
          Posting.
        </p>

        <p>
          At the same time, we understand that some content that violates the
          Community Self-Regulatory Convention may be content that is in the
          public interest. Therefore, in rare cases, we may allow individual
          exceptions. Examples: educational, scientific, artistic, satirical
          content, fictional content, counter-speech, or content that allows
          individuals to express their opinion on important social issues. In
          order to minimize the potential negative impact of image content, we
          may first include security measures such as "click confirmation" pages
          or warnings.
        </p>

        <p>
          After consulting with relevant people, we will update the community
          self-discipline agreement from time to time to respond to emerging
          behaviors and risks at any time, so as to ensure that Vhot is a safe
          platform for creativity and joy.
        </p>

        <h3>Safety of Minors</h3>

        <p>
          We are committed to protecting the safety of minors on our platform.
          Vhot regards any user under the age of 18 as a minor. We prohibit any
          use of Vhot to cause sustained abuse, harm, harm or exploitation of
          minors. Any content that describes abuse, exploitation, or harm to
          minors (including media content that is displayed in animation,
          digitally produced, or modified) is a violation of regulations on this
          platform and will be removed once found. We provide child sexual abuse
          material (CSAM) and related evidence to the National Center for
          Missing and Exploited Children and other relevant law enforcement
          authorities.
        </p>

        <p>
          Users must meet the minimum age stated in our Terms of Service to use
          Vhot. Accounts of minors will be suspended once they are discovered.
          The safety of minors has always been a design consideration of this
          platform, so some of our functions have age restrictions. Account
          holders under the age of 16 cannot receive and send messages, cannot
          host live broadcasts, and published content will not be pushed to the
          "Recommended for you" page. In some regions, this age limit may be
          higher. In addition, account holders under the age of 18 cannot send
          or receive gifts through the virtual gift function.
        </p>

        <p>
          Sexual exploitation of minors Vhot will take action against any
          behavior related to child sexual abuse material (CSAM) or the sexual
          exploitation of minors. Sexual exploitation of minors includes any
          actual or attempted abuse of a minor's vulnerability, vulnerability or
          trust for sexual purposes (including obtaining monetary, social,
          sexual or political gain, etc.). Child Sexual Abuse Material (CSAM) is
          any visual depiction of explicit nudity or sexual activity, whether
          filmed by adults, peers, or minors themselves.
        </p>

        <p>Do not post, upload, live stream or share:</p>

        <p>
          Share, repost, offer to trade or sell or direct users to obtain or
          distribute child sexual abuse material outside the platform
          <br />
          Sexualized content related to minors or content that sexualizes minors
          (for example: using functions such as co-production videos)
          <br />
          Content that depicts, solicits, promotes, encourages imagery of child
          sexual abuse, including nudity, sexualizing or having sex with minors
          <br />
          Content that depicts, promotes, normalizes, or glorifies pedophilia or
          sexual abuse of minors
          <br />
          Content that re-injures or exploits underage victims through reposting
          or reproduction of violations or confessions by a third party sexually
          provocative behavior
          <br />
          Sexual grooming is when an adult enters into an emotional relationship
          with a minor in order to gain the minor's trust for future or ongoing
          sexual contact, sexual abuse, trafficking, or other exploitation.
          These behaviors include: courting minors, soliciting contact on or off
          the platform, requesting personal information, requests for lightly
          sexually abusive content, sexually alluring or pornographic messages,
          and gift giving, among others.
        </p>

        <p>Do not post, upload, live stream or share:</p>
        <p>
          Content that explicitly or implicitly depicts minor sexual activity,
          including penetrative and non-penetrative sex, oral sex, or intimate
          kissing <br />
          Depiction of content involving the sexual stimulation of minors
          <br />
          Content depicting sexual fetishes involving minors
          <br />
          Content that depicts the exposure of a minor's genitals, buttocks,
          pubic area, or female nipples
          <br />
          Contains sexually explicit language that describes or portrays minors
          <br />
          Depiction of minors, including sexually explicit lyrics
          <br />
          Depictions of minors performing sexually explicit dances, including
          swinging hips, shaking breasts, pushing pelvis, or touching their own
          or another's groin or breasts
          <br />
          Content that depicts minors undressing
          <br />
          Content that depicts minors wearing revealing clothing that is
          irrelevant to their context
          <br />
          Sexualized comments, emoticons, text, or other images that disguise or
          suggest nudity or sexual activity by minors
          <br />
          Harmful conduct by minors
          <br />
          We will also remove from the platform any content involving harmful
          behavior by minors. Harmful behavior by a minor includes possession or
          use of a minor's controlled substances and controlled substances,
          abuse of legal drugs, engaging in illegal activities, participation in
          activities that threaten the minor's health, physical challenges or
          adventures.
        </p>

        <p>Do not post, upload, live stream or share:</p>

        <p>
          Content that suggests, depicts, parodies, or promotes the possession
          or use of alcohol, tobacco, or drugs by minors <br />
          Content that specifically instructs minors on how to buy, sell, or
          trade alcohol, tobacco, or controlled drugs
          <br />
          Depiction or promotion of activities that may endanger the health of
          young people, including physical challenges, adventures, or stunts
          physical and mental harm to minors
          <br />
          We will also remove any content that causes physical and mental harm
          to minors from the platform. Behavior that places a minor at risk of
          physical or psychological harm, including: physical abuse, neglect,
          child endangerment and psychological harm.
        </p>

        <p>Do not post, upload, live stream or share:</p>
        <p>
          Content that depicts or promotes physical abuse, neglect, safety
          hazards, and psychological harm to minors crimes against children
        </p>

        <p>
          We prohibit any user who has been convicted of a crime against a child
          from opening an account on the Platform. Child abuse offenses include:
          sexual assault, sexual harassment, murder, physical abuse and neglect,
          kidnapping, international parental abduction crimes, trafficking,
          exploitation of minors for prostitution, online sexual assault of
          minors, sexual exploitation of minors in the tourism industry ,
          attempting to obtain or distribute child sexual abuse material (CSAM),
          and producing, possessing or distributing child sexual abuse material
          (CSAM), etc. Once discovered, we will immediately disable the accounts
          of these users. Any account holder who self-identifies as a pedophile
          or a minor sex offender, we will believe their claims and delete the
          account.
        </p>

        <h3>Risky Behaviors and Challenges</h3>

        <p>
          We prohibit content that depicts, promotes, normalizes, or glorifies
          dangerous behavior that could result in serious injury or death. We
          also don't allow content that promotes or supports group participation
          in dangerous or harmful activities that in any way violate the
          community's self-regulatory conventions.
        </p>

        <p>
          Risky activities or other dangerous behavior are behaviors that may
          cause serious injury or death to the user or others in a
          non-professional environment, or without the necessary skills and
          safety precautions, such as amateur stunts or dangerous challenges
          Wait.
        </p>

        <p>Do not post, upload, live stream or share:</p>
        <p>
          Content depicting potentially dangerous tools or items for
          inappropriate use
          <br />
          Content that describes dangerous driving behavior <br />
          Content that depicts or promotes the ingestion of non-edible
          substances that could cause serious harm
          <br />
          Content that describes or provides detailed instructions on how to
          conduct the hazardous activity Hazardous games, adventures, challenges
          or stunts that could result in injury or property damage
        </p>

        <h3>Suicide, Self-Injury, and Eating Disorders</h3>
        <p>
          We care deeply about the health and well-being of each member of our
          community. We prohibit content that depicts, promotes, normalizes or
          glorifies content that may lead to suicide, self-harm or eating
          disorders. However, we support members of our community in sharing
          their personal experiences with these issues in a safe manner to raise
          awareness and seek community support. We also encourage users who are
          struggling with suicidal or self-harm thoughts, or who know of someone
          who is seriously considering suicide, to contact local emergency
          services or a suicide prevention hotline immediately. Vhot may also
          notify local emergency services where our intervention can help users
          who may injure themselves.
        </p>

        <h3>Suicide and self harm</h3>
        <p>
          We remove content that depicts suicide, contains suicidal tendencies,
          or may encourage other suicidal or self-harming behaviors. We will
          also remove content that describes behavior in which someone attempted
          suicide that could lead to suicide. We prohibit posts that promote,
          normalize, or glorify suicide in any way, or provide instruction, and
          portray suicide as a heroic or honorable act. Vhot may report any
          situation that poses a specific, definite and immediate threat to
          someone's life to law enforcement.
        </p>

        <p>
          We also don't allow content that encourages or promotes suicide or
          self-harm hoaxes. Such conduct includes warnings that may cause panic
          and widespread harm. We will remove this warning content, while
          allowing content to reassure the public and inform the public about
          the purpose of this hoax.
        </p>

        <p>Do not post, upload, live stream or share:</p>
        <p>
          Content that depicts, promotes, normalizes, or glorifies suicide or
          self-harm <br />
          Content that provides instructions for suicide or self-harm <br />
          Games, adventures, challenges, agreements or pranks involving suicide
          or self-harm eating disorder <br />
          Content that promotes unhealthy eating behaviors or habits that may
          lead to adverse health outcomes, including expressing a desire for a
          disordered eating disorder, sharing tips or guidance on an eating
          disorder, and participating in an unhealthy posture challenge is
          prohibited on the platform.
        </p>

        <p>Do not post, upload, live stream or share:</p>
        <p>
          Content that depicts, promotes, normalizes, or glorifies eating
          disorders <br />
          Depicts, promotes, normalizes or glorifies any dangerous weight loss
          related to eating disorders
        </p>

        <h3>Adult Nudity and Sexuality</h3>
        <p>
          We are committed to creating a platform that makes users feel friendly
          and safe. We prohibit anyone from posting nudity, pornography or
          sexually explicit content on this platform. We also prohibit content
          that depicts or endorses non-consensual sexual activity, sharing
          non-consensual intimate images, and adult sexual solicitation.
        </p>

        <h3>Sexual Exploitation</h3>

        <p>
          Sexual exploitation is any actual or attempted abuse of vulnerability,
          vulnerability or trust for sexual purposes, including financial,
          social or political gain from the sexual exploitation of another. We
          prohibit sexually exploitative content.
        </p>

        <p>Do not post, upload, live stream or share:</p>
        <p>
          Content that depicts, induces, promotes, normalizes, or glorifies
          non-consensual sexual activity or non-consensual touching, including
          rape and sexual assault <br />
          Content that depicts, induces, promotes, normalizes, or glorifies the
          sharing of non-consensual intimate images, including: sexual images
          taken, produced, or shared without consent <br />
          Content that depicts, promotes, normalizes, or glorifies sexual
          violence <br />
          Content that depicts, normalizes, or glorifies sexual solicitation,
          including: offering or seeking sexual partners, chats or images,
          sexual services, paid sexual content, or sex cams <br />
          Nudity and Sexual Conduct Involving Adults <br />
          Nudity and sexual activity includes public display of breasts,
          genitalia, anus, or buttocks, or acts that mimic, suggest, or
          demonstrate sexual acts.
        </p>
        <p>
          We prohibit depictions of nudity or sexual acts, including digitally
          created or modified content. We are careful to note that certain
          content may be offensive or culturally offensive in certain regions,
          or may not be suitable for users of all ages
        </p>
        <p>Do not post, upload, live stream or share:</p>

        <p>
          Explicit or implied depictions of sexual activity, including
          penetrative and non-penetrative sex, oral sex, or erotic kissing{" "}
          <br />
          Content that depicts sexual stimulation
          <br />
          Content depicting sexual fetishes
          <br />
          Content depicting exposed genitals, buttocks, pubic area, or female
          nipples
          <br />
          Content containing sexually explicit language for sexual gratification
          harassment and bullying
          <br />
          We honor an inclusive community where users can express themselves
          freely without fear of being bullied. We have no tolerance for
          humiliation, bullying or harassment of members of our community.
          Abusive content or behavior will be removed if it has a serious impact
          on mental health.
        </p>

        <h3>Abusive behavior</h3>
        <p>
          We remove language that expresses abusive language, including threats
          or insults intended to ridicule, insult, embarrass, intimidate, or
          hurt an individual. This limitation applies to all functions of Vhot.
          To allow users to express issues of public interest, we allow personal
          judgments about public figures; however, we prohibit content that
          strongly abuses public figures.
        </p>

        <p>Do not post, upload, live stream or share:</p>
        <p>
          Content that insults or demeans another person based on traits such as
          intelligence, appearance, personality traits, or health <br />
          Content that encourages coordinated harassment <br />
          Content that demeans victims of violence <br />
          Use Vhot interactive features such as co-production videos to demean
          others Content that depicts intentional harm or intimidation, such as
          cyberstalking or online trolling <br />
          Content that expects death, serious illness, or other serious harm to
          an individual sexual harassment <br />
          Sexual harassment includes unwanted or inappropriate sexual conduct
          directed at another person. We prohibit any content that glorifies,
          normalizes, or promotes sexual harassment, regardless of user intent.
        </p>

        <p>Do not post, upload, live stream or share:</p>
        <p>
          Content that attempts to engage in unwanted sexual contact
          <br />
          Content that demeans another's sexuality <br />
          Content that simulates sexual activity with another person through
          voice, text (including emoticons), or use of any in-app functionality
          <br />
          Content that modifies or distorts images of others to depict or
          suggest sexual advances or acts
          <br />
          Content that reveals or threatens to reveal details of someone's
          private sexual life, including digital content, sexual history, and
          names of former sexual partners
          <br />
          Reveal or threaten to reveal someone's sexual orientation without
          their consent
          <br />
          Threats of Hacking, Bounty Hunting, and Extortion
          <br />
          Threats of hacking or sex-searching others with the intent to harass
          or blackmail them can cause serious emotional distress and other
          real-life harm to others. Rousou is the act of maliciously collecting
          and publishing personal or personally identifiable information. We
          consider these acts to be violent and prohibit them on this platform.
        </p>

        <p>Do not post, upload, live stream or share:</p>
        <p>
          Content that may reveal personal or personally identifiable data,
          including: residential addresses, private email addresses, private
          phone numbers, bank statements, social security numbers, national ID
          numbers, or passport numbers, etc. Content that threatens to blackmail
          or hack into someone else's account Content that incites or encourages
          others to hack or disclose another person's account, profile, or
          personally identifiable information a person's personal account
          number, data or identity data and encourages others to abuse, troll or
          harass that person acts of hate Vhot is a diverse and inclusive
          community with zero tolerance for discrimination. We prohibit and will
          remove content that contains hate speech or acts of hate. We will
          disable accounts that involve hate speech and/or are associated with
          severe or repeated hate speech outside of the Vhot platform.
        </p>

        <h3>Attacks and Defamation Targeting Protected Issues</h3>
        <p>
          Content that attacks, threatens, incites violence, or otherwise
          dehumanizes individuals or groups of people on the following topics is
          considered hate speech or conduct and will be removed from the
          platform:
        </p>
        <p>
          Race <br />
          ethnicity <br />
          Country of Citizenship <br />
          religion <br />
          caste <br />
          sexual orientation <br />
          biological sex <br />
          gender <br />
          gender identity <br />
          serious illness <br />
          Disability <br />
          immigration status
        </p>
        <p>
          Defamation is a derogatory term intended to belittle a group or
          individual with any of the above-mentioned protected qualities. In
          order to most effectively reduce the spread of offensive terms, we
          remove all defamatory content from our platform unless the terms are
          reappropriated, self-referential (e.g. self-used by members of a
          protected group), or not derogatory . (e.g. content posted for
          educational purposes)
        </p>
        <p>Do not post, upload, live stream or share:</p>
        <p>
          Content that asserts that individuals or groups of people with
          protected qualities are physically, mentally, or morally inferior to
          others, or that deem them criminals, animals, inanimate objects, or
          other non-human bodies Content that promotes or justifies violence,
          exclusion, segregation, or discrimination against them Contains
          content that is not self-referentially defamatory Content that targets
          transgender or non-binary people through incorrect gender
          classification or misnomering Content that depicts harm caused to an
          individual or group of individuals by targeting protected traits hate
          ideology The ideology of hate is the manifest hostility of others
          because of the qualities that should be protected. Hateful ideologies
          are not consistent with the inclusive and supportive community that
          this platform provides, and content promoting these ideologies will be
          removed.
        </p>
        <p>Do not post, upload, live stream or share:</p>
        <p>
          Content that glorifies, promotes, glorifies or supports any hateful
          ideology (e.g. white supremacy, misogyny, anti-LGBTQ, anti-Semitism)
          Content that contains names, symbols, logos, flags, slogans, uniforms,
          gestures, salutes, illustrations, portraits, songs, music, lyrics, or
          other objects representing hate ideologies. Content that advocates,
          supports, or advertises sexual orientation therapy and related
          programs Content that denies well-documented incidents of violence
          affecting groups with protected attributes (Holocaust denial) claim
          superiority over groups with protected traits Conspiracy Theories Used
          to Justify Hateful Ideologies
        </p>
        <h3>violent extremism</h3>
        <p>
          We firmly oppose the use of violence both on and off the Vhot
          platform. We prohibit users from using this platform to threaten
          others or incite violence, or to promote violent extreme individuals,
          organizations or actions. If it poses a threat to public safety or
          uses an account to promote or beautify violence off-platform, we will
          disable the account and report it to relevant law enforcement agencies
          when necessary. In order to effectively protect our community, we may
          refer to behavior on other platforms to identify violent extremist
          organizations and individuals on the platform. If we find such
          organizations or individuals on Vhot, we will disable their accounts.
        </p>

        <h3>Threats and Incitement to Violence</h3>
        <p>
          We consider inciting violence to be advocating, directing or
          encouraging others to commit violence. We prohibit the use of threats
          of violence or incitement to violence on the Platform where serious
          physical harm may result.
        </p>
        <p>Do not post, upload, live stream or share:</p>
        <p>
          Statements intended to cause physical harm to an individual or group
          Statements or images that encourage others to commit or promote
          violence Statements that condition or intend to encourage violence A
          call to bring a weapon to a location with the intent to intimidate or
          threaten an individual or group with violence Guidelines for the
          manufacture and use of weapons that incite violence Violent extremist
          organizations and individuals We prohibit individuals or organizations
          that promote or engage in violence on our platform, including:
          terrorist organizations, organized hate groups, criminal
          organizations, and other non-state armed groups that target civilians.
        </p>

        <h3>Terrorist Organization</h3>
        <p>
          Terrorists and terrorist organizations are non-state actors who
          threaten violence, use violence and/or commit serious crimes against
          civilians (such as crimes against humanity) in pursuit of political,
          religious, racial or ideological goals.
        </p>

        <h3>Organized Hatred</h3>
        <p>
          "Organizational hatred" is the attack by individuals and organizations
          on the basis of protected characteristics, such as race, ethnicity,
          nationality, religion, caste, sexual orientation, sex, gender, gender
          identity, or immigration status. We consider attacks to include acts
          that incite violence or hatred, intend to dehumanize an individual or
          group, or harbor a hateful ideology.
        </p>

        <h3>Criminal Organization</h3>
        <p>
          A criminal organization is a transnational, national or local group
          that engages in serious crimes, including violent crime (e.g.
          homicide, rape, robbery, assault), trafficking (e.g. human, organs,
          drugs, weapons), kidnapping, financial crime ( such as extortion,
          extortion, fraud, money laundering) or cybercrime, etc.
        </p>

        <p>Do not post, upload, live stream or share:</p>

        <p>
          Content that glorifies, promotes, glorifies, or supports violent or
          extremist individuals and/or organizations Content that encourages or
          seeks to recruit individuals to join violent or extremist groups
          Content containing names, symbols, logos, flags, slogans, uniforms,
          gestures, salutes, illustrations, likenesses, songs, music, lyrics, or
          other objects representing dangerous individuals and/or organizations
          Integrity and Authenticity We believe that trust is the cornerstone of
          this community. We prohibit activities that may compromise the
          integrity of the platform or the authenticity of users. We remove
          misleading information that involves spam or fake engagement,
          impersonation, or causes serious harm
        </p>

        <h3>Spam and Fake Engagement</h3>
        <p>
          False participation includes any content or activity intended to
          artificially increase the popularity of the platform. We prohibit any
          attempt to manipulate the platform to increase engagement.
        </p>

        <p>Do not:</p>
        <p>
          Share instructions on how to artificially increase views, likes,
          followers, shares or comments Participate in selling or buying clicks,
          likes, followers, shares or comments Advertise artificial traffic
          production services Operating multiple fake or fraudulent Vhot
          accounts to spread commercial spam Create malware or modify program
          code to artificially increase views, likes, followers, shares or
          comments
        </p>

        <h3>Fake Name</h3>
        <p>
          We prohibit creating accounts that impersonate other people or
          entities. Once the counterfeit report is confirmed, we may ask the
          user to modify the personal information, and may also disable the
          account. However, if the account is clearly a fan, comment or parody
          account, if it is indicated in the user name and has nothing to do
          with the character, we will allow the existence of parody, comment or
          fan accounts.
        </p>

        <p>
          Do not: Participate in falsification (e.g. use of multiple accounts)
          to influence and change public opinion while misleading individuals,
          the community and the system about the identity, location or purpose
          of accounts
        </p>

        <h3>Harmful Misinformation</h3>
        <p>
          Error messages are incorrect or erroneous content. We will remove any
          misinformation, regardless of intent, that causes significant harm to
          an individual, community, or society at large. Significant injury
          includes serious bodily injury, illness, or death; severe
          psychological trauma; massive property damage; and disruption of
          public trust in civic institutions and processes, such as government,
          electoral, and scientific institutions. This does not include simply
          incorrect information, myths or commercial and reputational damage.
        </p>
        <p>Do not post, upload, live stream or share:</p>
        <p>
          Misinformation that promotes hatred or bigotry Misinformation related
          to an emergency that will cause panic False medical information that
          could harm an individual's physical health Content that misleads
          community members about elections or other civic engagement processes
          Attacks specific protected groups, or includes conspiracy theory
          content that calls for violent action, or denies that violent or
          tragic events occurred Digital falsification (synthetic or modified
          media) that misleads users by distorting the truth of events and
          causes harm to the protagonist of the film, others, or society DO NOT:
          Participate in falsification (e.g. use of multiple accounts) to
          influence and change public opinion while misleading individuals, the
          community and the system about the identity, location or purpose of
          accounts
        </p>

        <h3>Illegal Acts and Controlled Substances</h3>
        <p>
          We are committed to ensuring that no activities that violate laws or
          regulations occur on the Vhot platform. We prohibit the trade, sale,
          promotion and use of certain regulated products, as well as the
          promotion or facilitation of criminal activity, including human
          exploitation. Content may be removed for activities or products that
          are regulated or illegal globally or in most regions.
        </p>
        <h3>criminal behavior</h3>
        <p>
          Criminal conduct involves a variety of conduct punishable by law,
          including: theft, assault, human exploitation, counterfeiting, and
          other harmful conduct. To prevent these behaviors from being
          normalized, parodied or promoted, we remove content that promotes or
          supports criminal behavior
        </p>
        <p>Do not post, upload, live stream or share:</p>
        <p>
          Content that promotes acts of physical harm, such as assault or
          kidnapping Content that may endanger the safety of others, including:
          false alarms Content that promotes human exploitation, including:
          human smuggling, bonded labor, domestic servitude, sex trafficking, or
          prostitution Content that promotes vandalism or damage to property
          Content that promotes poaching or the illegal trade in wildlife Offers
          to buy, sell, trade, or solicit illegally obtained or counterfeit
          goods Content that provides guidance on how to conduct criminal
          activity that causes damage to people, animals or property
        </p>

        <h3>arms</h3>
        <p>
          Weapons We prohibit the depiction, promotion, or trade in firearms,
          firearm accessories, ammunition, or explosive weapons. We also
          prohibit crafting guides for these weapons. We allow depictions of
          weapons in educational settings (eg, as part of a museum collection),
          with the involvement of authorized government personnel (eg, police),
          or when used in a safe and controlled environment (eg, shooting range,
          recreational hunting).
        </p>
        <p>Do not post, upload, live stream or share:</p>
        <p>
          Content featuring firearms, firearm accessories, ammunition, or
          explosive weapons Offers to buy, sell, trade, or solicit the use of,
          or instructions for making, firearms, firearm accessories, ammunition,
          or explosive weapons
        </p>

        <h3>Drugs, Controlled Substances, Alcohol & Tobacco</h3>
        <p>
          We prohibit the depiction, promotion, or dealing in drugs or other
          controlled substances. Trading of tobacco and alcohol products is also
          prohibited on the platform.
        </p>
        <p>Do not post, upload, live stream or share:</p>
        <p>
          Content that depicts or promotes drugs, uses drugs, or encourages the
          manufacture, use, or trade of drugs or other controlled substances
          Offers to buy, sell, trade or encourage the use of narcotics or other
          controlled drugs, alcohol or tobacco products (including vape
          products, smokeless or combustible tobacco products, synthetic
          nicotine products, electronic cigarettes and other EDNS [electronic
          nicotine delivery systems]) Content Provides how to buy illegal or
          controlled drugs Content that depicts or promotes the abuse of legal
          drugs or the creation of homemade drugs with the intent to become
          addicted Fraud and Scams We prohibit anyone from using this platform
          and taking advantage of users' trust to cause property or personal
          harm to others. We remove content that defrauds others for illegal
          financial or personal gain, including schemes to defraud individuals
          or steal assets.
        </p>

        <p>Do not post, upload, live stream or share:</p>
        <h3>Content promoting phishing</h3>
        <p>
          Content that promotes Ponzi schemes, multi-level schemes, or rat
          societies (pyramid schemes) Content that promotes guaranteed high
          payouts, fixed-odds betting, or any other type of fraudulent
          investment scheme gamble We prohibit the promotion of gambling
          services, or content that could be viewed as an advertisement for
          casinos, sports betting, or any other commercial gambling activity.
        </p>

        <p>
          Do not post, upload, stream, or share: Content that promotes casinos,
          sports betting, poker, lotto, gambling-related software and
          applications, or other gambling services
        </p>

        <h3>
          Privacy, Personal Data and Personally Identifiable Information (PII)
        </h3>

        <p>
          We prohibit content that violates the confidentiality of personal or
          personally identifiable data (such as ID information, phone numbers,
          physical addresses, etc.). We will remove content that depicts
          personal and personally identifiable information from the platform
        </p>

        <p>
          Do not post, upload, live stream or share: Content that contains
          personal data and personally identifiable information (PII)
        </p>

        <h3>Violence and Gore</h3>
        <p>
          Vhot is a platform that celebrates creativity over scare value or
          violence. We prohibit gruesome, gory, sadistic or gruesome content on
          our platform, or content that promotes, normalizes or glorifies
          extreme violence or suffering. If it poses a threat to public safety,
          we will suspend or deactivate the account and report it to relevant
          law enforcement agencies when necessary.
        </p>

        <p>Do not post, upload, live stream or share:</p>
        <p>
          Post, upload, stream, or share: Content that depicts: Violent or
          bloody death or accident Dismembered, mutilated, charred or
          incinerated human remains Focus on open wounds or blood stains from
          injuries Real-world physical violence and fighting, as well as torture
          in real and fictional settings Content that depicts the following
          animals: Animals slaughtered or otherwise unnaturally killed
          Mutilated, mutilated, charred or burnt remains of animals animal
          cruelty and its blood Copyright and Trademark Infringement Copyright
          is a legal right intended to protect an author's original work of work
          (eg, music, film) and original concept of expression (eg, a particular
          way of expressing or making a film or music), but not the underlying
          idea or fact. A trademark is a word, symbol, slogan or design that
          marks and distinguishes the source of a product or service. We
          encourage everyone to create and share original content. This platform
          prohibits any content that infringes the intellectual property rights
          of others. Once found, we will remove it. Using a copyrighted work
          under certain circumstances, such as the doctrine of fair use or other
          applicable laws, or citing a trademark, legally commenting,
          criticizing, parodying, creating a fan page, or reviewing a product or
          service may not be considered a violation of our content of the
          policy.
        </p>

        <p>
          Do not post, upload, stream, or share: Content that violates or
          infringes upon the copyrights, trademarks, or other intellectual
          property rights of others
        </p>

        <h3>platform security</h3>
        <p>
          Vhot's top priority is the safety and security of our users, creators,
          partners, suppliers and employee data, including their personal
          information, account numbers, files, content and other private
          information, as well as our products and services. We do not allow
          unauthorized access to the Vhot Platform (including our website,
          applications, network and related infrastructure or networks), or
          access to sensitive commercial or personal confidential information.
          We expressly prohibit any attempt to undermine or abuse the security,
          integrity or reliability of our platforms, products or services.
        </p>

        <p>Do not:</p>
        <p>
          Attempt to gain access to the Vhot platform in an unauthorized manner,
          do not create false or misleading versions of the Vhot platform Create
          or distribute malicious files, content or messages that contain
          viruses, Trojan horses, computer worms, logic bombs or other material
          that may be harmful to the community or platform Use automated
          scripts, web crawlers, software, spoofing techniques, or any other
          means to attempt to harvest, obtain, or request login credentials or
          other sensitive information, including non-public data, from Vhot or
          its users False or fraudulent use of Vhot accounts to spread spam,
          phishing or scam content in an attempt to commit cybercrime or gain
          unauthorized access to another's content, accounts, systems or data
          Modify, adapt, translate, reverse engineer, disassemble, decompile, or
          create any derivative work based on Vhot, including any document,
          form, or document, or attempt to reproduce any source program,
          algorithm, method, or technique contained in Vhot Provide others with
          access to your account credentials, or allow others to take actions
          that violate our Community Self-Regulatory Pact Click on suspicious
          hyperlinks or engage in requests for Vhot account details, passwords,
          verification eligibility, financial or other personal information
          about you Restrict the eligibility for posting recommended pages for
          you The goal of the For You Feed page is to fulfill our mission to
          inspire creativity and bring joy to diverse communities around the
          world. The page recommended for you by each account user is based on a
          personalized recommendation system. Our recommendation system is
          designed with user safety in mind, which means that some content may
          not be eligible for recommendations. While ideas for you are what
          makes Vhot great, it's recommended for an audience that includes
          everyone from our teenage users, to our great-grandparents. We will
          consider the breadth of the audience to determine which content is
          eligible to be pushed on the platform through algorithms. While not in
          violation of local laws, we may allow the following types of content
          to appear on our platform, be searched for, and be viewed in the
          "Follow" feed, although some content may not be eligible for
          recommendation for you .
        </p>
        <h3>minor safety</h3>
        <p>
          A minor's sense of judgment and identity matures as they grow. In an
          effort to give teens more room to explore and express their identities
          in digital spaces, user-uploaded content under the age of 16 is not
          eligible to be recommended for you. Additionally, we strive to
          encourage healthy development at all ages. Recommending cosmetic
          surgery for minors may lead to higher rates of body dissatisfaction.
          Therefore, this type of content (including before and after surgery
          videos, surgery videos, and content discussing elective cosmetic
          surgery without risk warnings) does not qualify as recommended for you
          by all users under the age of 18.
        </p>

        <h3>Dangerous stunts and sports</h3>
        <p>
          Reproducing certain activities without safety precautions may present
          risks or hazards and may result in bodily injury. Content that
          includes dangerous stunts or extreme sports performed by
          non-professionals may not be eligible to recommend pages for you. (We
          remove content that depicts dangerous behaviors and challenges
          performed by non-professionals that could result in serious injury or
          death to users or the public).
        </p>

        <h3>Explicit sexual content</h3>
        <p>
          We understand that not all content is suitable for all users
          (especially our teenage users) and/or may be culturally inappropriate
          in some regions. In order to provide a comfortable and age-appropriate
          experience for all users, sexually explicit content may not be
          eligible to be recommended for you. This includes content depicting
          implied nudity, the sexualization of body parts, or overtly sexual or
          carnal content such as striptease.
        </p>

        <h3>Tobacco and Alcohol Products</h3>
        <p>
          Vhot is committed to protecting the safety of minors on our platform.
          Content that promotes, refers to, or depicts alcohol products in a
          dangerous manner to persons of legal drinking age may not be eligible
          for recommendation. Content depicting adult use of tobacco products or
          references to regulated substances is also not eligible. Additionally,
          Vhot prohibits content that suggests, depicts, imitates, or promotes
          the possession or consumption of alcohol, tobacco, or drugs by minors.
          Our Community Guidelines also prohibit content that addresses minors
          with instructions on how to buy, sell, or trade alcohol, tobacco, or
          controlled substances.
        </p>

        <h3>Violence and Graphic Content</h3>
        <p>
          Our community celebrates creativity, but not everyone likes shocking
          values. Content likely to cause discomfort, shock, or disgust to
          viewers may not be eligible to recommend a page for you, including
          horror effects, sudden shocks, make-up that realistically replicate
          bloody wounds, or depictions of bodily functions. Most violent and
          graphic content is prohibited on Vhot; however, in some cases, we may
          not remove some of this content, for example, when it is in the public
          interest to record real events or image descriptions of battles.
          Because this content is not suitable for all audiences, it is not
          eligible to suggest pages for you and will contain a "confirm click"
          page or warning.
        </p>

        <h3>Spam, Inauthentic or Misleading Content</h3>
        <p>
          We value authenticity and accuracy. Anything that tricks or
          manipulates users to improperly increase followers, likes, views, or
          other metrics of engagement on the platform is not eligible to
          recommend pages for you, including follow-to-follow, like-to- like or
          manipulate users to like or share. Conspiracy content that goes
          against generally accepted belief and places blame on a group or
          entity (rather than any individual person), or harmful content that is
          ongoing, details of which are still developing and potentially
          misleading, also does not qualify for recommendation.
        </p>

        <h3>Unoriginal, Low-quality Content with QR Codes</h3>
        <p>
          Original and entertaining content is a core building block of the Vhot
          community. In order to maintain a positive user experience on the Vhot
          platform, unoriginal and low-quality content is not eligible for
          recommendation. If content is imported or uploaded from Vhot, other
          platforms, or other sources (including TV, film, or webcasts), and no
          new, creative edits have been added by the user, we consider it
          non-original content. One indicator that the content is not original
          is a visible watermark or overlay. Low-quality content includes
          extremely short clips, still images, and entirely GIF-based movies.
          Content containing a QR code is generally not eligible to recommend
          pages for you because it may direct users to harmful websites or
          applications, but exceptions are made in certain cases where the risk
          is low (e.g. e-commerce) .
        </p>

        <p>
          Thank you for being a part of our vibrant global community and joining
          us in providing a welcoming space for all users. If you find content
          or accounts that you think may violate our community self-discipline
          convention, please let us know so that we can review and take
          appropriate measures.
        </p>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-image: linear-gradient(
    to bottom,
    #af7384,
    #a0727f,
    #9e7081,
    #926593,
    #7b5997,
    #6753a0,
    #070606
  );
  padding: 20px 0px;
  min-height: 100vh;
  h1,
  h2,
  h3,
  h3,
  p,
  i,
  li {
    color: #fff;
  }
  h3 {
    padding: 20px 0px;
  }
  h3 {
    padding: 15px 0px;
  }
  p {
    padding: 10px 0px;
  }
`;
