import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Layout from "../../components/Layout";
import request from "../../utils/request";
import { Link } from "react-router-dom";

export default function Discover() {
  const [categories, setCategories] = useState<Array<Object>>([]);

  const getVideoCategory = () => {
    request
      .post("category", {
        pid: 2,
      })
      .then((res) => {
        setCategories(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getVideoCategory();
  }, []);

  return (
    <Layout>
      <Title>视频分类</Title>
      <div className="px-3 d-flex flex-row flex-wrap justify-content-center align-items-center">
        {categories.length !== 0 &&
          categories.map((el: any) => (
            <Link to={`/category/${el.id}@${el.name}`} key={el.id}>
              <Card>
                <CardTitle>{el.name}</CardTitle>
              </Card>
            </Link>
          ))}
      </div>
    </Layout>
  );
}

const Title = styled.h3`
  font-weight: 300;
  text-align: center;
  color: #fff;
  padding: 20px 0px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 340px;
  border-radius: 20px;
  margin: 30px;
  border: 1px solid pink;
  background-image: url(/images/vhot-cover.png);
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.4s;
  :hover {
    cursor: pointer;
    opacity: 0.4;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: row;
    width: 120px;
    height: 200px;
    margin: 20px;
  }
`;

const CardTitle = styled.p`
  color: #fff;
  background-color: black;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 300;
`;
