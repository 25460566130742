import styled from "styled-components";

export default function Rules() {
  return (
    <Wrapper>
      <div className="container p-5">
        <h2>社群自律公约</h2>
        <h3>前言</h3>
        <i>最新修改时间：2022年 2月</i>
        <p>
          Vhot的使命是激发创造力，带来欢乐。我们正在建立一个让用户可以在其中创造、分享、探索周遭世界，并与来自世界各地的人们建立连系的全球社群。我们致力于为逐渐茁壮的社群维持互助的环境。我们的社群手册为Vhot订定了一套规范与通用行为准则，藉此为所有用户创造一个安全且友善的空间。
        </p>
        <p>
          在Vhot，安全、多元、包容与事实是我们的优先考虑。我们鼓励创作者赞扬使他们与众不同的事物，并鼓励观众参与激发他们灵感的事物；我们相信，安全的环境可以让人们大方地表达。我们非常重视本社群全球化的特质，并且正努力从我们运营地区的角度出发思考文化规范的广度。我们也积极透过鼓励发表原创内容，希望将Vhot打造成具有真实互动的环境。
        </p>
        <p>
          我们的社群自律公约适用于Vhot上所有用户与内容。我们会藉由科技与人工审核的机制，期望在潜在违规内容被检举之前便主动落实社群公约条款。此外，我们也鼓励社群成员使用我们在Vhot上的工具来检举他们认为违反社群自律公约的内容或账号。
        </p>
        <p>
          我们将移除所有违反我们的社群自律公约的内容，包括影片、音文件、直播、图像、评论、超链接与其他文字。我们会告知用户我们的移除决定，若用户认为未有任何违规行为，用户可以提出上诉。我们将暂停或永久地停用涉及严重或屡次违规的账号和／或用户；我们亦可能参考从其他平台以及网络上获得的信息做为决定的参考。
        </p>
        <p>
          若涉及到在现实世界中可能会造成具体、确切及立即人身的伤害的威胁，我们将会报告给执法当局。全功能版的
          Vhot仅适用于 13
          岁及以上的用户，若我们怀疑账号的用户未符合这个年龄，我们会停用这些账号。（在美国，我们有专为13
          岁以下的用户提供的 Vhot for Younger Users
          软件，这是专为低龄用户开发的有限应用软件，并具有额外的儿童保护和隐私保护机制。）
        </p>
        <p>
          信任与安全性是我们算法的设计核心。我们可能会透过改变搜寻结果，或限制「为您推荐」页面的发布来降低某些内容的能见度。您可以在限制推荐页面发布资格下方取得更多信息。
        </p>
        <p>
          同时，我们理解有些违反社群自律公约的内容可能是符合公共利益目的的内容。因此，在少数情况下，我们可能会允许个别例外。例如：教育、科学、艺术、讽刺的内容、虚构的内容、反制言论，或者能够让个人针对重要社会议题发表意见的内容。为了尽量减少影像内容的潜在负面影响，我们可能会先加入「确认点击」的页面或警告等安全措施。
        </p>
        <p>
          在与相关人士咨询后，我们会不定时地更新社群自律公约，以随时因应新出现的行为与风险，藉此确保Vhot是提供创意与欢乐的安全平台。
        </p>
        <h3>未成年人的安全</h3>
        <p>
          我们致力于保护平台上未成年人的安全。Vhot视任何未满18岁的用户视为未成年人。我们禁止任何使用Vhot对未成年人造成持续虐待、伤害、危害或剥削的行为。任何关于描述虐待、剥削或对未成年人造成危害的内容(包括以动画展示、数字制作或修改的媒体内容)，在本平台上均属违规，一经发现将一律移除。我们会将儿童性虐待内容（CSAM）及相关证据提供给国家失踪与受虐儿童援助中心和其他相关执法当局。
        </p>
        <p>
          用户必须符合我们服务条款中规定的最低年龄才能使用Vhot。未成年人的账号，一经发现将一律停用。未成年人的安全一直是本平台的设计考虑，因此我们的某些功能设有年龄限制。未满16岁的账号持有人不能接收且传送讯息、不能主持直播，且发布的内容不会被推送到「为您推荐」的页面上。在部分地区，这个年龄限制可能会更高。此外，未满18岁的账号持有人无法透过虚拟礼物功能发送或取得礼物。
        </p>
        <h5>对未成年人的性剥削</h5>
        <p>
          Vhot会对任何与儿童性虐待内容（CSAM）或未成年人造成性剥削的相关行为采取行动。对未成年人的性剥削行为包括任何实际或企图滥用未成年人的脆弱、弱势或信任来达到性目的(包括从中获得金钱、社会、性或政治利益等)的行为。儿童性虐待内容（CSAM）为任何不论是由成年人、同侪或未成年人自行拍摄的露骨裸露或性行为的视觉描述内容。 
        </p>
        <p>请勿发布、上传、直播或分享：</p>
        <ul>
          <li>
            分享、转发，提供交易或出售或指引用户在平台之外获取或散布儿童性虐待素材的内容
          </li>
          <li>
            与未成年人相关的性化内容或性化未成年人的内容（例如：利用合拍影片等功能）
          </li>
          <li>
            描述、诱使、宣传、鼓励儿童性虐待图像的内容，包括裸露、性化未成年人或与未成年人发生性行为
          </li>
          <li>描述、宣传、正常化或美化恋童癖或性侵未成年人的内容</li>
          <li>
            透过第三方转载或重现侵害或供词来再度伤害或利用未成年受害者的内容
          </li>
        </ul>
        <h5>性略诱行为</h5>
        <p>
          性略诱行为是指成年人与未成年人建立情感关系以获取未成年人的信任，以便将来或持续进行性接触、性虐待、贩运或其他剥削。这些行为包括：讨好未成年人、请求平台上或平台外的联系、请求提供个人信息、请求提供轻微的性虐待内容、性诱惑或色情留言以及送礼等。 
        </p>
        <p>请勿发布、上传、直播或分享：</p>
        <ul>
          <li>主动的性略诱行为</li>
          <li>描述、宣传、正常化或美化性略诱行为的内容</li>
          <li>
            怂恿未成年人与成年人、或年龄差异较大的未成年人之间发生真实性接触的内容
          </li>
          <li>向未成年人展示或提供裸露的内容</li>
          <li>
            诱使未成年人在其他平台、网站或任何其他数字空间上与成年人接触的内容
          </li>
          <li>透过勒索或其他胁迫手段诱骗裸露图片或性接触</li>
        </ul>
        <h5>涉及未成年人的裸露和性行为</h5>
        <p>
          涉及未成年人的裸露和性行为包括过度展露乳房、生殖器官、肛门或臀部的内容，或涉及未成年人模仿、暗示或展示性行为的行为。我们禁止描绘裸露或性行为，包括以数字制作或修改的内容。
        </p>
        <p>请勿发布、上传、直播或分享：</p>
        <ul>
          <li>
            明确或暗示描绘未成年人性行为的内容，包括插入与非插入性行为、口交或亲密接吻
          </li>
          <li>描绘涉及未成年人性刺激的内容</li>
          <li>描绘涉及未成年人性恋物癖的内容</li>
          <li>描绘暴露未成年人的生殖器、臀部、耻骨区或女性乳头的内容</li>
          <li>包含描述或描绘未成年人露骨色情语言的内容</li>
          <li>描绘未成年人的内容，其中包含露骨的色情歌词</li>
          <li>
            描绘未成年人进行露骨的性感舞蹈，包括摆动臀部、摇晃乳房、推挤骨盆或抚摸自己或他人的腹股沟或胸部
          </li>
          <li>描绘未成年人脱衣的内容</li>
          <li>描述未成年人身穿与身处环境无关的暴露衣着的内容</li>
          <li>
            用于掩饰或暗示未成年人裸露或性行为的性化留言、图释、文字或其他图像
          </li>
        </ul>
        <h5>未成年人的有害行为</h5>
        <p>
          我们也将从平台中疑除任何涉及未成年人有害行为的内容。
          未成年人的有害行为包括持有或使用未成年人管制品及管制药物、滥用合法药物、从事非法活动、参加威胁未成年人健康的活动、体能挑战或大冒险。
        </p>
        <p>请勿发布、上传、直播或分享：</p>
        <p></p>
        <ul>
          <li>
            建议、描述、模仿或宣传未成年人持有或使用酒精饮料、烟草或毒品的内容
          </li>
          <li>
            专门指导未成年人如何购买、出售或交易酒精，烟草或管制药品的内容
          </li>
          <li>
            描述或宣传可能危害青少年健康的活动内容，包括体能挑战、大冒险或特技
          </li>
        </ul>
        <h5>未成年人的身心伤害</h5>
        <p>
          我们也将从平台中移除任何造成未成年人身心伤害的内容。
          使未成年人面临身体或心理伤害风险的行为，包括：身体虐待、忽视、危及儿童安全和心理伤害。
        </p>
        <p>请勿发布、上传、直播或分享：</p>
        <p>描述或宣传对未成年人的身体虐待、疏忽、危害安全和心理伤害的内容</p>
        <h5>针对儿童的犯罪</h5>
        <p>
          我们禁止任何被裁定犯有侵害儿童罪行的用户在本平台上开设帐号。儿童侵犯罪行包括：性侵犯、性骚扰、谋杀、身体虐待与疏忽、绑架、国际父母诱拐犯罪、贩运、利用未成年人卖淫、对未成年人在线性侵犯，旅游产业中对未成年人的性剥削、试图获取或散布儿童性虐待内容（CSAM），以及生产、拥有或散布儿童性虐待内容（CSAM）等。一但发现，我们将立即停用这些用户的账号。任何自我表明为恋童癖或未成年人性犯罪者的账号持有人，我们会相信其说词并删除账号。
        </p>
        <h3>危险行为与挑战</h3>
        <p>
          我们禁止发布描述、宣传、正常化或美化可能导致重伤或死亡的危险行为内容。同时，我们也不允许宣传或支持集体参与在任何方面违反社群自律公约的危险或有害活动的内容。
        </p>
        <p>
          具有风险的活动或其他危险行为，是指在非专业环境中，或者没有必要技能与安全预防措施情况下，可能导致使用者或其他人重伤或死亡的行为，例如：业余特技或危险的挑战行为等。
        </p>
        <p>请勿发布、上传、直播或分享：</p>
        <ul>
          <li>描述具有潜在不当使用危险工具或物品的内容</li>
          <li>描述危险驾驶行为的内容</li>
          <li>描述或宣传摄入非食用物质并可能造成严重伤害的内容</li>
          <li>描述或提供有关如何进行危险活动的详细说明的内容</li>
          <li>可能导致受伤或财产损失的危险游戏、冒险、挑战或特技</li>
        </ul>
        <h3>自杀、自我伤害与饮食障碍</h3>
        <p>
          我们深切关心每个组成我们社群成员的健康和福祉。我们禁止描述、宣传、正常化或美化可能导致自杀、自我伤害或饮食障碍的内容。然而，我们支持我们社群成员以安全的方式分享他们对这些问题的个人经验，以提高人们的意识并寻求社群的支持。我们也鼓励饱受自杀或自我伤害念头煎熬的用户，或者知道有人正在认真考虑自杀的用户，立即联系当地紧急服务机构或自杀预防热线。在我们的介入能够帮助可能会伤害自己的用户，Vhot也可能通报当地紧急服务机构。
        </p>
        <h5>自杀和自我伤害</h5>
        <p>
          我们会移除描述自杀、包含自杀倾向或可能鼓励其他具有自杀倾向或自我伤害行为的内容。我们也会移除描述某人企图自杀可能导致自杀的行为的内容。我们禁止以任何形式宣传、正常化或美化自杀，或提供指示，以及将自杀形容为英雄或光荣行为的贴文。若有任何会对某人的生命造成具体、明确且立即威胁的情况发生，Vhot可能会向执法单位报告。
        </p>
        <p>
          我们也不允许鼓励或宣扬自杀或自残恶作剧的内容。此类行为包括可能引起恐慌和广泛危害的警告内容。我们将移除此类警告内容，同时允许发布以消弭恐慌并告知大众有关此类恶作剧目的的正确信息内容。
        </p>
        <p>请勿发布、上传、直播或分享：</p>
        <ul>
          <li>描述、宣传、正常化或美化自杀或自我伤害的内容</li>
          <li>提供自杀或自我伤害指示的内容</li>
          <li>自杀或自我伤害之游戏、冒险、挑战、协议或恶作剧</li>
        </ul>
        <h5>饮食障碍</h5>
        <p>
          平台上禁止宣传可能导致不良健康后果的不健康饮食行为或习惯的内容，包括表达对饮食失调的渴望、分享饮食失调的技巧或指导以及参与不健康体态挑战的内容。
        </p>
        <p>请勿发布、上传、直播或分享：</p>
        <ul>
          <li>描述、宣传、正常化或美化饮食障碍的内容</li>
          <li>描述、宣传、正常化或美化任何与饮食障碍相关之危险减重内容</li>
        </ul>
        <h3>成人裸露与性行为</h3>
        <p>
          我们致力于打造让用户感到友善又安全的平台。我们禁止任何人在本平台上发布裸露、色情或露骨的情色内容。我们也禁止描述或支持非经双方同意的性行为、分享非经双方同意的亲密图像与成人性诱惑的内容。
        </p>
        <h5>性剥削</h5>
        <p>
          性剥削系指任何实际或企图滥用脆弱、弱势或信任来达到性目的的行为，包括从他人的性剥削中获得金钱、社会或政治利益。我们禁止性剥削的内容。
        </p>
        <p>请勿发布、上传、直播或分享：</p>
        <ul>
          <li>
            描述、诱使、宣传、正常化或美化非经双方同意的性行为或非经双方同意的触摸的内容，包括强奸和性侵害
          </li>
          <li>
            描述、诱使、宣传、正常化或美化分享非经双方同意的亲密图像的内容，包括：未经同意而拍摄、制作或分享的性爱图像
          </li>
          <li>描述、宣传、正常化或美化性暴力的内容</li>
          <li>
            描述、正常化或美化性引诱的内容，包括：提供或寻求性伴侣、性聊天或图像、性服务、付费性内容或性爱视讯
          </li>
        </ul>
        <h5>涉及成年人的裸露和性行为</h5>
        <p>
          裸露和性行为包括公开显示乳房、生殖器官、肛门或臀部的内容，或模仿、暗示或展示性行为的行为。我们禁止描绘裸露或性行为，包括以数字制作或修改的内容。我们会谨慎留意某些内容在某些地区可能令人反感或在违反文化风俗，或者可能不适合所有年龄层的用户
        </p>
        <p>请勿发布、上传、直播或分享：</p>
        <ul>
          <li>
            明确或暗示描绘性行为的内容，包括插入与非插入性行为、口交或情色接吻
          </li>
          <li>描绘性刺激的内容</li>
          <li>描绘性恋物癖的内容</li>
          <li>描绘暴露的生殖器、臀部、耻骨区或女性乳头的内容</li>
          <li>包含满足性欲的露骨色情语言的内容</li>
        </ul>
        <h3>骚扰和霸凌</h3>
        <p>
          我们所尊崇的是一个包容的社群，使用户得以自由表达，不必担心受到欺凌。我们无法容忍社群成员遭到羞辱、霸凌或骚扰。若辱骂的内容或行为会严重影响心理健康，我们会将其移除。
        </p>
        <h5>辱骂行为</h5>
        <p>
          我们会移除表达辱骂的言论，包括意图嘲笑、侮辱、使人难堪、恐吓或伤害个人的威胁或侮辱言词。这项限制适用于Vhot所有功能上。为了让用户能够表达有关公共利益的议题，我们允许针对公众人物发表个人的评断意见；
          然而，我们禁止强烈辱骂公众人物的内容。
        </p>
        <p>请勿发布、上传、直播或分享：</p>
        <ul>
          <li>
            侮辱他人或基于智力、外貌、人格特质或卫生状况等特质贬低他人的内容
          </li>
          <li>鼓励协调骚扰的内容</li>
          <li>贬低暴力事件受害者的内容</li>
          <li>使用Vhot互动功能（例如合拍影片）贬低他人</li>
          <li>描述故意伤害或恐吓的内容，例如：网络跟踪或在线引战</li>
          <li>期待对个人造成死亡、严重疾病或其他严重伤害的内容</li>
        </ul>
        <h5>性骚扰</h5>
        <p>
          性骚扰包括针对另一人违反意愿或不适当的性行为。无论用户的意图为何，我们都禁止任何美化、正常化或提倡性骚扰的内容。
        </p>
        <p>请勿发布、上传、直播或分享：</p>
        <ul>
          <li>试图进行违反他人意愿的性接触的内容</li>
          <li>贬损他人性行为的内容</li>
          <li>
            透过语音、文字（包括图释）或使用任何应用程序内的功能来仿真与其他人进行性行为的内容
          </li>
          <li>将他人图像修改或变形以描述或暗示性挑逗或进行性行为的内容</li>
          <li>
            泄露或威胁泄露某人私人性生活细节的内容，包括数字内容、性史与前性伴侣的名字
          </li>
          <li>在未经某人同意的情况下，泄露或威胁泄露其性取向的内容</li>
        </ul>
        <h5>黑客入侵、肉搜与勒索的威胁</h5>
        <p>
          以骚扰或勒索他人的意图来威胁对他人进行黑客攻击或肉搜的行为可能会导致他人严重的情绪困扰与其他现实生活中的伤害。肉搜是出于恶意收集与发布个人资料或个人身份资料的行为。我们认为这些行为皆为暴力，并且禁止在本平台进行上述行为。
        </p>
        <p>请勿发布、上传、直播或分享：</p>
        <ul>
          <li>
            可能泄露个人资料或个人身份数据的内容，包括：居住地址、私人电子邮件地址、私人电话号码、银行对账单、社会保险号码、身分证号码或护照号码等
          </li>
          <li>勒索或入侵他人账号的威胁内容</li>
          <li>
            煽动或鼓励他人入侵或泄露他人账号、个人资料或个人身份资料的内容
          </li>
          <li>
            某人的个人账号、数据或身分数据，并鼓吹他人滥用、引战或骚扰某人
          </li>
        </ul>
        <h3>仇恨行为</h3>
        <p>
          Vhot是一个多元而包容的社群，对歧视零容忍。我们禁止且会移除包含仇恨言论或涉及仇恨行为的内容。我们会停用涉及仇恨言论的账号和/或与Vhot平台之外的严重或多次仇恨言论相关的账号。
        </p>
        <h5>以受保护议题为目标的攻击与诽谤</h5>
        <p>
          本平台将针对以下议题进行攻击、威胁、煽动暴力或以其他方式使个人或群体非人化的内容视为仇恨言论或行为，我们会将这些内容移除：
        </p>
        <ul>
          <li>种族</li>
          <li>族裔</li>
          <li>国籍</li>
          <li>宗教</li>
          <li>种姓</li>
          <li>性取向</li>
          <li>生理性别</li>
          <li>社会性别</li>
          <li>性别认同</li>
          <li>严重疾病</li>
          <li>失能</li>
          <li>移民身份</li>
        </ul>
        <p>
          诽谤是贬损性的用语，意图在贬低上述任何应受保护的特质的群体或个人。为了最有效地降低冒犯性词汇的传播，我们会从平台上移除所有诽谤内容，除非这些术语被重新挪用、用于自我指涉（例如，受保护的群体成员自行使用），或不具贬低意义。（例如，出于教育目的发表的内容）
        </p>
        <p>请勿发布、上传、直播或分享：</p>
        <ul>
          <li>
            宣称个人或具应受保护特质的群体在身体、精神或道德方面不如他人，或将其视为罪犯、动物、无生命的物体或其他非人类体的内容
          </li>
          <li>提倡或合理化针对他们的暴力、排斥、隔离或歧视的内容</li>
          <li>包含非自我指涉诽谤的内容</li>
          <li>
            通过错误的性别分类或弃名错称来针对跨性别者或非二元性别者的内容
          </li>
          <li>描述以受保护特质为攻击目标对个人或群体所造成伤害的内容</li>
        </ul>
        <h5>仇恨意识形态</h5>
        <p>
          仇恨意识形态是因他人具有应受保护的特质而表现出明显的敌意。仇恨意识形态不符合本平台提供的既包容且支持的社群主旨，我们会移除宣传这些意识形态的内容。
        </p>
        <p>请勿发布、上传、直播或分享：</p>
        <ul>
          <li>
            赞美、促进、美化或支持任何仇恨意识形态的内容（如白人至上、厌女、反LGBTQ、排犹主义）
          </li>
          <li>
            含有代表仇恨意识形态名称、符号、徽标、旗帜、标语、制服、手势、敬礼、插图、肖像、歌曲、音乐、歌词或其他对象的内容
          </li>
          <li>倡导、支持或为性倾向矫正疗法及相关计划打广告的内容</li>
          <li>
            内容中否认影响具有应受保护特质的群体而证据充分的暴力事件（纳粹大屠杀否认论）
          </li>
          <li>宣称比拥应受保护特质的群体更加优越</li>
          <li>用来为仇恨意识形态辩护的阴谋论</li>
        </ul>
        <h3>暴力极端主义</h3>
        <p>
          我们在Vhot平台之上与之外都坚决反对使用暴力。我们禁止用户使用本平台来威胁他人或煽动暴力，抑或是宣传暴力极端的个人、组织或行动。若对公共安全构成威胁或使用账号来宣传或美化平台外的暴力，我们会停用该账号，并在必要时向相关执法机构检举。为了有效保护我们的社群，我们可能会参考其他平台上的行为，来辨识平台上的暴力极端主义组织和个人。若我们在Vhot上发现了此类组织或个人，我们将停用其账号。
        </p>
        <h5>威胁和煽动暴力</h5>
        <p>
          我们认为煽动暴力是提倡、指导或鼓励他人实施暴力。我们禁止在平台上使用暴力威胁或煽动暴力，以免造成严重的人身伤害。
        </p>
        <p>请勿发布、上传、直播或分享：</p>
        <ul>
          <li>意图对个人或群体造成身体伤害的言论</li>
          <li>鼓励他人犯下或提倡暴力的言论或图像</li>
          <li>有条件或有意图鼓励他人实施暴力的言论</li>
          <li>意图以暴力恐吓或威胁个人或群体而将武器带到某一地点的呼吁</li>
          <li>煽动暴力的武器制造与使用指南</li>
        </ul>
        <h5>暴力极端组织与个人</h5>
        <p>
          我们禁止在平台上宣传或参与暴力的个人或组织，包括：恐怖组织、组织性仇恨团体、犯罪组织以及其他以平民为目标的非国家武装团体。
        </p>
        <p>恐怖组织</p>
        <p>
          恐怖分子和恐怖组织为非国家行为体，他们为追求政治、宗教、种族或意识形态目标而威胁暴力、使用暴力和或对平民实施严重犯罪（例如反人类罪）。
        </p>
        <p>组织性仇恨</p>
        <p>
          「组织性仇恨」系指个人与组织因应受保护的特质而攻击他人，例如：种族、族裔、国籍、宗教、种姓、性取向、生理性别、社会性别、性别认同或移民身份。我们认为的攻击包括以下的行为：煽动暴力或仇恨、意图使个人或群体非人化，或怀有仇恨的意识形态等。
        </p>
        <p>犯罪组织</p>
        <p>
          犯罪组织是从事严重罪行的跨国、国内或地方团体，其犯罪行为包括暴力犯罪（例如：凶杀、强奸、抢劫、殴打），贩运（例如：人类、器官、毒品、武器），绑架，金融犯罪（
          例如敲诈，勒索，欺诈，洗钱）或网络犯罪等。
        </p>
        <p>请勿发布、上传、直播或分享：</p>
        <ul>
          <li>赞美、促进、美化或支持暴力或极端的个人和／或组织的内容</li>
          <li>鼓励或意图招募个人加入暴力或极端组织的内容</li>
          <li>
            含有代表危险个人和／或组织的名称、符号、徽标、旗帜、标语、制服、手势、敬礼、插图、肖像、歌曲、音乐、歌词或其他对象的内容
          </li>
        </ul>
        <h3>诚信与真实性</h3>
        <p>
          我们相信，信任是本社群的基石。我们禁止进行可能损害平台完整性或用户真实性的活动。我们会移除涉及垃圾邮件或虚假参与、冒名、或造成严重损害的误导性信息
        </p>
        <h5>垃圾邮件和虚假参与</h5>
        <p>
          虚假参与包括任何意图人为冲高平台人气的内容或活动。我们禁止任何试图操纵平台以增加互动指数的行为。
        </p>
        <p>请勿：</p>
        <ul>
          <li>分享如何人为增加点阅率、赞数、追踪者、分享或评论的说明</li>
          <li>参与出售或购买点阅率、赞数、追踪者、分享或评论</li>
          <li>宣传人工流量生产服务</li>
          <li>经营多个假的或诈欺Vhot账号以散布商业垃圾邮件</li>
          <li>
            为了人为增加浏览量、点赞、追踪人数、分享或评论而创造恶意软件或修改程序代码
          </li>
        </ul>
        <h5>冒名</h5>
        <p>
          我们禁止建立账号冒充其他人或实体。假冒检举一经确认，我们可能要求用户修改个人资料，亦可能停用该账号。但若该账号明确其为粉丝、评论或戏仿账号，如在用户名处标明并且与该人物无关，我们便会允许戏仿、评论或粉丝账号的存在。
        </p>
        <p>请勿：</p>
        <p>
          参与假造（例如：使用多个账号），以施加影响并改变公众舆论，同时误导个人、本社群与本系统有关账号的身份、位置或目的
        </p>
        <h5>有害错误信息</h5>
        <p>
          错误信息为不正确或错误的内容。我们会移除任何致使个人、社群或社会大众受到重大伤害的错误信息，无论其意图为何。重大伤害包括严重的身体伤害、疾病或死亡；严重的心理创伤；大规模财产损失以及破坏公众对政府、选举和科学机构等公民机构和程序的信任等。这不包括单纯不正确的信息、神话或商业与声誉损害。
        </p>
        <p>请勿发布、上传、直播或分享：</p>
        <ul>
          <li>煽动仇恨或偏见的错误信息</li>
          <li>与紧急状况有关，将引起恐慌的错误信息</li>
          <li>可能损害个人身体健康的错误医疗信息</li>
          <li>误导社群成员有关选举或其他公民参与程序的内容</li>
          <li>
            攻击特定的受保护群体、或包括呼吁暴力行动、或否认暴力或悲剧事件发生的阴谋论内容
          </li>
          <li>
            透过扭曲事件的真相来误导用户，并且对影片的主角、他人或社会造成伤害的数字伪造（合成媒体或修改媒体）
          </li>
        </ul>
        <p>
          请勿：参与假造（例如：使用多个账号），以施加影响并改变公众舆论，同时误导个人、本社群与本系统有关账号的身份、位置或目的
        </p>
        <h3>非法行为与管制物品</h3>
        <p>
          我们致力确保在Vhot平台上不会发生违反法律或规范的活动。我们禁止交易、销售、宣传与使用某些受管制的商品，也禁止宣传或协助犯罪行为，其中包含人类剥削。若内容涉及在全球或大部分地区受管制或非法的活动或商品，则该内容可能会被移除。
        </p>
        <h5>犯罪行为</h5>
        <p>
          犯罪行为涉及各种可依法惩处的行为，包括：盗窃、殴击、人类剥削、仿冒，以及其他有害行为。为了防止这些行为遭到正常化、模仿或宣传，我们会移除促进或支持犯罪行为的内容
        </p>
        <p>请勿发布、上传、直播或分享：</p>
        <ul>
          <li>宣传人身伤害行为的内容，例如：殴打或绑架</li>
          <li>可能危害他人安全的内容，包括：假警报</li>
          <li>
            宣传人类剥削的内容，包括：人口走私、抵债劳工、家庭奴役、性贩运或卖淫
          </li>
          <li>宣传破坏或损害财产的内容</li>
          <li>宣传盗猎或野生动植物非法交易的内容</li>
          <li>提供购买、出售、交易、教唆非法获取商品或假冒商品的内容</li>
          <li>提供有关如何进行对人、动物或财产造成损害的犯罪活动指引的内容</li>
        </ul>
        <h5>武器</h5>
        <p>
          武器
          我们禁止描述、宣传或交易枪枝、枪枝配件、弹药或爆炸性武器。我们也禁止这些武器的制造指南。我们会允许在教育环境中（如作为博物馆藏品的一部分）、有授权的政府人员参与下（如警察）或在安全且受控的环境中使用（如射击场、休闲狩猎）时对武器进行描述。
        </p>
        <p>请勿发布、上传、直播或分享：</p>
        <ul>
          <li>展示枪枝、枪枝配件、弹药或爆炸性武器的内容</li>
          <li>
            提供购买、出售、交易或教唆使用枪枝、枪枝配件、弹药或爆炸性武器或有关其制造说明的内容
          </li>
        </ul>
        <h5>毒品、管制药品、酒精与烟草</h5>
        <p>
          我们禁止描述、宣传或交易毒品或其他管制药品。平台上也禁止烟草与酒精产品的交易。
        </p>
        <p>请勿发布、上传、直播或分享：</p>
        <ul>
          <li>
            描述或宣传毒品、使用毒品，或鼓励他人制造、使用、交易毒品或其他管制药品的内容
          </li>
          <li>
            提供购买、销售、交易或怂恿使用毒品或其他管制药品、酒精或烟草产品（包括电子雾化器产品、无烟或可燃烟产品、合成尼古丁产品、电子烟以及其他EDNS[电子尼古丁传送系统]）的内容
          </li>
          <li>提供如何购买非法或管制药品的内容</li>
          <li>描述或宣传滥用合法药品或制作自制药品，意图使人药物成瘾的内容</li>
        </ul>
        <h5>欺诈和诈骗</h5>
        <p>
          我们禁止任何人使用本平台并利用用户的信任对他人造成财产或人身伤害。我们会移除欺骗他人以获取非法财产或个人利益的内容，包括欺诈个人或盗窃资产的计划。 
        </p>
        <p>请勿发布、上传、直播或分享：</p>
        <ul>
          <li>宣传网络钓鱼的内容</li>
          <li>宣传庞氏骗局、多层次传销或老鼠会（金字塔式骗局）的内容</li>
          <li>宣传保证高报酬、固定赔率博彩或任何其他类型欺诈投资计划的内容</li>
        </ul>
        <h5>赌博</h5>
        <p>
          我们禁止宣传赌博服务，或得以被视为赌场、体育博彩或任何其他商业赌博活动广告的内容。
        </p>
        <p>
          请勿发布、上传、直播或分享：宣传赌场、体育博彩、扑克、乐透、赌博相关的软件与应用程序，或其他赌博服务的内容
        </p>
        <h5>隐私权、个人资料及个人身份资料（PII）</h5>
        <p>
          我们禁止违反个人资料或个人身份数据（例如身分证信息、电话、实体地址等）机密性的内容。我们将从平台上删除描述个人资料及个人身份资料的内容
        </p>
        <p>
          请勿发布、上传、直播或分享：包含个人资料及个人身份资料（PII）的内容
        </p>
        <h3>暴力与血腥画面</h3>
        <p>
          Vhot是赞扬创造力而非惊吓值或暴力的平台。我们禁止在平台上出现不堪入目、血腥、虐待狂或令人毛骨悚然的内容，或宣传、正常化或美化极端暴力或痛苦的内容。若对公共安全构成威胁，我们会暂停或停用该账号，并在必要时向相关执法机构检举。
        </p>
        <p>请勿发布、上传、直播或分享：</p>
        <ul>
          <li>发布、上传、直播或分享： 描绘以下人类的内容：</li>
          <ul>
            <li>暴力或血腥的死亡或事故</li>
            <li>遭肢解、残缺、烧焦或烧毁的人类遗体</li>
            <li>聚焦于开放性伤口或受伤的血渍</li>
            <li>现实世界的身体暴力与打斗，以及真实和虚构环境中的酷刑</li>
          </ul>
          <li>描绘以下动物的内容：</li>
          <ul>
            <li>屠杀或其他非自然死亡的动物</li>
            <li>遭肢解、残缺、烧焦或烧毁的动物遗体</li>
            <li>虐待动物与其血渍</li>
          </ul>
        </ul>
        <h3>侵害著作权与商标</h3>
        <p>
          著作权是一种法律权利，目的在保护作者的原创作品（例如：音乐、影片）与原创的表达概念（例如：表达或制作影片或音乐的特定方式），但不保护基本思想或事实。商标是标志和区分产品或服务来源的文字、符号、口号或设计。我们鼓励所有人创造与分享原创内容。本平台禁止任何侵犯他人知识产权的内容。一经发现，我们会将其移除。在某些情况下使用受著作权保护的作品，例如合理使用原则或其他适用法律，或引用商标、合法评论、批评、戏仿、制作粉丝页面或评论产品或服务，可能不会被视为违反我们政策的内容。
        </p>
        <p>
          请勿发布、上传、直播或分享：违反或侵犯他人著作权、商标或其他知识产权的内容
        </p>
        <h3>平台安全</h3>
        <p>
          Vhot
          的首要任务是我们的用户、创作者、合作伙伴、供货商和员工数据的安全和保障，包括他们的个人信息、账号、档案、内容和其他私人信息，以及我们的产品和服务。我们不允许通过未经授权的方法访问
          Vhot
          平台（包括我们的网站、应用程序、网络和相关基础设施或网络），抑或是获取敏感的商业或个人机密信息。我们明确禁止任何企图破坏或滥用我们平台、产品或服务的安全性、完整性或可靠性的行为。
        </p>
        <p>请勿：</p>
        <ul>
          <li>
            尝试以未经授权的方式访问 Vhot 平台，不制作虚假或误导性版本的 Vhot
            平台
          </li>
          <li>
            创建或分发包含病毒、特洛伊木马程序、计算机蠕虫、逻辑炸弹或其他可能对社群或平台有害的材料的恶意文件、内容或消息
          </li>
          <li>
            使用自动化脚本、网络爬虫、软件、欺骗技术或任何其他方式尝试从 Vhot
            或其用户处取得、获取或请求登入凭据或其他敏感信息，包括非公开数据
          </li>
          <li>
            以虚假或诈欺手段利用 Vhot
            账号散布垃圾邮件、网络钓鱼或诈骗内容，企图进行网络犯罪或未经授权访问他人的内容、账号、系统或数据
          </li>
          <li>
            修改、改编、翻译、进行还原工程、反汇编、反编译或创建任何基于 Vhot
            的衍生产品，包括任何文件、表格或文文件，或尝试重新生成 Vhot
            中包含的任何源程序、算法、方法或技术
          </li>
          <li>
            向他人提供对您账号凭据的访问权限，或允许他人进行违反我们社群自律公约的操作
          </li>
          <li>
            点击可疑超链接或参与索取有关您的Vhot
            账号详细信息、密码、验证资格、财务或其他个人信息s
          </li>
        </ul>
        <h3>限制为您推荐页面发布资格</h3>
        <p>
          为您推荐(For You
          Feed)页面的目标是为了兑现我们的使命，去激发创造力，以及为全球多元化小区带来欢乐。每个账号使用者的为您推荐页面都是基于个性化的推荐系统。我们的推荐系统在设计时以用户安全为首要考虑，这代表某些内容可能不符合推荐条件。虽然为您推荐的创意是让
          Vhot
          出色的原因，但为您推荐面对的观众包含从我们的青少年用户，到曾祖父母辈的每一个人。我们会考虑到观众的广度去决定哪些内容有资格在平台上，通过算法被推送。在不违反当地法律的情况下，虽然有些内容可能并不符合为您推荐的条件，但我们可能会允许以下类型的内容在我们的平台上出现、被搜索，并可在「追踪」推送里查看。
        </p>
        <h5>未成年人安全</h5>
        <p>
          未成年人的判断力和认同感会随着他们的成长而成熟。为了给青少年在数字空间中进行身份探索和表达提供更多空间，16
          岁以下用户上传的内容并不符合为您推荐的条件。此外，我们努力鼓励各个年龄段的健康发展。为未成年人推荐整容手术可能会导致对自己体相不满的比例提高。因此，此类型的内容（包括手术前后影片、手术影片以及在没有风险警告的情况下讨论非急须整容手术的内容）并不符合所有
          18 岁以下用户为您推荐的资格。
        </p>
        <h5>危险特技与运动</h5>
        <p>
          在没有安全预防措施的情况下重现某些活动可能会带来风险或危险，并可能导致身体伤害。凡包含了危险特技或非专业人士进行的极限运动的内容可能不符合为您推荐页面的资格。（我们会移除描述由非专业人士所进行的危险行为和挑战的内容，这些内容可能会导致用户或公众受重伤或死亡）。 
        </p>
        <h5>明显的色情内容</h5>
        <p>
          我们了解到并非所有内容都适合所有用户（尤其是我们的青少年用户）和/或在某些地区可能会有文化上不合适的问题。为了让所有用户都有舒适且适合年龄的体验，具有明显性暗示的内容可能不符合为您推荐的条件。这包括描述隐含裸体、将身体部位性感化或公然色情或肉欲（例如脱衣舞）的内容。
        </p>
        <h5>烟草与酒精产品</h5>
        <p>
          Vhot
          致力于保护我们平台上未成年人的安全。以危险方式向已达法定饮酒年龄的人宣传、提及或描述酒精产品的相关内容可能不符合推荐条件。描述成人使用烟草制品或提及受管制物质的内容亦不符合推荐条件。此外，
          Vhot禁止暗示、描绘、模仿或宣传未成年人持有或消费酒精饮料、烟草或毒品的内容。我们的社群准则也禁止针对未成年人提供有关如何购买、销售或交易酒精、烟草或受管制物质的说明的内容。
        </p>
        <h5>暴力与图像内容</h5>
        <p>
          我们的社群崇尚创造力，但并不是每个人都喜欢冲击性的价值观。可能导致观众不适、震惊或厌恶的内容可能不符合为您推荐页面的要求，包括恐怖效果、突发惊吓、逼真地复制血腥伤口的化妆或身体机能的描绘。大多数的暴力和图像内容在Vhot上都是被禁止的；
          但在某些情况下，我们可能不会移除某些的此类内容，例如在记录真实事件且符合公共利益时对死亡、事故或战斗的图像描述。由于此内容并不适合所有受众，因此亦不符合为您推荐页面的条件，并且该内容将包含「确认点击」的页面或警告。
        </p>
        <h5>垃圾邮件、不真实或误导性内容</h5>
        <p>
          我们重视真实性和准确性。透过欺骗或操纵用户，从而不当增加平台上的追踪者、点赞、观看次数或其他参与度指标的任何内容均不符合为您推荐页面的资格，包括
          follow-to-follow、like-to-like
          或操纵用户点赞或分享。违背普遍接受的信念并将责任归咎于团体或实体（而不是任何个人）的阴谋内容，或者与当前进行中，且相关细节仍在发展中并存在潜在误导性的有害内容也不符合推荐条件。
        </p>
        <h5>非原创、低质量且含有 QR code 的内容</h5>
        <p>
          原创和娱乐内容是 Vhot 社群的核心构成要素。为了保持用户对 Vhot
          平台的积极体验，非原创和质量低下的内容不符合推荐条件。如果内容是从
          Vhot、其他平台或其他来源（包括电视、电影或网络广播）导入或上传的，并且用户未添加新的、创造性的编辑，我们将其视为非原创内容。内容非原创的一个指标是可见的水印或迭加的标示。质量低下的内容包括极短的剪辑、静态图像和完全基于
          GIF 的影片。包含 QR
          code的内容通常不符合为您推荐页面的条件，因为它可能会将用户引导至有害的网站或应用程序，但在风险较低的某些情况下（例如电子商务），我们会例外处理。
        </p>
        <p>
          感谢您成为我们充满活力的全球社群的一员，并与我们一起为所有用户提供一个温馨的空间。若您发现您认为可能违反我们社群自律公约的内容或账号，请不吝告知，以便我们进行审核并采取适当的措施。
        </p>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-image: linear-gradient(
    to bottom,
    #af7384,
    #a0727f,
    #9e7081,
    #926593,
    #7b5997,
    #6753a0,
    #070606
  );
  padding: 20px 0px;
  min-height: 100vh;
  h3,
  h2,
  h5,
  p,
  i,
  li {
    color: #fff;
  }
  h3 {
    padding: 20px 0px;
  }
  h5 {
    padding: 15px 0px;
  }
  p {
    padding: 10px 0px;
  }
`;
