import React from "react";
import Layout from "../../components/Layout";
import styled from "styled-components";
import VideosTemplate from "../../components/VideosTemplate";
import { useParams } from "react-router-dom";

export default function Tags() {
  const prams: any = useParams();
  const tag: string = prams.tag;

  return (
    <Layout>
      <Title className="m-4"># {tag}</Title>
      <VideosTemplate
        payload={{
          tag: tag,
        }}
      />
    </Layout>
  );
}

const Title = styled.h3`
  font-weight: 400;
  text-align: center;
  color: #fff;
  padding: 20px 0px;
`;
