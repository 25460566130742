import React, { useState } from "react";
import styled from "styled-components";
import Layout from "../../../components/Layout";
import request from "../../../utils/request";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";

export default function Signup() {
  const [isLoading, setIsloading] = useState(false);
  const [email, setEmail] = useState("test3@vhot.live");
  const [password, setPassword] = useState("");

  const validateEmptyString = () => {
    if (email === "" || password === "") {
      toast.error("Your email or password can not be empty.");
      return false;
    } else {
      return true;
    }
  };

  const handleSignIn = () => {
    request
      .post("login", {
        username: email,
        password: password,
      })
      .then((res) => {
        console.log(res);
        setIsloading(false);
        if (res.data.code === 400) {
          toast.error("Username or password is not correct!");
        }
        if (res.data.code === 200) {
          localStorage.setItem("token", res.data.data.token);
          window.location.reload();
        }
      })
      .catch((err) => {
        setIsloading(false);
        console.log(err);
      });
  };

  return (
    <Layout>
      <Wrapper>
        <ContentWrapper>
          <Title>登录</Title>
          <Bar>
            <Text>邮箱/手机号:</Text>
            <Input
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Bar>
          <Bar>
            <Text>密码:</Text>
            <Input
              style={{ letterSpacing: "2px" }}
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Bar>
          <p className="text-light my-3 text-center">
            还没有 Vhot 账号? <br /> 赶快
            <a href="/signup">
              <span style={{ color: "#df4156" }}> 注册 </span>
            </a>
            一个吧
          </p>
          {isLoading ? (
            <ReactLoading
              type="bubbles"
              color="#23ed98"
              height={"100%"}
              width={80}
            />
          ) : (
            <div className="text-center">
              <button
                className="mybtn my-3"
                onClick={() => validateEmptyString() && handleSignIn()}
              >
                登录
              </button>
            </div>
          )}
        </ContentWrapper>
      </Wrapper>
    </Layout>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 85vh;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  background-color: #bfb9b971;
  padding: 60px;
  border-radius: 5px;
`;

const Title = styled.h1`
  color: #fff;
  text-align: center;
  padding-bottom: 30px;
`;

const Bar = styled.div`
  margin: 15px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const Text = styled.p`
  color: #fff;
`;

const Input = styled.input`
  width: 300px;
  margin-top: 10px;
  border-radius: 10px;
  padding: 5px 10px;
  outline: 0px;
  border-width: 0px;
`;
