import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import userSlice from "./userSlice";
// import globalSlice from "./globalSlice";

export default configureStore({
  reducer: {
    // global: globalSlice,
    user: userSlice,
  },
  middleware: [thunk],
});
