import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Avatar from "./Avatar";

export default function MobileNav() {
  return (
    <MobileNavBar>
      <Link to="/">
        <img
          width="50px"
          className="mx-1"
          src={`/images/vhot-avatar.png`}
          alt="vhot logo"
        />
      </Link>
      <Link to="/">
        <p>主页</p>
      </Link>
      <Link to="/main">
        <p>推荐</p>
      </Link>
      <Link to="/followingvideos">
        <p>关注</p>
      </Link>
      <Link to="/discover">
        <p>分类</p>
      </Link>
      <a href="https://vhot-android-apk.s3.us-west-2.amazonaws.com/app-debug-v005.apk">
        <p>安卓App</p>
      </a>
      <div className="d-flex flex-row justify-content-center align-items-center">
        {localStorage.getItem("token") === null ? (
          <Link to="/signin">
            <button className="mybtn mx-2">登录</button>
          </Link>
        ) : (
          <Avatar />
        )}
      </div>
    </MobileNavBar>
  );
}

const MobileNavBar = styled.div`
  display: none;
  @media only screen and (max-width: 768px) {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    p {
      color: #fff;
    }
  }
`;
