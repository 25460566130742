import { useParams } from "react-router-dom";

import Layout from "../../components/Layout";
import VideosTemplate from "../../components/VideosTemplate";

export default function Category() {
  const prams: any = useParams();
  const [id, name] = prams.data.split("@");
  return (
    <Layout>
      <div className="d-flex flex-row justify-content-center align-items-center">
        <h3 className="my-title">{name}</h3>
      </div>
      <VideosTemplate payload={{ category_id: id }} />
    </Layout>
  );
}
