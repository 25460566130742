import axios from "axios";
// import { useSelector } from "react-redux";
// import { useDispatch } from "react-redux";
// import { SET_LOADING } from "../store/globalSlice";

const instance = axios.create({
  baseURL: "https://test-api-vhot.richlink.io/api/v1/",
  // timeout: 8000,
  headers: {
    Authorization: localStorage.getItem("token"),
    "Access-Control-Allow-Origin": "*",
  },
});
instance.interceptors.request.use(
  function (config) {
    // const dispatch = useDispatch();
    // dispatch(SET_LOADING(true));
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    if (response.data.code === 400 || response.data.code === 500) {
      localStorage.removeItem("token");
    }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;
