import React from "react";
import Layout from "../../components/Layout";
import VideosTemplate from "../../components/VideosTemplate";

export default function Category() {
  return (
    <Layout>
      <div className="text-center">
        <h3 className="my-title m-auto d-inline-block">我喜欢的视频</h3>
      </div>
      <VideosTemplate payload={{ url: "like/video", type: 1 }} />
    </Layout>
  );
}
