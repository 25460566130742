import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import "./styles/index.scss";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "reactjs-popup/dist/index.css";

import Routing from "./utils/routing";
import store from "./store/_store";

import { ToastContainer } from "react-toastify";

ReactDOM.render(
  <Provider store={store}>
    <ToastContainer
      theme="dark"
      position="top-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
    <Routing />
  </Provider>,
  document.getElementById("root")
);
