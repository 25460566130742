import React from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

export default function VideoCard(props: any) {
  const {
    id,
    thumb,
    viewNum,
    likeNum,
    title,
    avatar,
    nickname,
    mtime,
    userId,
  } = props;

  const navigate = useNavigate();

  const processingTitle = (title: string) => {
    if (title.length > 10) {
      return title.slice(0, 10) + "...";
    } else {
      return title;
    }
  };

  return (
    <div className="m-4 pb-3">
      <CoverImageWrapper onClick={() => navigate(`/videoplayer/${id}`)}>
        <ViewNumWrapper>
          <div className="d-flex flex-row justify-content-center align-items-center">
            <img
              width={20}
              src="https://media0.giphy.com/media/mCs8XcI1cVvhUZemSZ/giphy.webp?cid=ecf05e475t9eue54o745or0w2s7sjg6s8dj3z5h5w5aj4muz&rid=giphy.webp&ct=s"
              alt="view"
            />
            <p className="text-light mx-2">{viewNum}</p>
          </div>
        </ViewNumWrapper>
        <LikeNumWrapper>
          <div className="d-flex flex-row justify-content-center align-items-center">
            <img width={20} src="/images/nav-icons/heart.png" alt="like" />
            <p className="text-light mx-2">{likeNum}</p>
          </div>
        </LikeNumWrapper>
        <img src={thumb} width="100%" height="100%" alt="coverImage" />
      </CoverImageWrapper>
      <VideoTitle>{processingTitle(title)}</VideoTitle>
      <InfoWrapper>
        <UserWrapper onClick={() => navigate(`/uploader/${userId}`)}>
          <img src={avatar} alt="user-name" />
          <p className="mx-2">{nickname}</p>
        </UserWrapper>
        <p>{mtime}</p>
      </InfoWrapper>
    </div>
  );
}

const CoverImageWrapper = styled.div`
  width: 180px;
  height: 300px;
  border-radius: 10px;
  img {
    border-radius: 10px;
  }
  transition: 0.4s;
  position: relative;
  :hover {
    cursor: pointer;
    opacity: 0.4;
  }
  @media only screen and (max-width: 768px) {
    width: 120px;
    height: 200px;
    margin: 20px;
  }
`;

const ViewNumWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 10px;
  margin: auto;
`;

const LikeNumWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  margin: auto;
`;

const VideoTitle = styled.div`
  background-color: #bfb9b971;
  padding: 5px;
  color: #fff;
  margin: 20px 0px;
  text-align: center;
  font-size: 14px;
  width: 180px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  p {
    color: #ffffffb1;
  }
`;

const UserWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;
