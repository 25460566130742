import React from "react";
import styled from "styled-components";

export default function Index() {
  return (
    <Wrapper>
      <div className="container">
        <h1>Vhot Privacy Policy, Android</h1>
        <p>Last version date：May 2023</p>

        <p>
          Welcome to our App (Vhot). We are committed to protecting the privacy
          of our users and their personal information. This privacy policy (the
          "Policy") explains how we collect, use, and disclose information about
          our users and their activities on the App.
        </p>

        <h3>Information We Collect:</h3>

        <p>
          Account information:When you create a Vhot account, you will register
          with a phone number or email address. Phone number or email address is
          used to provide our Services to you and other Vhot users. You may
          optionally add other information to your account, such as a profile
          name and profile picture. We may collect those personal information to
          our server,such as profile name and profile picture,these information
          are encrypted end-to-end.
        </p>

        <h3>Browsing History:</h3>
        <p>
          We may collect information about the pages, posts, and other content
          that you view on the App.
        </p>

        <h3>Use of Information:</h3>

        <p>
          Personalizing your experience: We use your information to provide you
          with a personalized experience on the App, Display the profile name
          and profile picture and other information to others.such as suggesting
          content or features that may be of interest to you.
        </p>

        <p>
          You can manage your personal information in Vhot’s application
          Settings. For example, you can update your profile information .
        </p>

        <h3>Targeted Advertising</h3>
        <p>
          We may use your information to serve you targeted ads on the App and
          on other websites and apps.
        </p>

        <h3>Improving the App</h3>
        <p>
          We may use your information to improve the App and develop new
          features.
        </p>

        <p>
          The types of personal information collected by the functions of the
          products or services we offer, respectively, and the rules for
          collecting and using personal information (e.g. the purpose and manner
          of collecting and using personal information) are as follows:
        </p>

        <p>
          1, when you sign up for the Vhot service, we will collect your phone
          number or email address for creating an account and using social
          features. You can choose to add other information to your account,
          such as profile name and profile picture, the name and picture you
          add, will be stored in our server, because the storage is necessary to
          achieve this function, we will store in an encrypted way, you can also
          remove this information at any time. We will only use your relevant
          information within this application. During the registration and use
          of the service, if you provide the following information to complete
          your profile: age, city, occupation, school, it will help us to
          provide you with a better service and experience, and we can provide
          you with personalized services related to the above information.
          However, if you do not provide this information, only will prevent you
          from using or participating in the personalized services associated
          with this, but will not affect the use of Vhot products or services of
          the basic functions. You provide the above information will continue
          to authorize us to use during your use of the Service. When you
          voluntarily delete your account, we will delete your personal
          information in accordance with the applicable laws and regulations.
        </p>

        <p>
          2,When you use my videos and other information posting functions to
          post information publicly, including posting
          pictures/videos/publishing comments, you need to open photo album
          permission, camera permission, microphone permissionto take photos or
          videos. Please note that the information you post publicly may contain
          personal information or even sensitive personal information about you
          or others, so please consider more carefully if you choose to upload
          images containing personal information when posting your news. The
          dynamic photos, videos, comments, likes, etc. that you upload are
          stored on our servers, as storage is necessary to enable this
          functionality. We store them in an encrypted manner, and you can
          delete them at any time. We will not make such dynamic information
          available to the public or use it for purposes other than this
          feature, unless you choose to do so or comply with relevant laws and
          regulations.
        </p>

        <h3>Sharing of Information</h3>
        <p>
          Third-party service providers: We may share your information with
          third-party service providers who assist us in operating the App, such
          as hosting providers and analytics companies. Compliance with laws: We
          may disclose your information to government or law enforcement
          agencies if required by law or if we believe it is necessary to
          protect the safety of our users or the general public.
        </p>

        <h3>Data Retention</h3>
        <p>
          We retain your information for as long as necessary to provide the
          App's services and to comply with legal and regulatory requirements.
        </p>

        <h3>Your Rights</h3>
        <p>
          Access and control of your information: You have the right to access
          and control your personal information on the App, including the right
          to update, correct, or delete your account. Opt-out of targeted
          advertising: You may opt-out of receiving targeted ads on the App at
          any time.
        </p>

        <h3>Changes to this Policy</h3>
        <p>
          We may update this Policy from time to time. We will notify you of any
          changes by posting the new Policy on the App and updating the
          "effective date" at the top of this page.
        </p>

        <h3>Contact Us</h3>
        <p>
          If you have any questions about this Policy or the App's privacy
          practices, please contact us at info@yexc.ca
        </p>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-image: linear-gradient(
    to bottom,
    #af7384,
    #a0727f,
    #9e7081,
    #926593,
    #7b5997,
    #6753a0,
    #070606
  );
  background-attachment: fixed;
  width: 100%;
  min-height: 100vh;
  padding: 30px;

  h1 {
    font-size: 26px;
    color: #fff;
    padding-bottom: 20px;
  }

  h3 {
    font-size: 20px;
    color: #fff;
    padding: 16px 0px;
  }

  p {
    color: #fff;
    padding: 10px 0px;
  }
`;
