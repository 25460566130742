import styled from "styled-components";
import Layout from "../../components/Layout";

export default function Signup() {
  return (
    <Layout>
      <div className="container">
        <Title>通知信息</Title>
        <Message>欢迎来到 V HOT !</Message>
      </div>
    </Layout>
  );
}

const Title = styled.h3`
  font-weight: 400;
  text-align: center;
  color: #fff;
  padding: 20px 0px;
`;

// const Hr = styled.hr`
//   border: 1px solid #fff;
// `;

const Message = styled.p`
  text-align: center;
  color: #fff;
  padding: 20px;
`;
