import React, { useState, useEffect } from "react";

import styled from "styled-components";

import Layout from "../../components/Layout";
import request from "../../utils/request";

export default function Settings() {
  const [data, setData] = useState<any>(undefined);

  function getUserData() {
    request
      .post("user")
      .then((res) => {
        console.log(res);
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Layout>
      <div className="d-flex flex-row justify-content-center align-items-center">
        <h3 className="my-title">Settings</h3>
      </div>
      <div className="my-3" style={{ padding: "40px 20%" }}>
        <div className="row">
          <div className="col-12 col-md-6">
            <Text>Vhot名: {data?.nickname}</Text>
            <Text>ID: {data?.id}</Text>
            <Text>性别: {data?.sex}</Text>
            <Text>邮箱: {data?.email}</Text>
            <Text>推荐码: {data?.refcode}</Text>
          </div>
          <div className="col-12 col-md-6">
            <Text>余额: {data?.amount}</Text>
            <Text>授权: {data?.is_auth}</Text>
            <Text>最后一次登录时间: {data?.last_login_time}</Text>
            <Text>更新于: {data?.updated_at}</Text>
            <Text>创造于: {data?.created_at}</Text>
          </div>
        </div>
      </div>
    </Layout>
  );
}

const Text = styled.p`
  color: #fff;
  padding: 20px;
`;

// const EditWrapper = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: flex-start;
//   align-items: center;
// `;

// const EditBtn = styled.button`
//   border: 0px;
//   margin: 0px 30px;
//   padding: 2px 10px;
//   border-radius: 2px;
// `;
