import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import styled from "styled-components";
import request from "../../utils/request";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import axios from "axios";

export default function Chat() {
  const prams: any = useParams();
  const uid: number = parseInt(prams.uid);

  const [isLoading, setLoadingStatus] = useState<boolean>(false);
  const [currentFileNum, setCurrentFileNum] = useState<number>(0);
  const [totalFileNum, setTotalFileNum] = useState<number>(0);
  const [loadingPercentage, setLoadingPercentage] = useState<number>(0);

  const [userInfo, setUserInfo] = useState<any>();
  const [text, setText] = useState<string>("");
  const [chatHistory, setChatHistory] = useState<Array<string>>([]);
  const [filePaths, setFilePaths] = useState<Array<string>>([]);

  const getUserInfo = async (id: number) => {
    const res: any = await request.post("user/get", {
      user_id: id,
    });
    if (res.data.code !== 200) {
      toast.error("抱歉 未找到此用户！");
    } else {
      setUserInfo(res.data.data);
    }
  };

  const handleMessageSent = () => {
    setChatHistory([...chatHistory, text]);
    setText("");
  };

  const uploadFiles = async (e: any) => {
    setLoadingStatus(true);
    const fileList = e.target.files;
    setTotalFileNum(fileList.length);
    const tempFilePaths = [];
    for (let i = 0; i < fileList.length; i++) {
      const formData = new FormData();
      formData.append("photo", fileList[i]);
      const res = await axios.post(
        "http://3.144.241.138:8088/bucket/upload?httpkey=defaulthttpkey",
        formData,
        {
          onUploadProgress: (progressEvent: any) => {
            let current: number = progressEvent.loaded;
            let total: number = progressEvent.total;
            let percent: number = Math.floor((current / total) * 100);
            setLoadingPercentage(percent);
          },
        }
      );
      tempFilePaths.push(res.data.filepath);
      await setCurrentFileNum(i + 1);
    }
    setFilePaths(tempFilePaths);
    setLoadingStatus(false);
    setCurrentFileNum(0);
  };

  useEffect(() => {
    getUserInfo(uid);
  }, [uid]);

  return (
    <>
      <Layout>
        <Wrapper className="shadow-lg">
          {userInfo ? (
            <div className="d-flex flex-row justify-content-center align-items-center">
              <Link to={`/uploader/${userInfo?.id}`}>
                <img
                  style={{ borderRadius: "50%" }}
                  width={60}
                  height={60}
                  src={userInfo?.avatar}
                  alt="avatar"
                />
              </Link>
              <h4 className="text-center text-dark p-3">
                {userInfo?.nickname}
              </h4>
            </div>
          ) : (
            <div>
              <h4>🚫 此用户不存在</h4>
            </div>
          )}
          <MessageArea className="shadow-lg">
            {chatHistory.map((text: string, index) => (
              <Text key={index}>{text}</Text>
            ))}
            {isLoading ? (
              <div className="text-center">
                <img
                  width={300}
                  src="https://cdn.dribbble.com/users/26878/screenshots/3544693/07-loader.gif"
                  alt="loading"
                />
                <p className="my-2">{loadingPercentage} / 100 %</p>
                <div className="d-flex justify-content-center">
                  <div className="d-flex flex-row">
                    <p className="text-dark">{currentFileNum}</p>
                    <p className="mx-2">/</p>
                    <p className="text-dark">{totalFileNum}</p>
                    <p className="mx-3">Done</p>
                  </div>
                </div>
              </div>
            ) : (
              filePaths.map((fileUrl: string) => (
                <div key={fileUrl} className="m-4">
                  <a href={fileUrl}>{fileUrl}</a>
                </div>
              ))
            )}
          </MessageArea>
          <div className="d-flex flex-row justify-content-around align-items-center">
            <InputBar
              className="shadow-lg"
              onChange={(e: any) => {
                setText(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleMessageSent();
                }
              }}
            />
            <button className="mybtn mx-4" onClick={handleMessageSent}>
              发送
            </button>

            <div>
              <label htmlFor="fileUpload">
                <img
                  width={40}
                  className="cursor-pointer"
                  src="/images/folder.png"
                  alt="folder-icon"
                />
              </label>
              <input
                type="file"
                id="fileUpload"
                name="avatar"
                multiple
                onChange={(e) => {
                  uploadFiles(e);
                }}
              />
            </div>
          </div>
        </Wrapper>
      </Layout>
    </>
  );
}

const Wrapper = styled.div`
  width: 80%;
  margin: 40px auto;
  height: 80vh;
  padding: 20px 60px;
  border-radius: 10px;
  border: 1px solid grey;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const MessageArea = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  height: 400px;
  overflow-y: scroll;
`;

const Text = styled.p`
  font-size: 20px;
`;

const InputBar = styled.input`
  border-width: 0px;
  width: 500px;
  padding: 10px;
  outline: 0px;
  border-radius: 10px;
  font-size: 16px;
`;
