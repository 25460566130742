import request from "../../../utils/request";
import { toast } from "react-toastify";

export const handleLogin = (email: string, password: string) => {
  request
    .post("login", {
      username: email,
      password: password,
    })
    .then((res) => {
      if (res.data.code === 400) {
        toast.error("Username or password is not correct!");
      }
      if (res.data.code === 200) {
        localStorage.setItem("token", res.data.data.token);
        window.location.reload();
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
