import React, { useState } from "react";
import Layout from "../../components/Layout";
import styled from "styled-components";
import { useSelector } from "react-redux";

export default function Chat() {
  const hosts = useSelector((state: any) => state.user.hosts);
  const [selectedUser, setSelectedUser] = useState<any>();

  return (
    <>
      <Layout>
        <Wrapper>
          <UserListsWrapper>
            {hosts.map((el: any) => (
              <div key={el.id}>
                <UserWrapper onClick={() => setSelectedUser(el)}>
                  <p>{el.nickname}</p>
                  <UserAvatar src={el.avatar} alt="avatar" />
                </UserWrapper>
              </div>
            ))}
          </UserListsWrapper>
          <ChatWrapper>
            <MessageArea>
              {selectedUser ? (
                <div className="d-flex m-3">
                  <div className="m-auto">
                    <div className="d-flex flex-row justicy-content-center align-items-center">
                      <UserAvatar src={selectedUser.avatar} alt="avatar" />
                      <UserName>{selectedUser.nickname}</UserName>
                    </div>
                  </div>
                </div>
              ) : (
                <h4 className="text-center text-light p-5">
                  选择一位用户开始聊天
                </h4>
              )}
            </MessageArea>
            <TypingArea>
              <div className="m-auto">
                <InputBar className="shadow-lg" />
                <button className="mybtn mx-3">发送</button>
              </div>
            </TypingArea>
          </ChatWrapper>
        </Wrapper>
      </Layout>
    </>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
`;

const UserListsWrapper = styled.div`
  width: 30%;
  height: 600px;
  overflow-y: scroll;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  background-color: #444444;
`;

const ChatWrapper = styled.div`
  width: 70%;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  background-color: #444444;
`;

const UserWrapper = styled.div`
  border-radius: 5px;
  padding: 10px 20px;
  background-color: #fff;
  margin: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: 0.2s;
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

const UserAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const UserName = styled.p`
  color: #fff;
  font-size: 18px;
  padding: 0px 20px;
`;

const MessageArea = styled.div`
  width: 100%;
  height: 500px;
  overflow-y: scroll;
  /* background-color: #fff; */
`;

const TypingArea = styled.div`
  width: 100%;
  height: 15%;
  border-top: 1px solid grey;
  display: flex;
`;

const InputBar = styled.input`
  border-width: 0px;
  width: 600px;
  padding: 10px;
  outline: 0px;
  border-radius: 10px;
  font-size: 16px;
`;
