/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import request from "../../utils/request";
import styled from "styled-components";
import ReactPlayer from "react-player";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

export default function VideoPlayer() {
  const navigate = useNavigate();
  const prams: any = useParams();
  const id: number = parseInt(prams.id);
  const [data, setData] = useState<any>({});
  const [comments, setComments] = useState<any>([]);
  const [comment, setComment] = useState<string>("");

  const getVideoDataByID = () => {
    request
      .post("video/view", {
        vid: id,
      })
      .then((res) => {
        if (res.data.data === null) {
          toast.error("该视频不存在或者已经被作者删除!");
          navigate("/");
        } else {
          setData(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getComments = () => {
    request
      .post("comment/list", {
        type: 1,
        vid: id,
      })
      .then((res) => {
        setComments(res.data.data.list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkIsLogin = () => {
    if (localStorage.getItem("token") === null) {
      toast("请先登录账号");
      navigate("/signin");
      return false;
    } else {
      return true;
    }
  };

  const handleFollow = (userID: number) => {
    request
      .post("follow/on", {
        follow_id: userID,
      })
      .then((res) => {
        if (res.data.code === 200) {
          setData({ ...data, is_follow: 1 });
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleUnFollow = (userID: number) => {
    request
      .post("follow/off", {
        follow_id: userID,
      })
      .then((res) => {
        if (res.data.code === 200) {
          setData({ ...data, is_follow: 0 });
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleLike = () => {
    if (data.is_like === 0) {
      request.post("like/on", {
        vid: data.id,
      });
      setData({ ...data, is_like: 1, like_num: data.like_num + 1 });
    } else {
      request.post("like/off", {
        vid: data.id,
      });
      setData({ ...data, is_like: 0, like_num: data.like_num - 1 });
    }
  };

  const handleCommentAdd = async () => {
    const str = comment;
    setComment("");
    await request.post("comment/add", {
      type: 1,
      vid: id,
      content: str,
    });
    getComments();
  };

  const handleCommentLike = async (id: number, str: string) => {
    await request
      .post(`comment/${str}Like`, {
        comment_id: id,
      })
      .then((res) => {
        if (res.data.code === 200) {
          getComments();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getVideoDataByID();
    getComments();
  }, [id]);

  return (
    <>
      <Wrapper>
        <LeftWrapper>
          <CloseImage
            onClick={() => {
              navigate("/");
            }}
            src="https://www.freeiconspng.com/thumbs/close-button-png/silver-close-button-png-15.png"
            alt="close img"
          />
          <ReactPlayer
            style={{ margin: "auto" }}
            playing={true}
            controls={true}
            loop={true}
            url={data.video_url}
            width="80%"
            height="100%"
          />
        </LeftWrapper>
        <RightWrapper>
          <UserAreaWrapper>
            <div className="p-2 d-flex flex-row justify-content-between align-items-center">
              <Link to={`/uploader/${data.user_id}`}>
                <UserWrapper>
                  <UserAvatar src={data.avatar} alt="avatar" />
                  <Text className="mx-3">{data.username}</Text>
                </UserWrapper>
              </Link>
              {data.is_follow === 0 ? (
                <button
                  className="mybtn"
                  onClick={() => checkIsLogin() && handleFollow(data.user_id)}
                >
                  关注
                </button>
              ) : (
                <button
                  className="mybtn-grey bg-warning"
                  onClick={() => checkIsLogin() && handleUnFollow(data.user_id)}
                >
                  取消关注
                </button>
              )}
            </div>
            <div className="py-3 d-flex flex-row justify-content-center align-items-center flex-wrap">
              <Text className="mx-3">
                <Icon>💻</Icon> 标题: {data.title}
              </Text>
              <Text className="mx-3">
                <Icon>⏰</Icon> 时间: {data.created_at}
              </Text>
              <Link to={`/category/${data.category_id}@${data.category_name}`}>
                <Text className="mx-3">
                  <Icon>📦</Icon> 分类: {data.category_name}
                </Text>
              </Link>
              <Text className="mx-3">
                <Icon>👀</Icon> 观看次数: {data.view_num}
              </Text>
            </div>
            <div className="d-flex flex-row justify-content-center align-items-center">
              {data.is_like === 0 ? (
                <button
                  onClick={() => checkIsLogin() && handleLike()}
                  className="mybtn"
                >
                  点赞收藏
                </button>
              ) : (
                <button
                  onClick={() => checkIsLogin() && handleLike()}
                  className="mybtn-grey bg-warning"
                >
                  取消点赞
                </button>
              )}
              <p className="mx-4">{data.like_num}</p>
            </div>
          </UserAreaWrapper>
          <CommentAreaWrapper className="comments-scrollbar">
            {comments.length === 0 ? (
              <p className="text-dark text-center">
                暂时没有人评论，留下你的第一个评论吧。
              </p>
            ) : (
              comments.map((el: any, index: number) => (
                <div key={index}>
                  <div className="d-flex flex-row justify-content-between align-items-center py-2">
                    <Link to={`/uploader/${el.user_id}`}>
                      <UserWrapper>
                        <CommentAvatar src={el.avatar} alt="avatar" />
                        <p className="mx-3">{el.nickname}</p>
                      </UserWrapper>
                      <p className="px-5">{el.content}</p>
                    </Link>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      {el.is_like === 0 ? (
                        <LikeWrapper>
                          <img
                            onClick={() => handleCommentLike(el.id, "on")}
                            className="m-2"
                            width={30}
                            src="/images/video-functions/like2.png"
                            alt="like"
                          />
                        </LikeWrapper>
                      ) : (
                        <LikeWrapper>
                          <img
                            onClick={() => handleCommentLike(el.id, "off")}
                            className="m-2 animate__animated animate__bounceIn"
                            width={30}
                            src="/images/video-functions/like1.png"
                            alt="like"
                          />
                        </LikeWrapper>
                      )}

                      <p>{el.like_num}</p>
                    </div>
                  </div>
                </div>
              ))
            )}
          </CommentAreaWrapper>
          <PostAreaWrapper>
            <PostInput
              placeholder="请输入评论..."
              value={comment}
              className="shadow"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleCommentAdd();
                }
              }}
              onChange={(e: any) => {
                setComment(e.target.value);
              }}
            />
            <PostButtonWrapper>
              <PostBtn onClick={handleCommentAdd}>评论</PostBtn>
            </PostButtonWrapper>
          </PostAreaWrapper>
        </RightWrapper>
      </Wrapper>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <H5Wrapper>
          <ReactPlayer
            playing={false}
            controls={true}
            loop={false}
            url={data.video_url}
            width="100%"
            height="100%"
          />
        </H5Wrapper>
      </div>
    </>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: black;
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const H5Wrapper = styled.div`
  display: none;
  @media only screen and (max-width: 768px) {
    display: initial;
    background-color: black;
    width: 100%;
    height: 100vh;
  }
`;

const CloseImage = styled.img`
  position: absolute;
  top: 20px;
  left: 40px;
  background-color: #7e7e7e;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: 0.7s;
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

const LeftWrapper = styled.div`
  padding: 20px;
  margin: auto;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: black;
`;

const RightWrapper = styled.div`
  width: 600px;
  height: 100%;
  padding: 30px;
  background-color: #eeecec;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const UserAreaWrapper = styled.div`
  width: 100%;
`;

const UserWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  transition: 0.4s;
  :hover {
    opacity: 0.5;
    cursor: pointer;
  }
`;

const PostBtn = styled.p`
  transition: 0.4s;
  :hover {
    cursor: pointer;
    color: #e61659;
  }
`;

const CommentAreaWrapper = styled.div`
  padding: 20px;
  width: 100%;
  overflow-y: scroll;
  p {
    color: black;
  }
`;

const PostAreaWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const UserAvatar = styled.img`
  border-radius: 50%;
  width: 50px;
  height: 50px;
`;

const Text = styled.p`
  margin: 30px;
  padding: 10px 0px;
  color: black;
`;

const Icon = styled.span`
  margin-right: 5px;
  font-size: 100%;
`;

const PostInput = styled.input`
  margin: 0px 10px;
  width: 400px;
  padding: 10px 20px;
  border-radius: 10px;
  outline: none;
  border: 0px;
`;

const PostButtonWrapper = styled.div`
  width: 20%;
  text-align: center;
`;

const CommentAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const LikeWrapper = styled.div`
  :hover {
    cursor: pointer;
  }
`;
