import React, { useState, useEffect } from "react";

import styled from "styled-components";
import { toast } from "react-toastify";
import { Upload } from "antd";
import ImgCrop from "antd-img-crop";
import ReactLoading from "react-loading";

import Layout from "../../components/Layout";
import request from "../../utils/request";
import VideosTemplate from "../../components/VideosTemplate";

export default function Profile() {
  const [data, setData] = useState<any>(undefined);
  const [tabUrl, setTabUrl] = useState<string>("video/me");

  function getUserData() {
    request
      .post("user")
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleUpdateAvatar(file: File) {
    console.log(file);
    const FormData = require("form-data");
    const formData = new FormData();
    formData.append("file", file);
    request
      .post("user/uploadAvatar", formData)
      .then((res) => {
        if (res.data.code === 200) {
          getUserData();
          toast("上传头像成功");
        } else {
          toast.error("上传头像失败");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const isNavActive = (url: string) => {
    return url === tabUrl
      ? {
          borderBottom: "1px solid #fff",
          color: "#fff",
        }
      : {
          borderBottom: "1px solid #bfb9b933",
          color: "#bfb9b933",
        };
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Layout>
      {!data ? (
        <div className="d-flex justify-content-center">
          <ReactLoading
            type="bubbles"
            color="#23ed98"
            height={"100%"}
            width={80}
          />
        </div>
      ) : (
        <div className="p-3">
          <div className="container">
            <UserInfo>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <Avatar
                  src={
                    data?.avatar === "" ? "./images/demo.jpeg" : data?.avatar
                  }
                  alt="avatar"
                />
                <div style={{ marginTop: "-10px" }}>
                  <ImgCrop rotate>
                    <Upload
                      accept="image/png, image/gif, image/jpeg"
                      showUploadList={false}
                      beforeUpload={(file: File) => {
                        handleUpdateAvatar(file);
                        return false;
                      }}
                    >
                      <img width={30} src="/images/pencil.png" alt="avatar" />
                    </Upload>
                  </ImgCrop>
                </div>
              </div>
              <div className="d-flex flex-column">
                <div className="mx-5 d-flex flex-row">
                  <Title1 className="m-3">{data?.nickname}</Title1>
                  <Title1 className="m-3">{data?.email}</Title1>
                </div>
                <div className="mx-5 d-flex flex-row">
                  <Title2 className="m-3">
                    <HighLighted>{data?.follow_num}</HighLighted> 关注
                  </Title2>
                  <Title2 className="m-3">
                    <HighLighted> {data?.my_follow_num}</HighLighted>
                    粉丝
                  </Title2>
                  <Title2 className="m-3">
                    <HighLighted> {data?.like_num}</HighLighted>
                    喜欢
                  </Title2>
                </div>
              </div>
            </UserInfo>
            <div className="text-center mt-3">
              <NavBar>
                <p
                  style={isNavActive("video/me")}
                  onClick={() => setTabUrl("video/me")}
                >
                  作品
                </p>
                <p
                  style={isNavActive("like/video")}
                  onClick={() => setTabUrl("like/video")}
                >
                  喜欢
                </p>
              </NavBar>
            </div>
            <div>
              <VideosTemplate payload={{ url: tabUrl }} />
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}

const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Avatar = styled.img`
  border-radius: 50%;
  width: 100px;
`;

const Title1 = styled.h4`
  color: #fff;
`;

const Title2 = styled.p`
  color: #fff;
`;

const HighLighted = styled.span`
  font-weight: 700;
  margin-right: 10px;
`;

const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  p {
    color: #fff;
    font-size: 18px;
    padding: 10px 60px;
    :hover {
      cursor: pointer;
    }
  }
`;
