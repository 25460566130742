import React from "react";
import Layout from "../../components/Layout";
import VideosTemplate from "../../components/VideosTemplate";

export default function Category() {
  return (
    <Layout>
      <h3 className="text-light my-3 text-center">我发布的视频</h3>
      <VideosTemplate payload={{ url: "video/me" }} />
    </Layout>
  );
}
