import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

import styled from "styled-components";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";

import request from "../utils/request.js";
import { SET_AVATAR } from "../store/userSlice";

export default function Avatar() {
  const avatar = useSelector((state: any) => state.user.avatar);
  const navigate = useNavigate();
  const dispath = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handelSignOut = () => {
    navigate("/");
    localStorage.removeItem("token");
    window.location.reload();
  };

  const getAvatar = () => {
    let token = localStorage.getItem("token");
    if (token !== null && token !== undefined) {
      if (avatar === "") {
        request.post("user").then((res) => {
          dispath(SET_AVATAR(res.data.data.avatar));
        });
      }
    }
  };

  useEffect(() => {
    getAvatar();
  });

  return (
    <Wrapper>
      <AvatarPic
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        src={avatar}
        alt="User Avatar"
      />
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Link to="/profile">
          <MenuItem className="text-dark">
            <AccountCircleIcon />
            <p className="mx-2">查看个人资料</p>
          </MenuItem>
        </Link>
        <Link to="/settings">
          <MenuItem className="text-dark">
            <SettingsIcon />
            <p className="mx-2">设置</p>
          </MenuItem>
        </Link>
        <MenuItem className="text-dark" onClick={handelSignOut}>
          <LogoutIcon />
          <p className="mx-2">登出</p>
        </MenuItem>
      </Menu>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 0px 30px;
`;

const AvatarPic = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  :hover {
    cursor: pointer;
  }
`;
