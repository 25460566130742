import React, { useState, useEffect } from "react";

import styled from "styled-components";

import request from "../utils/request";

export default function UserInfoBar() {
  const [data, setData] = useState<any>(undefined);

  function getUserData() {
    request
      .post("user")
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <UserInfo>
      <div className="d-flex flex-column justify-content-start align-items-start">
        <Avatar
          src={data?.avatar === "" ? "./images/demo.jpeg" : data?.avatar}
          alt="avatar"
        />
      </div>
      <div className="d-flex flex-column">
        <div className="mx-5 d-flex flex-row">
          <Title1 className="m-3">{data?.nickname}</Title1>
          <Title1 className="m-3">{data?.email}</Title1>
        </div>
        <div className="mx-5 d-flex flex-row">
          <Title2 className="m-3">
            <HighLighted>{data?.follow_num}</HighLighted> 关注
          </Title2>
          <Title2 className="m-3">
            <HighLighted> {data?.my_follow_num}</HighLighted>
            粉丝
          </Title2>
          <Title2 className="m-3">
            <HighLighted> {data?.like_num}</HighLighted>
            喜欢
          </Title2>
        </div>
      </div>
    </UserInfo>
  );
}

const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  background-color: #bfb9b933;
  padding: 10px 30px;
  padding-left: 140px;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Avatar = styled.img`
  border-radius: 50%;
  width: 100px;
`;

const Title1 = styled.h4`
  color: #fff;
`;

const Title2 = styled.p`
  color: #fff;
`;

const HighLighted = styled.span`
  font-weight: 700;
  margin-right: 10px;
`;
