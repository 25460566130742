import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Layout from "../../components/Layout";
import VideosBar from "../../components/VideosBar";
import request from "../../utils/request";
import { Link } from "react-router-dom";

export default function Home() {
  const [categories, setCategories] = useState<any[]>([]);

  const getVideoCategory = () => {
    request
      .post("category", {
        pid: 2,
      })
      .then((res) => {
        setCategories(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getVideoCategory();
  }, []);

  return (
    <Layout>
      <div className="container">
        <div className="d-flex justify-content-center my-4">
          <div className="d-flex flex-row">
            {categories?.map((category: any) => (
              <Link
                to={`/category/${category.id}@${category.name}`}
                key={category.id}
              >
                <NavItem className="mx-3">{category.name}</NavItem>
              </Link>
            ))}
          </div>
        </div>
        {categories?.map((category: any) => (
          <div>
            <Link
              to={`/category/${category.id}@${category.name}`}
              key={category.name}
              className="p-3"
            >
              <Title className="pb-4">{category.name}</Title>
            </Link>
            <VideosBar payload={{ category_id: category.id }} />
          </div>
        ))}
      </div>
    </Layout>
  );
}

const Title = styled.h1`
  display: inline-block;
  font-size: 26px;
  padding: 20px;
  color: #fff;
  transition: 0.4s;
  :hover {
    opacity: 0.5;
  }
`;

const NavItem = styled.p`
  font-size: 16px;
  border-radius: 20px;
  color: #fff;
  padding: 5px 30px;
  background-color: #8e6c76;
  :hover {
    opacity: 0.4;
    cursor: pointer;
    transition: 0.4s;
  }
`;
